/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum Permission {
  InviteUser = 'invite_user',
  Onboard = 'onboard',
  CompanyUpdate = 'company_update',
  RoleUpdate = 'role_update',
  RoleRead = 'role_read',
  TaskRead = 'task_read',
  TaskCreate = 'task_create',
  TaskUpdate = 'task_update',
  TaskDelete = 'task_delete',
  GetIntegrations = 'get_integrations'
}
