import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { ButtonComponent, ButtonDescriptor } from '../button/button.component';
import { HeaderComponent } from '../header/header.component';
import { IconName } from '../../assets/icons/icons';

@Component({
    selector: 'ui-section',
    standalone: true,
    imports: [CommonModule, ButtonComponent, HeaderComponent],
    templateUrl: './section.component.html'
})
export class SectionComponent {
    @Input() button?: ButtonDescriptor;
    @Input() headerTitle: string;
    @Input() headerIcon: IconName;
    @Input() emptyText: string = 'The section is empty!';
    @Output() onButtonClick = new EventEmitter<void>();

    @ViewChild('groupingContainer') groupingContainer!: ElementRef;
    isEmptyState: boolean = false;
}
