<div
    class="rounded-2xl px-2 flex gap-1 items-center w-fit"
    [ngClass]="cssClasses"
>
    <ui-icon *ngIf="icon" [size]="12" [name]="icon" />
    <span
        class="text-ellipsis line-clamp-1"
        [ngClass]="{
            't-cap': !bold,
            't-body-bold': bold,
        }"
        >{{ label }}</span
    >
</div>
