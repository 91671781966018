/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attachUserToCompany } from '../fn/company/attach-user-to-company';
import { AttachUserToCompany$Params } from '../fn/company/attach-user-to-company';
import { AttachUserToCompanyResponseDto } from '../models/attach-user-to-company-response-dto';
import { CompanyResponseDto } from '../models/company-response-dto';
import { createCompany } from '../fn/company/create-company';
import { CreateCompany$Params } from '../fn/company/create-company';
import { getBusinessInfo } from '../fn/company/get-business-info';
import { GetBusinessInfo$Params } from '../fn/company/get-business-info';
import { updateBusinessInfo } from '../fn/company/update-business-info';
import { UpdateBusinessInfo$Params } from '../fn/company/update-business-info';

@Injectable({ providedIn: 'root' })
export class CompanyApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCompany()` */
  static readonly CreateCompanyPath = '/company';

  /**
   * Create a company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompany$Response(params: CreateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponseDto>> {
    return createCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompany(params: CreateCompany$Params, context?: HttpContext): Observable<CompanyResponseDto> {
    return this.createCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponseDto>): CompanyResponseDto => r.body)
    );
  }

  /** Path part for operation `getBusinessInfo()` */
  static readonly GetBusinessInfoPath = '/company/business-info';

  /**
   * Get business info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusinessInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBusinessInfo$Response(params: GetBusinessInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getBusinessInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get business info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBusinessInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBusinessInfo(params: GetBusinessInfo$Params, context?: HttpContext): Observable<{
}> {
    return this.getBusinessInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `updateBusinessInfo()` */
  static readonly UpdateBusinessInfoPath = '/company/business-info';

  /**
   * Update bussines info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBusinessInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBusinessInfo$Response(params: UpdateBusinessInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return updateBusinessInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Update bussines info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBusinessInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBusinessInfo(params: UpdateBusinessInfo$Params, context?: HttpContext): Observable<{
}> {
    return this.updateBusinessInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `attachUserToCompany()` */
  static readonly AttachUserToCompanyPath = '/company/user';

  /**
   * Attach a user to a company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachUserToCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attachUserToCompany$Response(params: AttachUserToCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachUserToCompanyResponseDto>> {
    return attachUserToCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Attach a user to a company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachUserToCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attachUserToCompany(params: AttachUserToCompany$Params, context?: HttpContext): Observable<AttachUserToCompanyResponseDto> {
    return this.attachUserToCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachUserToCompanyResponseDto>): AttachUserToCompanyResponseDto => r.body)
    );
  }

}
