import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { ButtonComponent } from '../button/button.component';

export type DownloaderProps = {
    // this is in bytes
    fileSize: number;
    fileName: string;
    allowReplaceFile: boolean;
};

@Component({
    selector: 'ui-attachment',
    standalone: true,
    imports: [CommonModule, IconComponent, ButtonComponent],
    templateUrl: './attachment.component.html'
})
export class AttachmentComponent implements OnChanges {
    @ViewChild('fileUploader', { static: true })
    fileUploader: ElementRef<HTMLInputElement>;

    @Input() selectedFile?: File;
    @Output() selectedFileChange = new EventEmitter<File>();

    // when it's a downloader attachment we can pass in size and filename instead
    @Input() downloaderProps: DownloaderProps;
    @Output() onDownloaderClick = new EventEmitter<void>();

    @Input() title: string = 'Upload file';
    @Input() toUploadText: string = 'Upload';
    @Input() uploadedText: string = 'Replace File';
    @Input() errorMessage?: string = undefined;
    @Input() disabled: boolean = false;

    @Input() loadingPercentage?: number = -1;
    @Output() loadingPercentageChange = new EventEmitter<number>();

    ngOnChanges(): void {
        if (this.downloaderProps) {
            // fake a selected file - parent will handle the on click
            this.selectedFile = new File([], this.downloaderProps.fileName);
        }
    }

    get fileSize(): string {
        const size = this.downloaderProps?.fileSize ?? this.selectedFile?.size;
        const sizeInKb = size / 1024;
        return sizeInKb > 1024
            ? `${(sizeInKb / 1024).toFixed(1)}MB`
            : `${sizeInKb.toFixed(1)}KB`;
    }

    get fileName(): string {
        return this.downloaderProps?.fileName ?? this.selectedFile?.name;
    }

    get isLoading(): boolean {
        return this.loadingPercentage !== -1;
    }

    get loadingBarStyles(): string {
        if (this.loadingPercentage >= 0 && this.loadingPercentage <= 100) {
            return `width: ${this.loadingPercentage}%`;
        }
        return '';
    }

    get iconBackgroundClass(): string {
        if (this.disabled) return 'bg-neutral-100';
        if (this.isLoading) return 'bg-primary-gradient-02';
        if (this.errorMessage) return 'bg-red-100';
        if (this.selectedFile) return 'bg-primary-gradient-02';
        return 'bg-neutral-100';
    }

    get iconParentClasses(): Record<string, boolean> {
        return { [this.iconBackgroundClass]: true };
    }

    get buttonLabel() {
        if (this.isLoading) {
            return '';
        }
        return this.selectedFile ? this.uploadedText : this.toUploadText;
    }

    get showButton() {
        return this.downloaderProps
            ? !this.disabled && this.downloaderProps?.allowReplaceFile
            : !this.disabled;
    }

    onContainerClicked(event: Event) {
        if (this.downloaderProps) {
            // if the target is the file uploader - allow it
            if ((event.target as HTMLElement).nodeName === 'INPUT') {
                return;
            }

            this.onDownloaderClick.emit();
        }
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.selectedFile = input.files[0];
            this.selectedFileChange.emit(this.selectedFile);
        }
    }

    onButtonClicked(event: Event) {
        event.stopPropagation();
        if (this.isLoading) {
            this.unsetFile();
            this.unsetLoadingBar();
        } else {
            this.fileUploader.nativeElement.click();
        }
    }

    unsetFile() {
        this.selectedFile = undefined;
        this.fileUploader.nativeElement.value = '';
        this.selectedFileChange.emit(this.selectedFile);
    }

    unsetLoadingBar() {
        this.loadingPercentage = -1;
        this.loadingPercentageChange.emit(this.loadingPercentage);
    }
}
