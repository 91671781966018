import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DefaultModalContentComponent } from './default-content/default-modal-content.component';

export type ModalPosition = 'right' | 'center';
export type ModalInput = {
    position: ModalPosition;
    growVertically: boolean;
    isOpen: boolean;
};

@Component({
    selector: 'ui-modal',
    standalone: true,
    imports: [CommonModule, IconComponent, DefaultModalContentComponent],
    templateUrl: './modal.component.html'
})
export class ModalComponent {
    // only applies to computer screens, for mobiles its always centered
    @Input() position: ModalPosition = 'center';
    @Input() growVertically: boolean = true;

    @Input() isOpen: boolean = false;
    @Output() isOpenChange = new EventEmitter<boolean>();

    @Output() onClose = new EventEmitter<void>();
    @Output() onOpen = new EventEmitter<void>();

    openModal() {
        this.isOpen = true;
        this.isOpenChange.emit(this.isOpen);
        this.onOpen.emit();
    }

    closeModal() {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
    }
}
