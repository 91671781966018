<div
    class="rounded-[18px] w-9 h-9 gap-[10px] flex items-center content-center"
    [ngClass]="{
        'shadow-depth-3': shadow, 
        'bg-neutral-100': mode === 'light',
        'bg-primary-gradient-02': mode === 'dark',
        'border-transparent': mode === 'light',
        'border-primary-500': mode === 'dark',
        'border-green-200/40': mode === 'green',
        'bg-green-300': mode === 'green',
     }"
    [style.borderWidth]="mode === 'green' ? '2px' : '1px'"
>
    <ui-icon
        *ngIf="icon"
        [fill]="fill"
        [name]="icon"
        [size]="20"
        class="mx-auto h-5"
        [ngClass]="{
            'text-neutral-100': mode === 'dark' || mode === 'green',
            'text-neutral-400': mode === 'light',
        }"
    />
</div>
