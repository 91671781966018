import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconName } from '../../assets/icons/icons';
import { IconComponent } from '../icon/icon.component';

export type LabelColor =
    | 'yellow'
    | 'blue'
    | 'green'
    | 'gray'
    | 'dark-blue'
    | 'dark-green';
export type LabelSize = 'small' | 'medium';
export type LabelDescriptor = {
    color?: LabelColor;
    active?: boolean;
    label: string;
    icon?: IconName;
    size?: LabelSize;
    bold?: boolean;
};

type ColorCombination = {
    backgroundClass: string;
    textClass: string;
};

type ColorCombinationMap = {
    [key in LabelColor]: ColorCombination;
};

const colorMap: ColorCombinationMap = {
    yellow: {
        backgroundClass: 'bg-yellow-100',
        textClass: 'text-yellow-400'
    },
    blue: {
        backgroundClass: 'bg-secondary-100',
        textClass: 'text-secondary-400'
    },
    green: {
        backgroundClass: 'bg-green-100',
        textClass: 'text-green-400'
    },
    gray: {
        backgroundClass: 'bg-neutral-100',
        textClass: 'text-primary-400'
    },
    'dark-blue': {
        backgroundClass: 'bg-primary-400',
        textClass: 'text-neutral-000'
    },
    'dark-green': {
        backgroundClass: 'bg-green-400',
        textClass: 'text-neutral-000'
    }
};

const inactiveColorCombination: ColorCombination = {
    backgroundClass: 'bg-neutral-200',
    textClass: 'text-neutral-500'
};

@Component({
    selector: 'ui-label',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './label.component.html'
})
export class LabelComponent {
    @Input() label: string;
    @Input() icon?: IconName;

    private _color: LabelColor = 'green';
    @Input()
    set color(color: LabelColor) {
        this._color = color !== undefined ? color : 'green';
    }
    get color(): LabelColor {
        return this._color;
    }

    private _active: boolean = true;
    @Input()
    set active(active: boolean) {
        this._active = active !== undefined ? active : true;
    }
    get active(): boolean {
        return this._active;
    }

    private _size: LabelSize = 'medium';
    @Input()
    set size(size: LabelSize) {
        this._size = size !== undefined ? size : 'medium';
    }
    get size(): LabelSize {
        return this._size;
    }

    private _bold: boolean = false;
    @Input()
    set bold(bold: boolean) {
        this._bold = bold !== undefined ? bold : false;
    }
    get bold(): boolean {
        return this._bold;
    }

    get cssClasses() {
        const colorCombination = this.active
            ? colorMap[this.color]
            : inactiveColorCombination;
        return {
            'h-7': this.size === 'medium',
            'h-5': this.size === 'small',
            [colorCombination.backgroundClass]: true,
            [colorCombination.textClass]: true
        };
    }
}
