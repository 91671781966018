import { Component } from '@angular/core';

@Component({
    selector: 'svg-gradients',
    standalone: true,
    template: `
        <svg class="w-0 h-0">
            <defs>
                <linearGradient
                    id="primary-gradient-02"
                    gradientUnits="userSpaceOnUse"
                    x1="100%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                >
                    <stop offset="7.03%" stop-color="#2D3F6C" />
                    <stop offset="95.31%" stop-color="#202D4D" />
                </linearGradient>
                <linearGradient
                    id="neutral-gradient-01"
                    gradientUnits="userSpaceOnUse"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                >
                    <stop offset="0%" stop-color="#FFFFFF" />
                    <stop
                        offset="100%"
                        stop-color="#FFFFFF"
                        stop-opacity="0.6"
                    />
                </linearGradient>
            </defs>
        </svg>
    `
})
export class SvgGradientComponent {}
