<div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
    <!-- Title -->
    <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
        {{ 'app.OPEN_SIGN_UP' | translate }}
    </div>

    <!-- Conditionally render this section based on showAlreadyHaveAccount -->
    <div
        *ngIf="showAlreadyHaveAccount"
        class="flex items-baseline mt-0.5 font-medium"
    >
        <div>{{ 'app.ALREADY_HAVE_AN_ACCOUNT' | translate }}</div>
        <a [routerLink]="['/sign-in']" class="ml-1 text-primary-500">
            {{ 'app.SIGN_IN' | translate }}
        </a>
    </div>

    <!-- Alert -->
    <fuse-alert
        class="mt-8"
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
    >
        {{ alert.message }}
    </fuse-alert>

    <!-- Sign-up form -->
    <form
        class="mt-8"
        [formGroup]="signUpForm"
        *ngIf="!isLoading && signUpForm"
    >
        <!-- First Name field -->
        <mat-form-field class="w-full" *ngIf="!isLoading; else ghostLoader">
            <mat-label>{{ 'app.FIRST_NAME' | translate }}</mat-label>
            <input
                id="firstName"
                matInput
                formControlName="firstName"
                data-cy="signUpFirstName"
            />
            <mat-error *ngIf="signUpForm.get('firstName').hasError('required')">
                {{ 'app.FIRST_NAME_IS_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Last Name field -->
        <mat-form-field class="w-full" *ngIf="!isLoading; else ghostLoader">
            <mat-label>{{ 'app.LAST_NAME' | translate }}</mat-label>
            <input
                id="lastName"
                matInput
                formControlName="lastName"
                data-cy="signUpLastName"
            />
            <mat-error *ngIf="signUpForm.get('lastName').hasError('required')">
                {{ 'app.LAST_NAME_IS_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Email field -->
        <mat-form-field
            class="w-full"
            [ngClass]="{ 'gray-disable': isEmailReadOnly }"
            *ngIf="!isLoading; else ghostLoader"
        >
            <mat-label>{{ 'app.EMAIL_ADDRESS' | translate }}</mat-label>
            <input
                id="email"
                matInput
                formControlName="email"
                [readonly]="isEmailReadOnly"
                data-cy="signUpEmail"
            />
            <mat-error *ngIf="signUpForm.get('email').hasError('required')">
                {{ 'app.EMAIL_ADDRESS_IS_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="signUpForm.get('email').hasError('email')">
                {{ 'app.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field class="w-full" *ngIf="!isLoading; else ghostLoader">
            <mat-label>{{ 'app.PASSWORD' | translate }}</mat-label>
            <input
                data-cy="signUpPassword"
                id="password"
                matInput
                type="password"
                [formControlName]="'password'"
                #passwordField
            />
            <button
                mat-icon-button
                type="button"
                (click)="
                    passwordField.type === 'password'
                        ? (passwordField.type = 'text')
                        : (passwordField.type = 'password')
                "
                matSuffix
            >
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'"
                ></mat-icon>
            </button>
            <mat-error
                *ngIf="
                    signUpForm.get('password').errors &&
                    !signUpForm.get('password').hasError('maxlength')
                "
            >
                {{
                    'app.PASSWORD_MUST_CONTAIN_AT_LEAST_EIGHT_CHARACTERS_AT_LEAST_ONE_NUMBER_AND_BOTH_LOWER_AND_UPPERCASE_LETTERS_AND_SPECIAL_CHARACTERS'
                        | translate
                }}
            </mat-error>
            <mat-error *ngIf="signUpForm.get('password').hasError('maxlength')">
                {{ 'app.PASSWORD_MAXIMUM_LENGTH' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Confirm Password field -->
        <mat-form-field class="w-full" *ngIf="!isLoading; else ghostLoader">
            <mat-label>{{ 'app.CONFIRM_PASSWORD' | translate }}</mat-label>
            <input
                data-cy="signUpConfirmPassword"
                id="confirmPassword"
                matInput
                type="password"
                formControlName="confirmPassword"
                #confirmPassword
            />
            <button
                mat-icon-button
                type="button"
                (click)="
                    confirmPassword.type === 'password'
                        ? (confirmPassword.type = 'text')
                        : (confirmPassword.type = 'password')
                "
                matSuffix
            >
                <mat-icon
                    class="icon-size-5"
                    *ngIf="confirmPassword.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="confirmPassword.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'"
                ></mat-icon>
            </button>
            <mat-error
                *ngIf="signUpForm.get('confirmPassword').hasError('required')"
            >
                {{ 'app.CONFIRM_PASSWORD_IS_REQUIRED' | translate }}
            </mat-error>
            <mat-error
                *ngIf="signUpForm.get('confirmPassword').hasError('mustMatch')"
            >
                {{ 'app.PASSWORD_DOES_NOT_MATCH' | translate }}
            </mat-error>
        </mat-form-field>

        <!--  ToS and PP -->
        <div class="inline-flex items-end w-full mt-1.5" *ngIf="!isLoading">
            <mat-checkbox
                class="-ml-2"
                [color]="'primary'"
                [formControlName]="'agreements'"
            >
                <span data-cy="signUpTerms">
                    {{ 'app.I_AGREE_WITH' | translate }}
                </span>
                <a
                    href="https://storage.googleapis.com/kamigo-prd-assets/terms.pdf"
                    target="_blank"
                    class="ml-1 text-primary-500 hover:underline"
                    >{{ 'app.TERMS' | translate }}</a
                >
                {{ 'app.AND' | translate }}
                <a
                    href="https://storage.googleapis.com/kamigo-prd-assets/privacy.pdf"
                    target="_blank"
                    class="ml-1 text-primary-500 hover:underline"
                >
                    {{ 'app.PRIVACY_POLICY' | translate }}
                </a>
            </mat-checkbox>
        </div>

        <!-- Submit button -->
        <button
            class="fuse-mat-button-large w-full mt-6"
            mat-flat-button
            [color]="'primary'"
            [disabled]="signUpForm?.disabled || signUpForm?.invalid"
            (click)="onSignUp()"
            data-cy="signUpSubmit"
        >
            <span *ngIf="!signUpForm?.disabled">
                {{ 'app.CREATE_YOUR_FREE_ACCOUNT' | translate }}
            </span>
            <mat-progress-spinner
                *ngIf="signUpForm?.disabled"
                [diameter]="24"
                [mode]="'indeterminate'"
            ></mat-progress-spinner>
        </button>
    </form>
</div>

<ng-template #ghostLoader>
    <div class="w-full">
        <div class="w-24 h-4" [skeleton]="isLoading"></div>
        <div class="w-full h-12" [skeleton]="isLoading"></div>
    </div>
</ng-template>
