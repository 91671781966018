import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { LabelComponent, LabelDescriptor } from '../label/label.component';
import { IconName } from '../../assets/icons/icons';
import { IconBadgeComponent } from '../icon-badge/icon-badge.component';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { IconComponent } from '../icon/icon.component';

export type LabelInput = Omit<LabelDescriptor, 'active' | 'size' | 'bold'>;

@Component({
    selector: 'ui-task-card',
    standalone: true,
    imports: [CommonModule, IconBadgeComponent, LabelComponent, IconComponent],
    templateUrl: './task-card.component.html'
})
export class TaskCardComponent implements OnInit {
    SEPARATOR = ' · ';

    constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) {}
    @Input() forceFocus: boolean = false;
    @Input() leftIcon: IconName;
    @Input() title: string;
    @Input() descriptionTitle: string;
    @Input() descriptionIcon?: IconName;
    @Input() descriptionExtra?: string;
    @Input() descriptionDate: string;
    @Input() active?: boolean = true;
    @Input() leftLabelText: string;
    @Input() rightLabelInput?: LabelInput;
    @Output() onClick = new EventEmitter<Event>();

    get rightLabel(): LabelDescriptor {
        return {
            ...this.rightLabelInput,
            size: this.displayMode === 'full' ? 'medium' : 'small'
        };
    }

    get leftLabel() {
        if (this.leftLabelText) {
            return {
                label: this.leftLabelText,
                color: 'gray',
                size: 'small'
            };
        }
    }

    @ViewChild('parentContainer', { static: true }) parentContainer: ElementRef;
    resizeObserver: ResizeObserver;
    private $resize = new BehaviorSubject(null);

    displayMode: 'full' | 'medium' = 'medium';

    ngOnInit(): void {
        this.setupResizeObserver();
    }

    onNewResize() {
        const newDisplayMode =
            this.parentContainer.nativeElement.offsetWidth > 450
                ? 'full'
                : 'medium';

        // avoid running the detection on every resize
        if (newDisplayMode !== this.displayMode) {
            this.displayMode = newDisplayMode;
            this.cdr.detectChanges();
        }
    }

    setupResizeObserver() {
        // debounce to avoid resize loops
        this.$resize.pipe(debounceTime(1)).subscribe(() => {
            this.onNewResize();
        });
        this.resizeObserver = new ResizeObserver(() => {
            this.$resize.next(null);
        });

        this.resizeObserver.observe(this.parentContainer.nativeElement);
    }
}
