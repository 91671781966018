/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UserApiService } from './services/user-api.service';
import { TwoFactorAuthApiService } from './services/two-factor-auth-api.service';
import { TaskApiService } from './services/task-api.service';
import { FirmSettingsApiService } from './services/firm-settings-api.service';
import { CompanyApiService } from './services/company-api.service';
import { FinanceApiService } from './services/finance-api.service';
import { StorageApiService } from './services/storage-api.service';
import { PermaLinkApiService } from './services/perma-link-api.service';
import { MigrationApiService } from './services/migration-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UserApiService,
    TwoFactorAuthApiService,
    TaskApiService,
    FirmSettingsApiService,
    CompanyApiService,
    FinanceApiService,
    StorageApiService,
    PermaLinkApiService,
    MigrationApiService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
