/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addBatchIdInTasks } from '../fn/migration/add-batch-id-in-tasks';
import { AddBatchIdInTasks$Params } from '../fn/migration/add-batch-id-in-tasks';
import { addSignUpOrigin } from '../fn/migration/add-sign-up-origin';
import { AddSignUpOrigin$Params } from '../fn/migration/add-sign-up-origin';
import { deledeleteNotSentTranscriptTasksteTask } from '../fn/migration/deledelete-not-sent-transcript-taskste-task';
import { DeledeleteNotSentTranscriptTasksteTask$Params } from '../fn/migration/deledelete-not-sent-transcript-taskste-task';
import { deleteKamigoSettings } from '../fn/migration/delete-kamigo-settings';
import { DeleteKamigoSettings$Params } from '../fn/migration/delete-kamigo-settings';
import { deleteTask_1 } from '../fn/migration/delete-task-1';
import { DeleteTask_1$Params } from '../fn/migration/delete-task-1';
import { migrateTaskData } from '../fn/migration/migrate-task-data';
import { MigrateTaskData$Params } from '../fn/migration/migrate-task-data';
import { SuccessMessageDto } from '../models/success-message-dto';

@Injectable({ providedIn: 'root' })
export class MigrationApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `migrateTaskData()` */
  static readonly MigrateTaskDataPath = '/migration/migrate-tasks';

  /**
   * migration for update task status.
   *
   * Migration task status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `migrateTaskData()` instead.
   *
   * This method doesn't expect any request body.
   */
  migrateTaskData$Response(params?: MigrateTaskData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return migrateTaskData(this.http, this.rootUrl, params, context);
  }

  /**
   * migration for update task status.
   *
   * Migration task status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `migrateTaskData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  migrateTaskData(params?: MigrateTaskData$Params, context?: HttpContext): Observable<void> {
    return this.migrateTaskData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteKamigoSettings()` */
  static readonly DeleteKamigoSettingsPath = '/migration/delete-setting';

  /**
   * Delete kamigo Settings.
   *
   * Delete kamigo settings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteKamigoSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKamigoSettings$Response(params?: DeleteKamigoSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteKamigoSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete kamigo Settings.
   *
   * Delete kamigo settings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteKamigoSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKamigoSettings(params?: DeleteKamigoSettings$Params, context?: HttpContext): Observable<void> {
    return this.deleteKamigoSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteTask_1()` */
  static readonly DeleteTask_1Path = '/migration/delete-task';

  /**
   * Delete task which is deleted from accounton side but not reflected in kamigo side
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTask_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteTask_1$Response(params: DeleteTask_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTask_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete task which is deleted from accounton side but not reflected in kamigo side
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTask_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteTask_1(params: DeleteTask_1$Params, context?: HttpContext): Observable<void> {
    return this.deleteTask_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addSignUpOrigin()` */
  static readonly AddSignUpOriginPath = '/migration/user-origin';

  /**
   * Add user sign up origin for accounton user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSignUpOrigin()` instead.
   *
   * This method doesn't expect any request body.
   */
  addSignUpOrigin$Response(params?: AddSignUpOrigin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addSignUpOrigin(this.http, this.rootUrl, params, context);
  }

  /**
   * Add user sign up origin for accounton user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSignUpOrigin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addSignUpOrigin(params?: AddSignUpOrigin$Params, context?: HttpContext): Observable<void> {
    return this.addSignUpOrigin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deledeleteNotSentTranscriptTasksteTask()` */
  static readonly DeledeleteNotSentTranscriptTasksteTaskPath = '/migration/not-sent-task';

  /**
   * Delete task which is deleted from accounton side but not reflected in kamigo side
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deledeleteNotSentTranscriptTasksteTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deledeleteNotSentTranscriptTasksteTask$Response(params: DeledeleteNotSentTranscriptTasksteTask$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return deledeleteNotSentTranscriptTasksteTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete task which is deleted from accounton side but not reflected in kamigo side
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deledeleteNotSentTranscriptTasksteTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deledeleteNotSentTranscriptTasksteTask(params: DeledeleteNotSentTranscriptTasksteTask$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.deledeleteNotSentTranscriptTasksteTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `addBatchIdInTasks()` */
  static readonly AddBatchIdInTasksPath = '/migration/batchId';

  /**
   * Add batchId in tasks used from AOS multiple customer task creation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBatchIdInTasks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBatchIdInTasks$Response(params: AddBatchIdInTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return addBatchIdInTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * Add batchId in tasks used from AOS multiple customer task creation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBatchIdInTasks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBatchIdInTasks(params: AddBatchIdInTasks$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.addBatchIdInTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

}
