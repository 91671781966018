import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NavigationComponentRef } from '../reference/navigationComponentRef';
import { NavigationComponent } from '../navigation.component';

@Component({
    selector: 'ui-navigation-header',
    standalone: true,
    imports: [CommonModule],
    template: `<ng-container
        *ngTemplateOutlet="navigationHeader"
    ></ng-container>`
})
export class NavigationHeaderComponent implements OnInit, OnDestroy {
    @Input() activeItemHidden?: boolean = false;
    constructor(
        public readonly navigationComponentRef: NavigationComponentRef,
        private cdr: ChangeDetectorRef
    ) {}

    navigationHeaderSubscription: Subscription;
    navigationHeader: TemplateRef<any>;
    navComponent: NavigationComponent;

    ngOnInit(): void {
        this.navigationHeaderSubscription =
            this.navigationComponentRef.component$.subscribe((navComp) => {
                if (navComp) {
                    this.navComponent = navComp;
                    this.navComponent.activeItemHeaderHidden =
                        this.activeItemHidden;
                    this.navigationHeader = navComp.header;
                    this.cdr.detectChanges();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeItemHidden && this.navComponent) {
            this.navComponent.activeItemHeaderHidden =
                changes.activeItemHidden.currentValue;
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.navigationHeaderSubscription.unsubscribe();
    }
}
