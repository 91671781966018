/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PaginatedCompanyUser } from '../../models/paginated-company-user';

export interface GetCompanyUsers$Params {
  companyId: string;
  page?: number;
  limit?: number;
  filter?: string;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
}

export function getCompanyUsers(http: HttpClient, rootUrl: string, params: GetCompanyUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedCompanyUser>> {
  const rb = new RequestBuilder(rootUrl, getCompanyUsers.PATH, 'get');
  if (params) {
    rb.path('companyId', params.companyId, {});
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('filter', params.filter, {});
    rb.query('sortField', params.sortField, {});
    rb.query('sortDirection', params.sortDirection, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PaginatedCompanyUser>;
    })
  );
}

getCompanyUsers.PATH = '/company/{companyId}/users';
