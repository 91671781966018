/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum Language {
  Nl = 'nl',
  En = 'en',
  Fr = 'fr',
  De = 'de'
}
