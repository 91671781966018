import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { take } from 'rxjs/operators';
import { NavigationComponentRef } from '../reference/navigationComponentRef';
import { NavItem } from '../navigation.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { IconComponent } from '../../icon/icon.component';
import { CommonModule } from '@angular/common';
import { IconName } from '../../../assets/icons/icons';

@Component({
    selector: 'ui-mobile-submenu-header',
    standalone: true,
    imports: [CommonModule, IconComponent],
    template: `
        <div
            class="z-10 flex flex-col items-center gap-y-4 bg-neutral-000 shadow-depth-2-regular sm:hidden pt-12 p-4"
        >
            <div class="flex flex-row items-center  ">
                <button
                    class="absolute left-4 cursor-pointer h-11 w-11"
                    (click)="handleBack()"
                >
                    <ui-icon [name]="'ArrowLeft'"></ui-icon>
                </button>
                <div class="flex items-center justify-center flex-1">
                    <div
                        *ngIf="(!title && activeItem) || (title && icon)"
                        class="p-1.5 mr-3 border border-primary-100 rounded-md shadow-depth-3"
                    >
                        <ui-icon
                            [name]="icon || activeItem?.icon"
                            class="h-5 w-5 text-primary-500"
                        ></ui-icon>
                    </div>
                    <h1 class="t-h3-bold">
                        {{ title || activeItem?.label || 'Profile' }}
                    </h1>
                </div>
            </div>
            <!-- Custom content slot -->
            <ng-content select="[customContent]"></ng-content>
        </div>
    `
})
export class MobileHeaderComponent implements OnInit {
    // Optional custom title. If not provided, we display the active route.
    @Input() title?: string;
    @Input() icon?: IconName;
    // when you want to dynamically set the back option back to default by setting to true
    @Input() defaultBack = false;
    @Output() back = new EventEmitter<void>();

    activeItem: NavItem | null = null;
    private subscription: Subscription;

    constructor(
        private navComponentRef: NavigationComponentRef,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (!this.title) {
            this.subscription = this.navComponentRef.component$.subscribe(
                (navComponent) => {
                    setTimeout(() => {
                        this.activeItem = navComponent?.activeItem;
                        this.cdRef.detectChanges();
                    });
                }
            );
        }
    }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    handleBack(): void {
        // If it's observed juust emit to parent.
        if (!this.defaultBack && this.back.observed) {
            this.back.emit();
        } else {
            // Otherwise, default to toggling the navigation's bottom expansion.
            this.navComponentRef.component$
                .pipe(take(1))
                .subscribe((navComponent) => {
                    if (navComponent) {
                        navComponent.toggleBottomExpansion();
                    }
                });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
