import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-sign-in-form-component',
    templateUrl: './sign-in-form.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SignInFormComponent {
    @Input() signInForm: UntypedFormGroup;
    @Input() alert: { type: FuseAlertType; message: string };
    @Input() showAlert: boolean;
    @Input() isLoading: boolean;
    @Input() isTFAEnable: boolean;
    @Input() enableAuthentication: number | string;
    @Input() showSignUpLink = true;
    @Input() emailReadOnly = false;
    @Input() emailClass = '';
    @Input() inviteMessage = '';

    @Output() signIn = new EventEmitter<void>();
    @Output() verifyTFA = new EventEmitter<number | string>();

    @ViewChild('signInNgForm') signInNgForm: NgForm;

    onSignIn(): void {
        this.signIn.emit();
    }

    onVerifyTFA(): void {
        this.verifyTFA.emit(this.enableAuthentication);
    }
}
