import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'ui-default-modal-content',
    standalone: true,
    imports: [CommonModule, IconComponent],
    template: `<div
        #container
        class="bg-white shadow-depth-2-regular flex flex-col min-h-[68px] pointer-events-auto"
        [ngClass]="{
            'h-full': growVertically,
            'h-fit': !growVertically,
            'rounded-2xl': shouldBeRounded
        }"
    >
        <div class="relative">
            <button
                class="m-3 absolute right-0 h-11 w-11 hover:cursor-pointer bg-neutral-000 hover:bg-neutral-100 flex rounded-lg border-none"
                (click)="onClose.emit()"
            >
                <ui-icon class="w-6 h-6 m-auto text-neutral-400" name="X" />
            </button>
        </div>
        <div
            class="rounded-2xl w-full"
            [ngClass]="{
                'h-full': growVertically,
                'h-fit': !growVertically
            }"
        >
            <ng-content></ng-content>
        </div>
    </div>`,
    host: {
        class: 'h-full'
    }
})
export class DefaultModalContentComponent {
    @ViewChild('container', { static: true })
    container: ElementRef<HTMLDivElement>;

    @Input() growVertically: boolean = true;
    @Output() onClose = new EventEmitter<void>();

    get shouldBeRounded() {
        return window.innerWidth > this.container.nativeElement.offsetWidth;
    }
}
