import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationComponent } from '../navigation.component';

@Injectable({
    providedIn: 'root'
})
export class NavigationComponentRef {
    private _component = new BehaviorSubject<NavigationComponent>(null);

    get component(): NavigationComponent {
        return this._component.getValue();
    }

    get component$(): Observable<NavigationComponent> {
        return this._component.asObservable();
    }

    emitChange(navigationComponent: NavigationComponent) {
        this._component.next(navigationComponent);
    }
}
