import { Pipe, PipeTransform, Injectable } from '@angular/core';

/**
 * The transformation goes as follows:
 * - If count is 0, append '_ZERO'.
 * - If count is 1, append '_ONE'.
 * - Otherwise, append '_MANY'.
 *
 * This pipe can be used in two ways:
 *
 * 1. **As a Pipe in the Template:**
 *    {{ 'app.NEW_TASK' | pluralize_key: numTasks | translate }}
 *
 * 2. **As an Injectable Service in Component Code:**
 *     this.pluralizeKeyPipe.transform('app.NEW_TASK', count);
 */
@Injectable()
@Pipe({
    name: 'pluralize_key'
})
export class PluralizeKeyPipe implements PipeTransform {
    transform(key: string, count: number): string {
        let suffix = '';
        if (count === 0) {
            suffix = '_ZERO';
        } else if (count === 1) {
            suffix = '_ONE';
        } else {
            suffix = '_MANY';
        }
        return `${key}${suffix}`;
    }
}
