/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { moniteProxyDelete } from '../fn/finance/monite-proxy-delete';
import { MoniteProxyDelete$Params } from '../fn/finance/monite-proxy-delete';
import { moniteProxyGet } from '../fn/finance/monite-proxy-get';
import { MoniteProxyGet$Params } from '../fn/finance/monite-proxy-get';
import { moniteProxyHead } from '../fn/finance/monite-proxy-head';
import { MoniteProxyHead$Params } from '../fn/finance/monite-proxy-head';
import { moniteProxyOptions } from '../fn/finance/monite-proxy-options';
import { MoniteProxyOptions$Params } from '../fn/finance/monite-proxy-options';
import { moniteProxyPatch } from '../fn/finance/monite-proxy-patch';
import { MoniteProxyPatch$Params } from '../fn/finance/monite-proxy-patch';
import { moniteProxyPost } from '../fn/finance/monite-proxy-post';
import { MoniteProxyPost$Params } from '../fn/finance/monite-proxy-post';
import { moniteProxyPut } from '../fn/finance/monite-proxy-put';
import { MoniteProxyPut$Params } from '../fn/finance/monite-proxy-put';
import { proxyPostPaymentLinks } from '../fn/finance/proxy-post-payment-links';
import { ProxyPostPaymentLinks$Params } from '../fn/finance/proxy-post-payment-links';

@Injectable({ providedIn: 'root' })
export class FinanceApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `proxyPostPaymentLinks()` */
  static readonly ProxyPostPaymentLinksPath = '/finance/{companyId}/payment_links';

  /**
   * Overwrite body entityId where necessary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proxyPostPaymentLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  proxyPostPaymentLinks$Response(params: ProxyPostPaymentLinks$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return proxyPostPaymentLinks(this.http, this.rootUrl, params, context);
  }

  /**
   * Overwrite body entityId where necessary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proxyPostPaymentLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proxyPostPaymentLinks(params: ProxyPostPaymentLinks$Params, context?: HttpContext): Observable<{
}> {
    return this.proxyPostPaymentLinks$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyGet()` */
  static readonly MoniteProxyGetPath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyGet$Response(params: MoniteProxyGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyGet(params: MoniteProxyGet$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyPut()` */
  static readonly MoniteProxyPutPath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyPut$Response(params: MoniteProxyPut$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyPut(params: MoniteProxyPut$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyPost()` */
  static readonly MoniteProxyPostPath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyPost$Response(params: MoniteProxyPost$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyPost(params: MoniteProxyPost$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyDelete()` */
  static readonly MoniteProxyDeletePath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyDelete$Response(params: MoniteProxyDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyDelete(params: MoniteProxyDelete$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyOptions()` */
  static readonly MoniteProxyOptionsPath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyOptions$Response(params: MoniteProxyOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyOptions(params: MoniteProxyOptions$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyHead()` */
  static readonly MoniteProxyHeadPath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyHead()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyHead$Response(params: MoniteProxyHead$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyHead(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyHead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyHead(params: MoniteProxyHead$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyHead$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `moniteProxyPatch()` */
  static readonly MoniteProxyPatchPath = '/finance/{companyId}/*';

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moniteProxyPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyPatch$Response(params: MoniteProxyPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return moniteProxyPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward all finance get operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moniteProxyPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moniteProxyPatch(params: MoniteProxyPatch$Params, context?: HttpContext): Observable<{
}> {
    return this.moniteProxyPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
