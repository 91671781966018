<div
    role="switch"
    tabindex="0"
    [attr.aria-checked]="checked"
    aria-label="Toggle switch"
    class="relative inline-flex items-center w-[56px] h-[32px] cursor-pointer transition-colors duration-200 rounded-full"
    [ngClass]="checked ? 'bg-primary-600' : 'bg-neutral-100'"
    (click)="toggle()"
    (keydown)="onKeyDown($event)"
>
    <!-- The "knob" -->
    <div
        class="absolute left-0 flex items-center justify-center w-[24px] h-[24px] bg-neutral-000 rounded-full shadow-md transition-transform duration-200"
        [ngClass]="checked ? 'translate-x-[26px]' : 'translate-x-1'"
    >
        <ui-icon
            [name]="checked ? 'Check' : 'Minus'"
            strokeWidth="3"
            class="w-4 h-4 text-primary-500"
        ></ui-icon>
    </div>
</div>
