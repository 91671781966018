<div
    class="p-1 inline-flex items-center rounded-2xl tab-container-border-gradient tab-container-bg shadow-[0_0_0.5px_rgba(0,0,0,0.2),_0_0_8px_rgba(16,23,41,0.12)]"
    [ngClass]="{
        'w-full justify-evenly': fullWidth,
        'w-fit': !fullWidth
    }"
>
    <ng-container *ngFor="let tab of tabItems">
        <ui-tab-item
            [tab]="tab"
            [isSelected]="selectedTab === tab.key"
            [fullWidth]="fullWidth"
            (onClick)="selectTab($event)"
            [ngClass]="{ 'w-full': fullWidth }"
        />
    </ng-container>
</div>
