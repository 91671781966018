<div
    class="rounded-2xl py-3 px-4 shadow-depth-2-regular max-w-[450px] bg-neutral-000 flex gap-3"
    [ngClass]="
        notificationConfiguration.isDetailPresent
            ? 'items-start'
            : 'items-center justify-between'
    "
    [@notificationState]="animationState"
>
    <div
        class="flex"
        [ngClass]="
            notificationConfiguration.isDetailPresent
                ? 'items-start'
                : 'items-center'
        "
    >
        <div
            class="shadow-depth-3 rounded-xl w-11 h-10 p-2 flex justify-center items-center"
            [ngClass]="notificationIconBackground"
        >
            <ui-icon
                [ngClass]="notificationIconColor"
                [size]="30"
                [name]="notificationIcon"
            />
        </div>
        <div class="flex flex-col ml-3 w-[330px]">
            <span class="t-h4" [ngClass]="notificationTextColor">{{
                notificationConfiguration.title
            }}</span>
            <span
                *ngIf="notificationConfiguration.isDetailPresent"
                class="t-body text-neutral-600 text-wrap"
                >{{ notificationConfiguration.detail }}
                <a
                    class="t-body text-neutral-600 underline"
                    href="{{ notificationConfiguration.detailLink }}"
                    target="_blank"
                >
                    Link for more info
                </a>
            </span>
        </div>
    </div>
    <ui-icon
        class="text-neutral-300 font-light cursor-pointer"
        [name]="'X'"
        (click)="handleCloseNotifier()"
    />
</div>
