import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-logo-and-message',
    templateUrl: './logo-and-message.component.html'
})
export class LogoAndMessageComponent {
    @Input() inviteMessage: string;
    @HostBinding('class') hostClass =
        'flex flex-col flex-auto items-center justify-center h-full overflow-hidden bg-primary-800';
}
