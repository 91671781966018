import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ModalComponent, ModalInput } from '../modal/modal.component';
import { IconComponent } from '../icon/icon.component';
import { DefaultModalContentComponent } from '../modal/default-content/default-modal-content.component';
import { BreakpointObserver, LayoutModule } from '@angular/cdk/layout';
import { getMediaQuery } from '../../helper/media.helper';

export type ModalPosition = 'right' | 'center';

@Component({
    selector: 'ui-portal',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        ModalComponent,
        DefaultModalContentComponent,
        IconComponent
    ],
    templateUrl: './portal.component.html'
})
export class PortalComponent implements OnInit, OnChanges {
    constructor(public breakpointObserver: BreakpointObserver) {}

    @ViewChild('modal') modal: ModalComponent;

    @Input() isOpen: boolean = false;
    @Output() onClose = new EventEmitter<void>();

    @Input() pixelsToChange: number = 820;
    @Input() modalConfig: Omit<ModalInput, 'isOpen'>;

    public showAsModal: boolean = false;

    ngOnInit(): void {
        this.setBreakpoint();
    }

    ngOnChanges(): void {
        this.setBreakpoint();
    }

    setBreakpoint() {
        const breakpoint = getMediaQuery(this.pixelsToChange);
        this.breakpointObserver
            .observe(breakpoint)
            .subscribe((breakpointState) => {
                this.showAsModal = !breakpointState.breakpoints[breakpoint];
            });
    }

    get modalGrowVertically() {
        return this.modalConfig?.growVertically !== undefined
            ? this.modalConfig?.growVertically
            : true;
    }
    get modalPosition() {
        return this.modalConfig?.position || 'center';
    }

    close() {
        this.isOpen = false;
        this.onClose.emit();
    }
}
