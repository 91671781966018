<div
    class="rounded-2xl px-4 gap-3 w-64 min-h-[84px] flex cursor-pointer items-center shadow-depth-2-regular bg-neutral-000"
    [ngClass]="{
        'border-secondary-300 shadow-input-glow': isActive,
        'hover:shadow-depth-2-hover': !isActive
    }"
    (click)="applyClass(); handleClick(appCardDetails)"
>
    <div class="w-9 h-9 flex items-center justify-center">
        <ng-content></ng-content>
    </div>
    <div>
        <div class="t-h4">{{ appCardDetails?.name }}</div>
        <div class="t-body text-neutral-400">
            {{ appCardDetails?.description }}
        </div>
    </div>
</div>
