<!-- Outer container with a navigation landmark -->
<div
    class="flex items-center justify-between"
    role="navigation"
    aria-label="Pagination Navigation"
>
    <!-- Page size selector with label -->
    <div class="flex items-center">
        <label id="page-size-label" class="mr-2 t-body text-neutral-500">{{
            itemsPerPageLabel
        }}</label>
        <ui-dropdown
            aria-labelledby="page-size-label"
            [items]="dropdownPageSizeItems"
            [singleSelectedItem]="selectedPageSizeItem"
            [buttonVariant]="'secondary'"
            (singleSelectedItemChange)="onPageSizeChange($event.item)"
        >
        </ui-dropdown>
    </div>

    <!-- Pagination controls: Previous button, page info, Next button -->
    <div class="flex items-center gap-1">
        <span
            aria-live="polite"
            aria-atomic="true"
            class="mr-2 t-body text-neutral-500"
        >
            {{ startIndex }} - {{ endIndex }} {{ ofLabel }} {{ length }}
        </span>
        <!-- Previous Button -->
        <ui-button
            [variant]="'secondary'"
            [disabled]="pageIndex === 1"
            [icon]="'ChevronLeft'"
            [attr.aria-label]="'Previous page'"
            (clicked)="onPrevious()"
        >
        </ui-button>
        <!-- Next Button -->
        <ui-button
            [variant]="'secondary'"
            [disabled]="pageIndex === totalPages"
            [icon]="'ChevronRight'"
            [attr.aria-label]="'Next page'"
            (clicked)="onNext()"
        >
        </ui-button>
    </div>
</div>
