import { NgModule } from '@angular/core';
import { ButtonComponent } from './components/button/button.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { IconComponent } from './components/icon/icon.component';
import { IconBadgeComponent } from './components/icon-badge/icon-badge.component';
import { InputComponent } from './components/input/input.component';
import { LabelComponent } from './components/label/label.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TaskCardComponent } from './components/task-card/task-card.component';
import { ToggleTabsComponent } from './components/toggle-tabs/toggle-tabs.component';
import { SvgGradientComponent } from './assets/icons/svg-gradients';
import { AppCardComponent } from './components/app-card/app-card.component';
import { PortalComponent } from './components/portal/portal.component';
import { SectionComponent } from './components/section/section.component';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { ModalComponent } from './components/modal/modal.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { NavigationHeaderComponent } from './components/navigation/header/navigation-header.component';
import { FirmCardComponent } from './components/firm-card/firm-card.component';
import { MobileHeaderComponent } from './components/navigation/header/mobile-submenu-header.component';
import { DefaultModalContentComponent } from './components/modal/default-content/default-modal-content.component';
import { HeaderComponent } from './components/header/header.component';
import { TabItemComponent } from './components/toggle-tabs/tab-item/tab-item.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { AttachmentComponent } from './components/attachments/attachment.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { NotificationComponent } from './components/notification/notification.component';

const Components = [
    ButtonComponent,
    DropdownComponent,
    IconComponent,
    IconBadgeComponent,
    InputComponent,
    LabelComponent,
    NavigationComponent,
    TaskCardComponent,
    ToggleTabsComponent,
    SvgGradientComponent,
    AppCardComponent,
    ModalComponent,
    NavigationHeaderComponent,
    PortalComponent,
    SectionComponent,
    SummaryCardComponent,
    ModalComponent,
    InputComponent,
    NavigationHeaderComponent,
    ContactCardComponent,
    FirmCardComponent,
    AttachmentComponent,
    DefaultModalContentComponent,
    MobileHeaderComponent,
    HeaderComponent,
    TabItemComponent,
    PaginatorComponent,
    ToggleComponent,
    OtpInputComponent,
    NotificationComponent
];

@NgModule({
    imports: Components,
    exports: Components
})
export class UIModule {}
