import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { IconName } from '../../assets/icons/icons';

export type ColorMode = 'dark' | 'light' | 'green';

@Component({
    selector: 'ui-icon-badge',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './icon-badge.component.html'
})
export class IconBadgeComponent {
    @Input() icon: IconName;
    @Input() fill?: boolean = true;
    @Input() mode: ColorMode = 'dark';
    @Input() shadow = true;
}
