import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { IconName } from '../../assets/icons/icons';
import { HeaderComponent } from '../header/header.component';
import { NavigationComponentRef } from './reference/navigationComponentRef';
import { ButtonComponent } from '../button/button.component';
import { BreakpointObserver, LayoutModule } from '@angular/cdk/layout';
import { extractTailwindBreakpoint } from '../../helper/media.helper';

export interface NavItem {
    key: string;
    label: string;
    icon: IconName;
    activeIcon?: IconName;
    route: string;
    mobileButton?: boolean;
}

export interface BottomNavItem {
    label: string;
    icon: IconName;
    activeIcon?: IconName;
    image?: string;
}

export type NavigationComposition = 'mobile' | 'modal' | 'desktop';

@Component({
    selector: 'ui-navigation',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        IconComponent,
        HeaderComponent,
        ButtonComponent
    ],
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnChanges {
    constructor(
        public navigationComponentRef: NavigationComponentRef,
        public breakpointObserver: BreakpointObserver
    ) {}

    @Input() logoSvgPath?: string;
    @Input() navItems: NavItem[] = [];
    @Input() bottomNavItem!: BottomNavItem;
    @Input() bottomSubItems: NavItem[] = [];
    @Input() mobileSubMenuText: string = 'Your account';
    @Input() hideMobileBottomNav?: boolean = false;

    @Input() activeItemHeaderHidden?: boolean = false;

    @Input() activeItemKey: string | null = null;
    @Output() activeItemKeyChange = new EventEmitter<string>();
    // set static true so that the child is resolved before ngOnInit
    @ViewChild('header', { static: true })
    public header: TemplateRef<unknown> | null = null;

    public composition: NavigationComposition = 'desktop';
    public isTransitioning = false;
    public isCollapsed = false;
    public isBottomExpanded = false;
    public isBottomImageError = false;
    private hasErrored = false;

    get activeItem(): NavItem | undefined {
        return (
            this.navItems.find((item) => item.key === this.activeItemKey) ||
            this.bottomSubItems.find((item) => item.key === this.activeItemKey)
        );
    }

    ngOnInit(): void {
        // initialize navigation reference
        this.navigationComponentRef.emitChange(this);
        this.setupComposition();
    }

    setupComposition() {
        const smBreakpoint = extractTailwindBreakpoint('sm');
        const lgBreakpoint = extractTailwindBreakpoint('lg');
        this.breakpointObserver
            .observe([smBreakpoint, lgBreakpoint])
            .subscribe((breakpointState) => {
                // sm is not matched so small screen
                if (!breakpointState.breakpoints[smBreakpoint]) {
                    this.composition = 'mobile';
                    this.isCollapsed = false;
                    // lg not matched so modal
                } else if (!breakpointState.breakpoints[lgBreakpoint]) {
                    this.composition = 'modal';
                    this.isCollapsed = true;
                    // more is a normal desktop screen
                } else {
                    this.composition = 'desktop';
                    this.isCollapsed = false;
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeItemKey) {
            this.activeItemKey = changes.activeItemKey.currentValue;
        }
    }

    toggleCollapse(): void {
        this.isTransitioning = true;
        setTimeout(() => {
            this.isTransitioning = false;
        }, 300);

        this.isCollapsed = !this.isCollapsed;
    }

    toggleBottomExpansion(): void {
        if (this.hideMobileBottomNav) {
            this.hideMobileBottomNav = false;
        }
        this.isBottomExpanded = !this.isBottomExpanded;
    }

    updateActiveState(selectedKey: string | null): void {
        this.activeItemKey = selectedKey;
        this.navigationComponentRef.emitChange(this);
    }

    isActive(item: NavItem): boolean {
        return this.activeItemKey === item.key;
    }

    getIcon(item: NavItem): IconName {
        // when on mobile and the bottom is expanded the icon is actually no longer active
        if (this.composition === 'mobile' && this.isBottomExpanded) {
            return item.icon;
        }
        return this.isActive(item) && item.activeIcon
            ? item.activeIcon
            : item.icon;
    }

    getNavItemClasses(item: NavItem): { [key: string]: boolean } {
        const active = this.isActive(item);
        return {
            'bg-neutral-000 text-primary-600': active,
            'hover:bg-[rgba(255,255,255,0.15)] bg-primary-600 text-white':
                !active
        };
    }

    getBottomSubItemClasses(item: NavItem): { [key: string]: boolean } {
        const active = this.isActive(item);
        return {
            'bg-neutral-000 text-primary-600': active,
            'hover:bg-[rgba(255,255,255,0.15)] bg-transparent text-white':
                !active
        };
    }

    onBottomImageError(): void {
        if (!this.hasErrored) {
            this.hasErrored = true;
            this.isBottomImageError = true;
        }
    }

    // allow modifier clicking for regular link functions while passing left click to the parent/router
    handleClick(event: MouseEvent, item: NavItem): void {
        if (this.isKeyBoardAccesibility(event)) {
            return;
        }
        event.preventDefault();
        this.onNavItemClick(item);
    }

    // allow modifier clicking for regular link functions while passing left click to the parent/router
    handleSubItemClick(event: MouseEvent, item: NavItem): void {
        if (this.isKeyBoardAccesibility(event)) {
            return;
        }
        event.preventDefault();
        this.onBottomSubItemClick(item);
    }

    isKeyBoardAccesibility(event: MouseEvent) {
        return (
            event.button !== 0 ||
            event.ctrlKey ||
            event.shiftKey ||
            event.altKey ||
            event.metaKey
        );
    }

    // used directly for mobile
    onNavItemClick(selectedItem: NavItem): void {
        const key = selectedItem.key;
        this.updateActiveState(key);
        this.activeItemKeyChange.emit(key);
        this.isBottomExpanded = false;

        // close nav on modal composition
        if (this.composition === 'modal') {
            this.toggleCollapse();
        }
    }

    onBottomSubItemClick(selectedItem: NavItem): void {
        const key = selectedItem.key;
        this.hideMobileBottomNav = true;
        this.updateActiveState(key);
        this.activeItemKeyChange.emit(key);
        if (this.composition === 'mobile') {
            this.isBottomExpanded = false;
        }

        // close nav on modal composition
        if (this.composition === 'modal') {
            this.toggleCollapse();
        }
    }
}
