import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { Layout } from 'app/layout/layout.types';
import { AppConfig } from 'app/core/config/app.config';

import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import {
    BOTTOM_SUB_ITEMS,
    NavItemId,
    TOP_NAV_ITEMS
} from 'app/core/navigation/data';
import { CompanyFeature, CompanyWithPermissions } from 'api/models';
import { companyHasFeature } from 'app/core/auth/auth.helper';
import {
    BottomNavItem,
    NavItem
} from '../../ui/components/navigation/navigation.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

type DropdownIcon = 'mat_solid:arrow_drop_down' | 'mat_solid:arrow_drop_up';

const companyProtectedRoutes = ['/finance'];

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
    // App configuration
    config: AppConfig;
    layout: Layout = 'default';
    scheme: 'dark' | 'light';
    theme: string;
    hideMobileNavbar: boolean = false;
    hiddenRoutes = ['/profile', '/companies', '/account-security'];

    isCompanyScoped = false;
    dropdownIcon: DropdownIcon = 'mat_solid:arrow_drop_down';
    user: User;
    activeCompany: CompanyWithPermissions;
    protected readonly NavItemId = NavItemId;

    // New UI navigation properties
    topNavItems: NavItem[] = TOP_NAV_ITEMS;
    bottomNavItem: BottomNavItem;
    bottomSubItems: NavItem[] = BOTTOM_SUB_ITEMS;
    activeItemKey: string | null = null;

    private langChangeSubscription: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        public _userService: UserService,
        private translate: TranslateService,
        public location: Location
    ) {
        // Check if the current URL is company scoped (for finance routes)
        this.checkIfCompanyScoped(this._router.url);
        this.location.onUrlChange((url) => {
            this.checkIfCompanyScoped(url);
        });
    }

    /**
     * On init lifecycle hook
     */
    ngOnInit(): void {
        this.updateLayout();
        this.updateNavbarState(this._router.url);

        // Subscribe to language changes
        this.langChangeSubscription = this.translate.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.setNavItems();
            }
        );

        // Subscribe to user data and set bottom nav item
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                this.bottomNavItem = {
                    label: `${user.firstName} ${user.lastName}`,
                    icon: 'User',
                    activeIcon: 'UserFill',
                    image: user?.profilePic
                };
                // Re-run nav item setup when user data changes
                this.setNavItems();
            });

        // Subscribe to changes for the active company
        this._userService.activeCompany$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((company) => {
                this.activeCompany = company;
                this.reRunGuards();
            });

        // Set initial translated nav items and update the active nav item
        this.setNavItems();
        this.updateActiveNavItem();

        // Detect route changes (for e.g. empty layout)
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((event: NavigationEnd) => {
                this.updateLayout();
                this.updateActiveNavItem();
                this.updateNavbarState(event.urlAfterRedirects);
            });

        this.hideMobileNavbar = this.hiddenRoutes.some((route) =>
            this._router.url.startsWith(route)
        );
    }

    /**
     * On destroy lifecycle hook
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------
    // PRIVATE METHODS
    // -----------------------------

    private updateNavbarState(currentUrl: string): void {
        this.hideMobileNavbar = this.hiddenRoutes.some(
            (route) =>
                currentUrl === route || currentUrl.startsWith(`${route}/`)
        );
    }

    /**
     * Check if the current URL matches company-scoped routes.
     */
    private checkIfCompanyScoped(url: string): void {
        this.isCompanyScoped = false;
        for (const protectedRoute of companyProtectedRoutes) {
            if (url.includes(protectedRoute)) {
                this.isCompanyScoped = true;
                break;
            }
        }
    }

    /**
     * Set the active company
     */
    setActiveCompany(companyId: string): void {
        this._userService.setActiveCompany(companyId);
    }

    /**
     * Toggle the dropdown icon (used for the active company selector)
     */
    onDropdownClicked(): void {
        this.dropdownIcon =
            this.dropdownIcon === 'mat_solid:arrow_drop_down'
                ? 'mat_solid:arrow_drop_up'
                : 'mat_solid:arrow_drop_down';
    }

    /**
     * Re-run any navigation guards for company features
     */
    reRunGuards(): void {
        this.topNavItems = this.topNavItems.filter((item) => {
            if (item.key === NavItemId.FINANCE) {
                return !this.isFinanceHidden(item);
            }
            return true;
        });
    }

    /**
     * Determine if the Finance navigation item should be hidden.
     */
    private isFinanceHidden = (item: NavItem): boolean => {
        if (!this.user || !this.user.companies) {
            return true;
        }
        for (const company of this.user.companies) {
            if (companyHasFeature(company, CompanyFeature.Finance)) {
                return false;
            }
        }
        return true;
    };

    /**
     * When a Finance nav item is clicked, check if the active company has access.
     * If not, switch to the first company with Finance access and navigate to '/finance'.
     */
    private onFinanceClicked(): void {
        // If the active company already has Finance access, no need to switch.
        if (companyHasFeature(this.activeCompany, CompanyFeature.Finance)) {
            return;
        }

        // Find the first company with the Finance feature and activate it.
        for (const company of this.user?.companies || []) {
            if (!companyHasFeature(company, CompanyFeature.Finance)) {
                continue;
            }
            this.setActiveCompany(company._id);
            this._router.navigateByUrl('/finance');
            return;
        }
    }

    /**
     * Update the layout based on the activated route.
     */
    private updateLayout(): void {
        // Default layout if not overridden
        let newLayout: Layout = 'default';

        // Iterate over the entire activated route path
        this._activatedRoute.pathFromRoot.forEach((route) => {
            const routeData =
                route.snapshot.routeConfig && route.snapshot.routeConfig.data;
            if (routeData && routeData.layout) {
                newLayout = routeData.layout;
            }
        });
        this.layout = newLayout;
    }

    /**
     * Update active navItem based on the current URL.
     */
    private updateActiveNavItem(): void {
        const currentUrl = this._router.url;
        const allNavItems: NavItem[] = [
            ...this.topNavItems,
            ...this.bottomSubItems
        ];
        const activeItem = allNavItems.find((item) =>
            currentUrl.startsWith(item.route)
        );
        this.activeItemKey = activeItem ? activeItem.key : null;
    }

    /**
     * Handle nav item changes. If the Finance item is selected and the active company lacks the Finance feature,
     * execute the finance-specific logic.
     */
    onActiveItemChange(key: string): void {
        this.activeItemKey = key;
        const selectedItem = [...this.topNavItems, ...this.bottomSubItems].find(
            (item) => item.key === key
        );
        if (selectedItem && selectedItem.route) {
            if (selectedItem.key === NavItemId.FINANCE) {
                if (
                    !companyHasFeature(
                        this.activeCompany,
                        CompanyFeature.Finance
                    )
                ) {
                    this.onFinanceClicked();
                    return;
                }
            }
            this._router.navigateByUrl(selectedItem.route);
        }
    }

    setNavItems(): void {
        this.topNavItems = TOP_NAV_ITEMS.filter(
            (item) =>
                item.key !== NavItemId.FINANCE || !this.isFinanceHidden(item)
        ).map((item) => ({
            ...item,
            label: this.translate.instant(`app.${item.label}`)
        }));

        this.bottomSubItems = BOTTOM_SUB_ITEMS.filter((item) => {
            if (item.key === NavItemId.COMPANIES) {
                return this.user?.companies?.length > 0;
            }
            return true;
        }).map((item) => ({
            ...item,
            label: this.translate.instant(`app.${item.label}`)
        }));
    }
}
