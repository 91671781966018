import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CountdownEvent } from 'ngx-countdown';

@Component({
    selector: 'app-verification-modal',
    templateUrl: './verification-modal.component.html'
})
export class VerificationModalComponent {
    @Input() email: string;
    @Input() reSendOTP: boolean;
    @Output() confirmCode = new EventEmitter<number>();
    @Output() sendOTP = new EventEmitter<void>();
    @Output() checkCountdown = new EventEmitter<CountdownEvent>();
    @Output() closeVerificationModal = new EventEmitter<void>();

    emailVerificationCode: number;

    onConfirmCode(): void {
        this.confirmCode.emit(this.emailVerificationCode);
    }

    onSendOTP(): void {
        this.sendOTP.emit();
    }

    onCheckCountdown(event: CountdownEvent): void {
        this.checkCountdown.emit(event);
    }

    onCloseVerificationModal(): void {
        this.closeVerificationModal.emit();
    }
}
