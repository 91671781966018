<div
    class="inline-flex items-center justify-center w-4 h-4 rounded-full text-[11px] font-bold"
    [ngClass]="
        color === 'red'
            ? 'bg-[rgba(250,99,99,1)] text-primary-500'
            : 'bg-primary-600 text-white'
    "
>
    <span
        class="pt-[1px] flex items-center justify-center h-full leading-none"
        >{{ count }}</span
    >
</div>
