<div class="flex flex-col items-center shadow-lg rounded-md p-6 bg-white">
    <img src="assets/images/logo/logo-text.svg" alt="Logo" class="w-44 mb-4" />
    <div class="flex flex-col items-center">
        <img src="assets/images/verify.svg" alt="Verify" class="w-60 mb-4" />
        <p>{{ 'app.ENTER_VERIFICATION_CODE' | translate }}</p>
        <small>
            {{ 'app.VERIFICATION_CODE_SEND_TO_YOUR_EMAIL_ADDRESS' | translate }}
            <strong>{{ email }}</strong>
        </small>
        <mat-form-field appearance="fill" class="w-full mt-4">
            <input
                maxlength="6"
                minlength="6"
                [(ngModel)]="emailVerificationCode"
                matInput
                type="number"
                class="w-full"
            />
        </mat-form-field>
        <button
            class="fuse-mat-button-large w-full mt-4"
            mat-flat-button
            color="primary"
            [disabled]="!emailVerificationCode"
            (click)="onConfirmCode()"
        >
            {{ 'app.VERIFY_YOUR_ACCOUNT' | translate }}
        </button>
        <div class="my-4 border w-full"></div>
        <div class="flex items-center">
            <p class="pr-2">{{ 'app.RESEND_OTP_IN' | translate }} :</p>
            <countdown
                #cd
                (event)="onCheckCountdown($event)"
                [config]="{ leftTime: 300, format: 'm:s' }"
            ></countdown>
        </div>
        <div class="flex mt-2">
            <p
                (click)="onCloseVerificationModal()"
                class="cursor-pointer text-sky-600"
            >
                {{ 'app.BACK_TO_SIGN_UP' | translate }}
            </p>
            <ng-container *ngIf="reSendOTP">
                <p class="px-2">|</p>
                <p (click)="onSendOTP()" class="cursor-pointer text-sky-600">
                    {{ 'app.RESEND_OTP' | translate }}
                </p>
            </ng-container>
        </div>
    </div>
</div>
