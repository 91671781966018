/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkUnlinkCustomers } from '../fn/user/bulk-unlink-customers';
import { BulkUnlinkCustomers$Params } from '../fn/user/bulk-unlink-customers';
import { changeMailValidation } from '../fn/user/change-mail-validation';
import { ChangeMailValidation$Params } from '../fn/user/change-mail-validation';
import { checkEmailAvailability } from '../fn/user/check-email-availability';
import { CheckEmailAvailability$Params } from '../fn/user/check-email-availability';
import { createUser } from '../fn/user/create-user';
import { CreateUser$Params } from '../fn/user/create-user';
import { CreateUserResponseDto } from '../models/create-user-response-dto';
import { createUsers } from '../fn/user/create-users';
import { CreateUsers$Params } from '../fn/user/create-users';
import { CreateUsersResponseDto } from '../models/create-users-response-dto';
import { deleteFirmUser } from '../fn/user/delete-firm-user';
import { DeleteFirmUser$Params } from '../fn/user/delete-firm-user';
import { deleteUser } from '../fn/user/delete-user';
import { DeleteUser$Params } from '../fn/user/delete-user';
import { deleteUserProfile } from '../fn/user/delete-user-profile';
import { DeleteUserProfile$Params } from '../fn/user/delete-user-profile';
import { forgetPassword } from '../fn/user/forget-password';
import { ForgetPassword$Params } from '../fn/user/forget-password';
import { getInviteStatus } from '../fn/user/get-invite-status';
import { GetInviteStatus } from '../models/get-invite-status';
import { GetInviteStatus$Params } from '../fn/user/get-invite-status';
import { getItsMeVerificationLink } from '../fn/user/get-its-me-verification-link';
import { GetItsMeVerificationLink$Params } from '../fn/user/get-its-me-verification-link';
import { getUser } from '../fn/user/get-user';
import { GetUser$Params } from '../fn/user/get-user';
import { getUserStatusAndTaskData } from '../fn/user/get-user-status-and-task-data';
import { GetUserStatusAndTaskData$Params } from '../fn/user/get-user-status-and-task-data';
import { GetUserStatusAndTaskDataDto } from '../models/get-user-status-and-task-data-dto';
import { LinkDto } from '../models/link-dto';
import { logOut } from '../fn/user/log-out';
import { LogOut$Params } from '../fn/user/log-out';
import { resetForgetPassword } from '../fn/user/reset-forget-password';
import { ResetForgetPassword$Params } from '../fn/user/reset-forget-password';
import { resetPassword } from '../fn/user/reset-password';
import { ResetPassword$Params } from '../fn/user/reset-password';
import { SelfSignUpResponseDto } from '../models/self-sign-up-response-dto';
import { sendOtp } from '../fn/user/send-otp';
import { SendOtp$Params } from '../fn/user/send-otp';
import { setUserPassword } from '../fn/user/set-user-password';
import { SetUserPassword$Params } from '../fn/user/set-user-password';
import { signIn } from '../fn/user/sign-in';
import { SignIn$Params } from '../fn/user/sign-in';
import { signup } from '../fn/user/signup';
import { Signup$Params } from '../fn/user/signup';
import { SuccessMessageDto } from '../models/success-message-dto';
import { updateProfile } from '../fn/user/update-profile';
import { UpdateProfile$Params } from '../fn/user/update-profile';
import { updateUserAssociation } from '../fn/user/update-user-association';
import { UpdateUserAssociation$Params } from '../fn/user/update-user-association';
import { updateUserVerificationData } from '../fn/user/update-user-verification-data';
import { UpdateUserVerificationData$Params } from '../fn/user/update-user-verification-data';
import { User } from '../models/user';
import { verifyEmailConfirmation } from '../fn/user/verify-email-confirmation';
import { VerifyEmailConfirmation$Params } from '../fn/user/verify-email-confirmation';

@Injectable({ providedIn: 'root' })
export class UserApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/user';

  /**
   * Get user data .
   *
   * Get user data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params?: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user data .
   *
   * Get user data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params?: GetUser$Params, context?: HttpContext): Observable<User> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `deleteUserProfile()` */
  static readonly DeleteUserProfilePath = '/user';

  /**
   * Delete the user account and all the association.
   *
   * Delete the user profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserProfile$Response(params: DeleteUserProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return deleteUserProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete the user account and all the association.
   *
   * Delete the user profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserProfile(params: DeleteUserProfile$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.deleteUserProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/user';

  /**
   * Create new user .
   *
   * Create new user it's always trigger from account-on
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUserResponseDto>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new user .
   *
   * Create new user it's always trigger from account-on
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<CreateUserResponseDto> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUserResponseDto>): CreateUserResponseDto => r.body)
    );
  }

  /** Path part for operation `updateProfile()` */
  static readonly UpdateProfilePath = '/user';

  /**
   * Update a user profile .
   *
   * Update user profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfile$Response(params: UpdateProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return updateProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a user profile .
   *
   * Update user profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfile(params: UpdateProfile$Params, context?: HttpContext): Observable<User> {
    return this.updateProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `signup()` */
  static readonly SignupPath = '/user/signUp';

  /**
   * SignUp user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signup$Response(params: Signup$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfSignUpResponseDto>> {
    return signup(this.http, this.rootUrl, params, context);
  }

  /**
   * SignUp user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signup(params: Signup$Params, context?: HttpContext): Observable<SelfSignUpResponseDto> {
    return this.signup$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfSignUpResponseDto>): SelfSignUpResponseDto => r.body)
    );
  }

  /** Path part for operation `getInviteStatus()` */
  static readonly GetInviteStatusPath = '/user/status';

  /**
   * Get invite status of user.
   *
   * Get the status of user using the invite token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInviteStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInviteStatus$Response(params: GetInviteStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<GetInviteStatus>> {
    return getInviteStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get invite status of user.
   *
   * Get the status of user using the invite token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInviteStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInviteStatus(params: GetInviteStatus$Params, context?: HttpContext): Observable<GetInviteStatus> {
    return this.getInviteStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetInviteStatus>): GetInviteStatus => r.body)
    );
  }

  /** Path part for operation `getUserStatusAndTaskData()` */
  static readonly GetUserStatusAndTaskDataPath = '/user/{userId}/user-details';

  /**
   * Get the user invite status of user and tasks by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserStatusAndTaskData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserStatusAndTaskData$Response(params: GetUserStatusAndTaskData$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserStatusAndTaskDataDto>> {
    return getUserStatusAndTaskData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the user invite status of user and tasks by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserStatusAndTaskData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserStatusAndTaskData(params: GetUserStatusAndTaskData$Params, context?: HttpContext): Observable<GetUserStatusAndTaskDataDto> {
    return this.getUserStatusAndTaskData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserStatusAndTaskDataDto>): GetUserStatusAndTaskDataDto => r.body)
    );
  }

  /** Path part for operation `setUserPassword()` */
  static readonly SetUserPasswordPath = '/user/password';

  /**
   * Set password using invite token.
   *
   * Set the user password and email using invite token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserPassword$Response(params: SetUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return setUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Set password using invite token.
   *
   * Set the user password and email using invite token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserPassword(params: SetUserPassword$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.setUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `signIn()` */
  static readonly SignInPath = '/user/signIn';

  /**
   * SignIn user .
   *
   * Login user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signIn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signIn$Response(params: SignIn$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return signIn(this.http, this.rootUrl, params, context);
  }

  /**
   * SignIn user .
   *
   * Login user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signIn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signIn(params: SignIn$Params, context?: HttpContext): Observable<{
}> {
    return this.signIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `logOut()` */
  static readonly LogOutPath = '/user/logout';

  /**
   * Logout the user.
   *
   * Logout the user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logOut()` instead.
   *
   * This method doesn't expect any request body.
   */
  logOut$Response(params?: LogOut$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return logOut(this.http, this.rootUrl, params, context);
  }

  /**
   * Logout the user.
   *
   * Logout the user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logOut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logOut(params?: LogOut$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.logOut$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `resetPassword()` */
  static readonly ResetPasswordPath = '/user/reset-password';

  /**
   * Reset password  when user know the old one.
   *
   * Reset the user password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: ResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return resetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Reset password  when user know the old one.
   *
   * Reset the user password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: ResetPassword$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.resetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `forgetPassword()` */
  static readonly ForgetPasswordPath = '/user/forget-password-token';

  /**
   * Request Token for forget password reset.
   *
   * Send mail with new reset password token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgetPassword$Response(params: ForgetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return forgetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Request Token for forget password reset.
   *
   * Send mail with new reset password token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forgetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgetPassword(params: ForgetPassword$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.forgetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `resetForgetPassword()` */
  static readonly ResetForgetPasswordPath = '/user/forget-reset-password';

  /**
   * Reset forget password.
   *
   * Reset the user password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetForgetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetForgetPassword$Response(params: ResetForgetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return resetForgetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Reset forget password.
   *
   * Reset the user password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetForgetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetForgetPassword(params: ResetForgetPassword$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.resetForgetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `deleteUser()` */
  static readonly DeleteUserPath = '/user/{customerId}';

  /**
   * Remove association of the customer.
   *
   * Remove the association for customer always trigger from account-on
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: DeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return deleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove association of the customer.
   *
   * Remove the association for customer always trigger from account-on
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: DeleteUser$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.deleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `updateUserAssociation()` */
  static readonly UpdateUserAssociationPath = '/user/{customerId}';

  /**
   * Update settings of the customer isAssociationActive, isItsMeVerificationEnable.
   *
   * Update the association for customer always trigger from account-on
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserAssociation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserAssociation$Response(params: UpdateUserAssociation$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return updateUserAssociation(this.http, this.rootUrl, params, context);
  }

  /**
   * Update settings of the customer isAssociationActive, isItsMeVerificationEnable.
   *
   * Update the association for customer always trigger from account-on
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserAssociation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserAssociation(params: UpdateUserAssociation$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.updateUserAssociation$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `deleteFirmUser()` */
  static readonly DeleteFirmUserPath = '/user/firm/{firmId}';

  /**
   * Remove association of the firm.
   *
   * Remove the association for firms always trigger from account-on
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFirmUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFirmUser$Response(params: DeleteFirmUser$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return deleteFirmUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove association of the firm.
   *
   * Remove the association for firms always trigger from account-on
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFirmUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFirmUser(params: DeleteFirmUser$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.deleteFirmUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `checkEmailAvailability()` */
  static readonly CheckEmailAvailabilityPath = '/user/check-email';

  /**
   * Check the email is available or not.
   *
   * Check the email is available or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkEmailAvailability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkEmailAvailability$Response(params: CheckEmailAvailability$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkEmailAvailability(this.http, this.rootUrl, params, context);
  }

  /**
   * Check the email is available or not.
   *
   * Check the email is available or not
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkEmailAvailability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkEmailAvailability(params: CheckEmailAvailability$Params, context?: HttpContext): Observable<void> {
    return this.checkEmailAvailability$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendOtp()` */
  static readonly SendOtpPath = '/user/send-otp';

  /**
   * Resend otp to the user.
   *
   * Send the otp for email verification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendOtp$Response(params: SendOtp$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return sendOtp(this.http, this.rootUrl, params, context);
  }

  /**
   * Resend otp to the user.
   *
   * Send the otp for email verification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendOtp(params: SendOtp$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.sendOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `verifyEmailConfirmation()` */
  static readonly VerifyEmailConfirmationPath = '/user/email-confirmation';

  /**
   * Email confirmation while self signUP.
   *
   * Email confirmation while self signUP
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyEmailConfirmation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyEmailConfirmation$Response(params: VerifyEmailConfirmation$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return verifyEmailConfirmation(this.http, this.rootUrl, params, context);
  }

  /**
   * Email confirmation while self signUP.
   *
   * Email confirmation while self signUP
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyEmailConfirmation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyEmailConfirmation(params: VerifyEmailConfirmation$Params, context?: HttpContext): Observable<{
}> {
    return this.verifyEmailConfirmation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getItsMeVerificationLink()` */
  static readonly GetItsMeVerificationLinkPath = '/user/its-me/verify';

  /**
   * get the verification link for itsMe.
   *
   * Get the its-me verification link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItsMeVerificationLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItsMeVerificationLink$Response(params: GetItsMeVerificationLink$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkDto>> {
    return getItsMeVerificationLink(this.http, this.rootUrl, params, context);
  }

  /**
   * get the verification link for itsMe.
   *
   * Get the its-me verification link
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItsMeVerificationLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItsMeVerificationLink(params: GetItsMeVerificationLink$Params, context?: HttpContext): Observable<LinkDto> {
    return this.getItsMeVerificationLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkDto>): LinkDto => r.body)
    );
  }

  /** Path part for operation `updateUserVerificationData()` */
  static readonly UpdateUserVerificationDataPath = '/user/its-me/{userId}';

  /**
   * Update verification status registration number and partner details.
   *
   * Update the itsMe status and partner details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserVerificationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserVerificationData$Response(params: UpdateUserVerificationData$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return updateUserVerificationData(this.http, this.rootUrl, params, context);
  }

  /**
   * Update verification status registration number and partner details.
   *
   * Update the itsMe status and partner details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserVerificationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserVerificationData(params: UpdateUserVerificationData$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.updateUserVerificationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `changeMailValidation()` */
  static readonly ChangeMailValidationPath = '/user/validate/change-mail';

  /**
   * Change email address.
   *
   * Check email is avialbel or not for requested user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeMailValidation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeMailValidation$Response(params: ChangeMailValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return changeMailValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * Change email address.
   *
   * Check email is avialbel or not for requested user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeMailValidation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeMailValidation(params: ChangeMailValidation$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.changeMailValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `createUsers()` */
  static readonly CreateUsersPath = '/users/bulk';

  /**
   * Bulk Create new users.
   *
   * Create users in bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUsers$Response(params: CreateUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CreateUsersResponseDto>>> {
    return createUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk Create new users.
   *
   * Create users in bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUsers(params: CreateUsers$Params, context?: HttpContext): Observable<Array<CreateUsersResponseDto>> {
    return this.createUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CreateUsersResponseDto>>): Array<CreateUsersResponseDto> => r.body)
    );
  }

  /** Path part for operation `bulkUnlinkCustomers()` */
  static readonly BulkUnlinkCustomersPath = '/users/bulk/unlink';

  /**
   * Update user association in bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUnlinkCustomers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUnlinkCustomers$Response(params: BulkUnlinkCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkUnlinkCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Update user association in bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkUnlinkCustomers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUnlinkCustomers(params: BulkUnlinkCustomers$Params, context?: HttpContext): Observable<void> {
    return this.bulkUnlinkCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
