<div
    class="rounded-2xl px-4 py-3 gap-3 max-w-[550px] flex cursor-pointer items-center shadow-depth-2-regular hover:shadow-depth-2-hover bg-neutral-000"
    (click)="handleClick()"
>
    <div class="w-9 h-9 flex items-center justify-center">
        <ui-icon-badge
            [icon]="icon"
            [mode]="badgeColor"
            [fill]="badgeIconFill"
        ></ui-icon-badge>
    </div>
    <div class="flex-1 min-w-0">
        <div class="t-h4 truncate">
            {{ label }}
        </div>
    </div>
</div>
