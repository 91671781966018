<div
    class="flex w-full h-full flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0"
>
    <div
        class="md:flex h-full md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card"
    >
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Title -->
            <div
                class="mt-8 text-4xl font-extrabold tracking-tight leading-tight"
            >
                {{ 'app.SIGN_IN' | translate }}
            </div>
            <!-- Content Projection for Custom Messages -->
            <ng-content select="[custom-message]"></ng-content>

            <!-- Alert -->
            <fuse-alert
                class="mt-8"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'"
            >
                {{ alert.message }}
            </fuse-alert>

            <!-- Sign in form -->
            <form
                class="mt-8"
                [formGroup]="signInForm"
                *ngIf="signInForm"
                #signInNgForm="ngForm"
            >
                <!-- Email field -->
                <mat-form-field class="w-full" [ngClass]="emailClass">
                    <mat-label>{{ 'app.EMAIL_ADDRESS' | translate }}</mat-label>
                    <input
                        id="email"
                        matInput
                        [formControlName]="'email'"
                        [readonly]="emailReadOnly"
                        data-cy="email"
                    />
                    <mat-error
                        *ngIf="signInForm.get('email').hasError('required')"
                    >
                        {{ 'app.EMAIL_ADDRESS_IS_REQUIRED' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="signInForm.get('email').hasError('email')"
                    >
                        {{
                            'app.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate
                        }}
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label>{{ 'app.PASSWORD' | translate }}</mat-label>
                    <input
                        id="password"
                        matInput
                        type="password"
                        [formControlName]="'password'"
                        #passwordField
                        data-cy="password"
                    />
                    <button
                        mat-icon-button
                        type="button"
                        (click)="
                            passwordField.type === 'password'
                                ? (passwordField.type = 'text')
                                : (passwordField.type = 'password')
                        "
                        matSuffix
                    >
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye'"
                        ></mat-icon>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye-off'"
                        ></mat-icon>
                    </button>
                    <mat-error
                        *ngIf="
                            signInForm.get('password').errors &&
                            !signInForm.get('password').hasError('maxlength')
                        "
                    >
                        {{
                            'app.PASSWORD_MUST_CONTAIN_AT_LEAST_EIGHT_CHARACTERS_AT_LEAST_ONE_NUMBER_AND_BOTH_LOWER_AND_UPPERCASE_LETTERS_AND_SPECIAL_CHARACTERS'
                                | translate
                        }}
                    </mat-error>
                    <mat-error
                        *ngIf="signInForm.get('password').hasError('maxlength')"
                    >
                        {{ 'app.PASSWORD_MAXIMUM_LENGTH' | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- Actions -->
                <div
                    class="inline-flex items-center justify-between w-full mt-5"
                >
                    <mat-checkbox
                        data-cy="rememberMe"
                        class="-ml-2"
                        [color]="'primary'"
                        [formControlName]="'rememberMe'"
                    >
                        {{ 'app.REMEMBER_ME' | translate }}
                    </mat-checkbox>
                    <a
                        class="text-md font-medium text-primary-500 hover:underline"
                        [routerLink]="['/forgot-password']"
                        >{{ 'app.FORGOT_PASSWORD' | translate }}?
                    </a>
                </div>

                <!-- Submit button -->
                <button
                    class="fuse-mat-button-large w-full mt-6"
                    mat-flat-button
                    [color]="'primary'"
                    [disabled]="signInForm.disabled"
                    (click)="onSignIn()"
                    data-cy="loginButton"
                >
                    <span *ngIf="!signInForm.disabled">
                        {{ 'app.SIGN_IN' | translate }}
                    </span>
                    <mat-progress-spinner
                        *ngIf="signInForm.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"
                    ></mat-progress-spinner>
                </button>
            </form>
        </div>
    </div>

    <!-- Logo and Message Section -->
    <app-logo-and-message
        [inviteMessage]="inviteMessage"
    ></app-logo-and-message>
</div>

<!-- Two-Factor Authentication Modal -->
<div
    class="w-screen h-screen fixed top-0 left-0 bg-white z-50"
    *ngIf="isTFAEnable"
>
    <img
        src="assets/images/logo/logo-text.svg"
        alt=""
        class="flex mx-auto p-6 w-44 items-center justify-center"
    />
    <div
        class="flex max-w-md flex-col items-center mx-auto shadow-lg rounded-md p-6"
    >
        <div mat-dialog-content class="p-0">
            <img src="assets/images/verify.svg" alt="" class="w-60 mx-auto" />
            <h2 class="text-md leading-5 mb-3">
                {{ 'app.TFA_DIS1' | translate }}
            </h2>
            <h2 class="text-md leading-5 mb-8">
                {{ 'app.TFA_DIS2' | translate }}
            </h2>
            <p>{{ 'app.ENTER_VERIFICATION_CODE' | translate }}</p>
            <mat-form-field appearance="fill" class="w-full">
                <input
                    maxlength="6"
                    minlength="6"
                    matInput
                    type="number"
                    class="w-full"
                    [(ngModel)]="enableAuthentication"
                    data-cy="authenticationID"
                />
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button
                data-cy="accountVerify"
                class="fuse-mat-button-large w-full"
                mat-flat-button
                [color]="'primary'"
                [disabled]="
                    isLoading || !enableAuthentication?.toString().length
                "
                (click)="onVerifyTFA()"
            >
                <span> {{ 'app.VERIFY_YOUR_ACCOUNT' | translate }} </span>
            </button>
        </div>
    </div>
</div>

<ng-template #ghostLoader>
    <div class="w-full">
        <div class="w-24 h-4" [skeleton]="isLoading"></div>
        <div class="w-full h-12" [skeleton]="isLoading"></div>
    </div>
</ng-template>
