import {
    AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    Input,
    Renderer2
} from '@angular/core';
import { FillIcons, IconName } from '../../assets/icons/icons';
import { IconComponent } from './icon.component';

@Directive({
    standalone: true
})
export class FillDirective implements AfterViewInit {
    @Input() public fill: boolean = false;
    @Input() public gradientUrl?: string;

    constructor(
        public component: IconComponent,
        @Inject(ElementRef) private elem: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2
    ) {
        component.onChangesCallback = () => {
            this.fillElement();
        };
    }

    ngAfterViewInit(): void {
        this.fillElement();
    }

    fillElement() {
        const svgElements = this.elem.nativeElement.querySelectorAll('svg');

        svgElements.forEach((svg: SVGElement) => {
            if (
                this.fill ||
                FillIcons.includes(this.component.name as IconName)
            ) {
                this.applyFilledStyles(svg);
            } else {
                this.applyUnfilledStyles(svg);
            }
        });
    }

    private applyFilledStyles(svg: SVGElement): void {
        if (this.gradientUrl) {
            this.renderer.setAttribute(
                svg,
                'fill',
                `url(#${this.gradientUrl})`
            );
        } else {
            this.renderer.setAttribute(svg, 'fill', this.component.color);
        }
        this.renderer.setAttribute(svg, 'stroke', 'none');
    }

    private applyUnfilledStyles(svg: SVGElement): void {
        this.renderer.setAttribute(svg, 'fill', 'none');
        if (this.gradientUrl) {
            this.renderer.setAttribute(
                svg,
                'stroke',
                `url(#${this.gradientUrl})`
            );
        } else {
            this.renderer.setAttribute(svg, 'stroke', this.component.color);
        }
    }
}
