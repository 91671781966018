<div
    class="h-fit"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (menuClosed)="onMenuButtonClick('close')"
    (menuOpened)="onMenuButtonClick('open')"
    aria-haspopup="menu"
    aria-expanded="false"
>
    <ng-container
        [ngTemplateOutlet]="customTrigger ?? defaultCustomTrigger"
    ></ng-container>
    <ng-template #defaultCustomTrigger>
        <ui-button
            [variant]="buttonVariant"
            [label]="buttonText"
            [fullWidth]="buttonFullWidth"
            [rightIcon]="menuRightIcon"
            [icon]="buttonIcon"
        ></ui-button>
    </ng-template>
</div>
<mat-menu
    #menu="matMenu"
    class="bg-transparent max-w-none my-1 rounded-lg shadow-depth-2-regular [&_.mat-mdc-menu-content]:flex [&_.mat-mdc-menu-content]:flex-col [&_.mat-mdc-menu-content]:p-0 [&_.mat-mdc-menu-content]:rounded-lg [&_.mat-mdc-menu-content]:bg-neutral-000 [&_.mat-mdc-menu-content]:gap-1 [&_.mat-mdc-menu-content]:py-1"
>
    <!-- All button -->
    <button
        mat-menu-item
        *ngIf="multiple && allSelector"
        (click)="onItemClick($event, allDropdownItem)"
        [class]="buttonStyleClasses"
        [class.mat-mdc-menu-item]="false"
    >
        <ng-container
            [ngTemplateOutlet]="itemContent"
            [ngTemplateOutletContext]="{ item: allDropdownItem }"
        />
    </button>

    <!-- Input dropdown items -->
    <button
        mat-menu-item
        *ngFor="let item of items || []"
        (click)="onItemClick($event, item)"
        [class]="buttonStyleClasses"
        [class.mat-mdc-menu-item]="false"
    >
        <ng-container
            [ngTemplateOutlet]="itemContent"
            [ngTemplateOutletContext]="{ item: item }"
        />
    </button>
</mat-menu>

<ng-template #itemContent let-item="item">
    <ng-container
        [ngTemplateOutlet]="customItem ?? defaultCustomItem"
        [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
</ng-template>

<ng-template #defaultCustomItem let-item="item">
    <div class="pl-1 gap-2 flex items-center my-auto h-5 text-primary-600">
        <ui-icon
            *ngIf="multiple"
            [size]="16"
            [name]="isItemSelected(item) ? 'SquareCheckFill' : 'Square'"
            class="my-auto w-4 h-4"
        ></ui-icon>
        <ui-icon
            *ngIf="!multiple && item.icon"
            [size]="16"
            [name]="item.icon"
            class="my-auto w-4 h-4"
        ></ui-icon>
        <span
            [ngClass]="{
                't-body-bold': isItemSelected(item),
                't-body': !isItemSelected(item),
            }"
            >{{ item.name }}</span
        >
        <ui-badge
            *ngIf="item.count"
            class="my-auto ml-auto"
            [count]="item.count"
            [color]="isItemSelected(item) ? 'red' : 'black'"
        />
        <ui-icon
            *ngIf="multiple && !item.count && item.icon"
            [size]="16"
            [name]="item.icon"
            class="my-auto ml-auto w-4 h-4"
        ></ui-icon>
    </div>
</ng-template>
