import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

export type BadgeColor = 'black' | 'red';

@Component({
    selector: 'ui-badge',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './badge.component.html'
})
export class BadgeComponent {
    @Input() count: number = 0;
    @Input() color: BadgeColor = 'black';
}
