/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum SupportedCountries {
  Be = 'BE',
  Nl = 'NL',
  At = 'AT',
  Bg = 'BG',
  Hr = 'HR',
  Cy = 'CY',
  Cz = 'CZ',
  Dk = 'DK',
  Ee = 'EE',
  Fi = 'FI',
  Fr = 'FR',
  De = 'DE',
  El = 'EL',
  It = 'IT',
  Lv = 'LV',
  Lt = 'LT',
  Lu = 'LU',
  Mt = 'MT',
  Pl = 'PL',
  Pt = 'PT',
  Ro = 'RO',
  Sk = 'SK',
  Si = 'SI',
  Es = 'ES',
  Se = 'SE',
  Xi = 'XI'
}
