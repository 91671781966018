import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItem } from '../../../ui/components/dropdown/dropdown.component';
import { ToggleTab } from '../../../ui/components/toggle-tabs/toggle-tabs.component';
import { User } from '../../core/user/user.types';
import { TaskCountWithTotal } from '../../core/web-socket/web-socket.types';
import { UserService } from '../../core/user/user.service';
import { TaskService } from '../../../@fuse/services/pti/task.service';
import { TranslateService } from '@ngx-translate/core';

export const ALL_SELECTOR = 'All';

@Component({
    selector: 'app-user-company-selector',
    templateUrl: './user-company-selector.html',
    styleUrls: ['./user-company-selector.scss']
})
export class UserCompanySelectorComponent implements OnInit {
    @Input() showAllOption = false;
    @Output() onEntitySelected = new EventEmitter<string>();

    public user: User;
    public outstandingTasks: TaskCountWithTotal;
    public tabItems: ToggleTab[] = [];
    public dropdownItems: DropdownItem<string>[] = [];
    public selectedItem: DropdownItem<string>;

    constructor(
        private readonly _userService: UserService,
        private readonly _taskService: TaskService,
        private readonly translationService: TranslateService
    ) {}

    ngOnInit() {
        this._userService.user$.subscribe((user: User) => {
            this.user = user;
            this.initializeItems();
        });
        this._taskService
            .taskCount$()
            .subscribe((taskCount: TaskCountWithTotal) => {
                this.outstandingTasks = taskCount;
                if (taskCount) {
                    this.reloadCounts();
                }
            });
    }

    private reloadCounts() {
        for (const tabItem of this.tabItems) {
            tabItem.count = this.getCountById(tabItem.key);
        }

        for (const dropdownItem of this.dropdownItems) {
            dropdownItem.count = this.getCountById(dropdownItem.item);
        }
    }

    getCountById(id: string) {
        const companyIds = (this.user.companies || []).map(
            (company) => company._id
        );
        if (id === ALL_SELECTOR) {
            return this.outstandingTasks?.firmOutstandingCounts?.[
                this.user.firmId
            ];
        } else if (companyIds.includes(id)) {
            return this.outstandingTasks?.companyOutstandingCounts?.[id];
        } else {
            return this.outstandingTasks?.userOutstandingCounts?.[
                this.user._id
            ];
        }
    }

    private initializeItems(): void {
        this.tabItems = [];
        this.dropdownItems = [];
        // Add all option
        if (this.showAllOption) {
            this.tabItems.push({
                key: ALL_SELECTOR,
                label: this.translationService.instant('app.ALL'),
                count: this.getCountById(ALL_SELECTOR),
                icon: 'CheckCheck'
            });
            this.dropdownItems.push({
                icon: 'CheckCheck',
                item: ALL_SELECTOR,
                name: this.translationService.instant('app.ALL'),
                count: this.getCountById(ALL_SELECTOR)
            });
        }
        // Add company options
        for (const company of this.user.companies || []) {
            this.tabItems.push({
                key: company._id,
                label: company.name,
                count: this.getCountById(company._id),
                icon: 'BuildingFill'
            });
            this.dropdownItems.push({
                icon: 'BuildingFill',
                item: company._id,
                name: company.name,
                count: this.getCountById(company._id)
            });
        }
        // Add person option
        this.tabItems.push({
            key: this.user._id,
            label: `${this.user.firstName} ${this.user.lastName}`,
            count: this.getCountById(this.user._id),
            icon: 'UserFill'
        });
        this.dropdownItems.push({
            icon: 'UserFill',
            item: this.user._id,
            name: `${this.user.firstName} ${this.user.lastName}`,
            count: this.getCountById(this.user._id)
        });

        this.selectedItem = this.dropdownItems?.[0];
        this.onSelectionChange(this.selectedItem?.item);
    }

    onSelectionChange(selectedItem: string): void {
        this.onEntitySelected.emit(selectedItem);
    }
}
