<div
    class="fixed inset-0 z-50 h-screen pointer-events-auto"
    [ngClass]="{ 
        hidden: !isOpen,
    }"
>
    <!-- Overlay -->
    <div
        class="absolute inset-0 bg-[#101729] opacity-50 back transition-opacity hover:cursor-pointer"
        (click)="closeModal()"
    ></div>

    <!-- Modal -->
    <div class="h-full min-[520px]:p-3 flex items-center">
        <div
            class="relative m-3 max-w-[520px] mx-auto flex flex-col flex-1 w-full"
            [ngClass]="{
                'h-full': growVertically,
                'min-[820px]:mr-0': position === 'right',
                'min-[820px]:max-w-[375px]': position === 'right',
                'h-fit p-5': !growVertically,
            }"
        >
            <ui-default-modal-content
                [growVertically]="growVertically"
                (onClose)="closeModal()"
            >
                <ng-content></ng-content>
            </ui-default-modal-content>
        </div>
    </div>
</div>
