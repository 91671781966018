import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ScrollState = 'top' | 'middle' | 'bottom' | 'none';

@Directive({
    selector: '[scrollArea]',
    standalone: true
})
export class ScrollAreaDirective implements AfterViewInit, OnDestroy {
    constructor(private elementRef: ElementRef<HTMLElement>) {}

    @Input() scrollState: ScrollState = 'none';
    @Output() scrollStateChange = new EventEmitter<ScrollState>();

    // we allow comparing with higher numbers, the user can define higher so that the
    // experience seems smoother in some situations
    @Input() errorPixels: number = 1;
    private scrollEvent$ = new BehaviorSubject<Event>(null);

    ngAfterViewInit(): void {
        // this will make sure it's executed the after all "afterViewInit"
        setTimeout(() => {
            this.checkScrollPosition();
        });

        this.scrollEvent$.subscribe(() => {
            this.checkScrollPosition();
        });
    }

    ngOnDestroy(): void {
        this.scrollEvent$.unsubscribe();
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: Event): void {
        this.scrollEvent$.next(event);
    }

    private checkScrollPosition(): void {
        const element = this.elementRef.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;
        const maxScrollTop = scrollHeight - clientHeight;
        const isScrollable = scrollHeight > clientHeight;

        let newState: ScrollState = 'none';
        if (!isScrollable) {
            newState = 'none';
        } else if (scrollTop <= this.errorPixels) {
            newState = 'top';
        } else if (maxScrollTop - scrollTop <= this.errorPixels) {
            newState = 'bottom';
        } else {
            newState = 'middle';
        }

        // Only emit if the state has changed
        if (this.scrollState !== newState) {
            this.scrollState = newState;
            this.scrollStateChange.emit(newState);
        }
    }

    scrollToTop(): void {
        this.elementRef.nativeElement.scrollTop = 0;
    }

    scrollToBottom(): void {
        const element = this.elementRef.nativeElement;
        element.scrollTop = element.scrollHeight - element.clientHeight;
    }

    isScrollable(): boolean {
        const element = this.elementRef.nativeElement;
        return element.scrollHeight > element.clientHeight;
    }

    getScrollPercentage(): number {
        const element = this.elementRef.nativeElement;
        const maxScroll = element.scrollHeight - element.clientHeight;
        return maxScroll > 0 ? (element.scrollTop / maxScroll) * 100 : 0;
    }
}
