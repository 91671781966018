import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, ButtonDescriptor } from '../button/button.component';

export interface FirmCardDetails {
    logo?: string;
    fallbackTitle?: string;
    description: string;
}

@Component({
    selector: 'ui-firm-card',
    standalone: true,
    imports: [CommonModule, ButtonComponent],
    templateUrl: './firm-card.component.html'
})
export class FirmCardComponent {
    @Input() firmCardDetails: FirmCardDetails;
    @Input() buttonDescriptor?: ButtonDescriptor;

    @Output() buttonClicked = new EventEmitter<void>();

    handleButtonClick(): void {
        this.buttonClicked.emit();
    }

    handleImageError(event: Event): void {
        this.firmCardDetails.logo = undefined;
    }
}
