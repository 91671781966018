import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconName } from '../../assets/icons/icons';
import { TabItemComponent } from './tab-item/tab-item.component';

export interface ToggleTab {
    key: string;
    label: string;
    count?: number;
    icon?: IconName;
    rightIcon?: IconName;
}

@Component({
    selector: 'ui-toggle-tabs',
    standalone: true,
    imports: [CommonModule, TabItemComponent],
    templateUrl: './toggle-tabs.component.html',
    styleUrls: ['./toggle-tabs.component.scss']
})
export class ToggleTabsComponent {
    @Input() tabItems: ToggleTab[] = [];
    @Input() selectedTab = '';
    @Output() selectedTabChange = new EventEmitter<string>();

    @Input() fullWidth: boolean = false;

    ngOnInit() {
        if (!this.selectedTab && this?.tabItems?.length) {
            this.selectedTab = this.tabItems[0].key;
        }
    }

    selectTab(tabKey: string): void {
        if (this.selectedTab !== tabKey) {
            this.selectedTab = tabKey;
            this.selectedTabChange.emit(tabKey);
        }
    }
}
