/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BulkCreateTaskResponseDto } from '../models/bulk-create-task-response-dto';
import { createTask } from '../fn/task/create-task';
import { CreateTask$Params } from '../fn/task/create-task';
import { CreateTaskResponseDto } from '../models/create-task-response-dto';
import { createTasks } from '../fn/task/create-tasks';
import { CreateTasks$Params } from '../fn/task/create-tasks';
import { deleteTask } from '../fn/task/delete-task';
import { DeleteTask$Params } from '../fn/task/delete-task';
import { deleteTasks } from '../fn/task/delete-tasks';
import { DeleteTasks$Params } from '../fn/task/delete-tasks';
import { downloadAttachments } from '../fn/task/download-attachments';
import { DownloadAttachments$Params } from '../fn/task/download-attachments';
import { expireTask } from '../fn/task/expire-task';
import { ExpireTask$Params } from '../fn/task/expire-task';
import { getBatchTasks } from '../fn/task/get-batch-tasks';
import { GetBatchTasks$Params } from '../fn/task/get-batch-tasks';
import { getESignDoc } from '../fn/task/get-e-sign-doc';
import { GetESignDoc$Params } from '../fn/task/get-e-sign-doc';
import { getESignSourceDoc } from '../fn/task/get-e-sign-source-doc';
import { GetESignSourceDoc$Params } from '../fn/task/get-e-sign-source-doc';
import { getKamigoTasksByCustomers } from '../fn/task/get-kamigo-tasks-by-customers';
import { GetKamigoTasksByCustomers$Params } from '../fn/task/get-kamigo-tasks-by-customers';
import { getPaginatedTask } from '../fn/task/get-paginated-task';
import { GetPaginatedTask$Params } from '../fn/task/get-paginated-task';
import { getPtiLinkOfOldestTask } from '../fn/task/get-pti-link-of-oldest-task';
import { GetPtiLinkOfOldestTask$Params } from '../fn/task/get-pti-link-of-oldest-task';
import { getTaskCount } from '../fn/task/get-task-count';
import { GetTaskCount$Params } from '../fn/task/get-task-count';
import { getTaskLink } from '../fn/task/get-task-link';
import { GetTaskLink$Params } from '../fn/task/get-task-link';
import { GetUserTaskCountWithFirmDto } from '../models/get-user-task-count-with-firm-dto';
import { LinkDto } from '../models/link-dto';
import { PaginatedTasksDto } from '../models/paginated-tasks-dto';
import { SuccessMessageDto } from '../models/success-message-dto';
import { updateAttachment } from '../fn/task/update-attachment';
import { UpdateAttachment$Params } from '../fn/task/update-attachment';
import { updateESignTaskStatus } from '../fn/task/update-e-sign-task-status';
import { UpdateESignTaskStatus$Params } from '../fn/task/update-e-sign-task-status';
import { updateTask } from '../fn/task/update-task';
import { UpdateTask$Params } from '../fn/task/update-task';
import { updateTaskResponse } from '../fn/task/update-task-response';
import { UpdateTaskResponse$Params } from '../fn/task/update-task-response';
import { updateTasksStatus } from '../fn/task/update-tasks-status';
import { UpdateTasksStatus$Params } from '../fn/task/update-tasks-status';
import { uploadRequestedFiles } from '../fn/task/upload-requested-files';
import { UploadRequestedFiles$Params } from '../fn/task/upload-requested-files';
import { UploadRequestedFilesResponseDto } from '../models/upload-requested-files-response-dto';

@Injectable({ providedIn: 'root' })
export class TaskApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadAttachments()` */
  static readonly DownloadAttachmentsPath = '/task/download/{type}/{taskId}';

  /**
   * Download attachments for payment request, notice and general task .
   *
   * Download attachments for notice and general task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadAttachments$Response(params: DownloadAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * Download attachments for payment request, notice and general task .
   *
   * Download attachments for notice and general task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadAttachments(params: DownloadAttachments$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `createTask()` */
  static readonly CreateTaskPath = '/task/{userId}';

  /**
   * Create new task .
   *
   * Create new task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTask$Response(params: CreateTask$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTaskResponseDto>> {
    return createTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new task .
   *
   * Create new task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTask(params: CreateTask$Params, context?: HttpContext): Observable<CreateTaskResponseDto> {
    return this.createTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTaskResponseDto>): CreateTaskResponseDto => r.body)
    );
  }

  /** Path part for operation `deleteTask()` */
  static readonly DeleteTaskPath = '/task/{taskId}';

  /**
   * Delete task of customer.
   *
   * delete task for customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask$Response(params: DeleteTask$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return deleteTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete task of customer.
   *
   * delete task for customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask(params: DeleteTask$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.deleteTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `updateTask()` */
  static readonly UpdateTaskPath = '/task/{taskId}';

  /**
   * Update task .
   *
   * Update task status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTask$Response(params: UpdateTask$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return updateTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Update task .
   *
   * Update task status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTask(params: UpdateTask$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.updateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `updateTaskResponse()` */
  static readonly UpdateTaskResponsePath = '/task/task-response/{taskId}';

  /**
   * Update response of notice and general task .
   *
   * Update notice and general task status and add answer if general task status is declined
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTaskResponse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaskResponse$Response(params: UpdateTaskResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return updateTaskResponse(this.http, this.rootUrl, params, context);
  }

  /**
   * Update response of notice and general task .
   *
   * Update notice and general task status and add answer if general task status is declined
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTaskResponse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaskResponse(params: UpdateTaskResponse$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.updateTaskResponse$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `updateAttachment()` */
  static readonly UpdateAttachmentPath = '/task/attachment/{taskId}';

  /**
   * Update attachments for notice and general task .
   *
   * Update attachments for notice and general task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAttachment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAttachment$Response(params: UpdateAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return updateAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Update attachments for notice and general task .
   *
   * Update attachments for notice and general task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAttachment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAttachment(params: UpdateAttachment$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.updateAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `updateESignTaskStatus()` */
  static readonly UpdateESignTaskStatusPath = '/task/e-sign/{taskId}';

  /**
   * Update  e-sign status.
   *
   * Update the status of e-sign task status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateESignTaskStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateESignTaskStatus$Response(params: UpdateESignTaskStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return updateESignTaskStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update  e-sign status.
   *
   * Update the status of e-sign task status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateESignTaskStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateESignTaskStatus(params: UpdateESignTaskStatus$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.updateESignTaskStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `getTaskCount()` */
  static readonly GetTaskCountPath = '/task';

  /**
   * Get count for outstanding task of user with firm.
   *
   * Get Outstanding task count
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskCount$Response(params?: GetTaskCount$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserTaskCountWithFirmDto>> {
    return getTaskCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get count for outstanding task of user with firm.
   *
   * Get Outstanding task count
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaskCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskCount(params?: GetTaskCount$Params, context?: HttpContext): Observable<GetUserTaskCountWithFirmDto> {
    return this.getTaskCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserTaskCountWithFirmDto>): GetUserTaskCountWithFirmDto => r.body)
    );
  }

  /** Path part for operation `getTaskLink()` */
  static readonly GetTaskLinkPath = '/task/{taskId}/link';

  /**
   * Get the pti link for the task.
   *
   * Get pti task link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskLink$Response(params: GetTaskLink$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkDto>> {
    return getTaskLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the pti link for the task.
   *
   * Get pti task link
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaskLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskLink(params: GetTaskLink$Params, context?: HttpContext): Observable<LinkDto> {
    return this.getTaskLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkDto>): LinkDto => r.body)
    );
  }

  /** Path part for operation `getPtiLinkOfOldestTask()` */
  static readonly GetPtiLinkOfOldestTaskPath = '/task/link';

  /**
   * Get the pti link of oldest pending task.
   *
   * Get pti task link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPtiLinkOfOldestTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPtiLinkOfOldestTask$Response(params: GetPtiLinkOfOldestTask$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkDto>> {
    return getPtiLinkOfOldestTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the pti link of oldest pending task.
   *
   * Get pti task link
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPtiLinkOfOldestTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPtiLinkOfOldestTask(params: GetPtiLinkOfOldestTask$Params, context?: HttpContext): Observable<LinkDto> {
    return this.getPtiLinkOfOldestTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkDto>): LinkDto => r.body)
    );
  }

  /** Path part for operation `getPaginatedTask()` */
  static readonly GetPaginatedTaskPath = '/task/{status}';

  /**
   * Get task data with pagination.
   *
   * Retrieves paginated task data based on the provided status and query (optional companyId) parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaginatedTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaginatedTask$Response(params: GetPaginatedTask$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedTasksDto>> {
    return getPaginatedTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Get task data with pagination.
   *
   * Retrieves paginated task data based on the provided status and query (optional companyId) parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaginatedTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaginatedTask(params: GetPaginatedTask$Params, context?: HttpContext): Observable<PaginatedTasksDto> {
    return this.getPaginatedTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginatedTasksDto>): PaginatedTasksDto => r.body)
    );
  }

  /** Path part for operation `getESignDoc()` */
  static readonly GetESignDocPath = '/task/e-sign/doc/{taskId}';

  /**
   * Get signed url of e-sign doc.
   *
   * Get signed url of e-sign doc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getESignDoc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getESignDoc$Response(params: GetESignDoc$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkDto>> {
    return getESignDoc(this.http, this.rootUrl, params, context);
  }

  /**
   * Get signed url of e-sign doc.
   *
   * Get signed url of e-sign doc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getESignDoc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getESignDoc(params: GetESignDoc$Params, context?: HttpContext): Observable<LinkDto> {
    return this.getESignDoc$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkDto>): LinkDto => r.body)
    );
  }

  /** Path part for operation `expireTask()` */
  static readonly ExpireTaskPath = '/task/expire/{firmId}';

  /**
   * Expired tasks from the given firmId by which status is sent or toProcess then change task status to expired.
   *
   * Only the Financial year changes then at the time of migration this endpoint will use
   * Expired tasks which status is sent or toProcess
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `expireTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  expireTask$Response(params: ExpireTask$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return expireTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Expired tasks from the given firmId by which status is sent or toProcess then change task status to expired.
   *
   * Only the Financial year changes then at the time of migration this endpoint will use
   * Expired tasks which status is sent or toProcess
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `expireTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  expireTask(params: ExpireTask$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.expireTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `uploadRequestedFiles()` */
  static readonly UploadRequestedFilesPath = '/task/upload/{taskId}/{fileId}';

  /**
   * Forward requested files to accounton and upload it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadRequestedFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadRequestedFiles$Response(params: UploadRequestedFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<UploadRequestedFilesResponseDto>> {
    return uploadRequestedFiles(this.http, this.rootUrl, params, context);
  }

  /**
   * Forward requested files to accounton and upload it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadRequestedFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadRequestedFiles(params: UploadRequestedFiles$Params, context?: HttpContext): Observable<UploadRequestedFilesResponseDto> {
    return this.uploadRequestedFiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<UploadRequestedFilesResponseDto>): UploadRequestedFilesResponseDto => r.body)
    );
  }

  /** Path part for operation `getESignSourceDoc()` */
  static readonly GetESignSourceDocPath = '/task/e-sign/source-doc/{taskId}';

  /**
   * Get source doc esign attachment url .
   *
   * Get uploaded source eSign document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getESignSourceDoc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getESignSourceDoc$Response(params: GetESignSourceDoc$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkDto>> {
    return getESignSourceDoc(this.http, this.rootUrl, params, context);
  }

  /**
   * Get source doc esign attachment url .
   *
   * Get uploaded source eSign document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getESignSourceDoc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getESignSourceDoc(params: GetESignSourceDoc$Params, context?: HttpContext): Observable<LinkDto> {
    return this.getESignSourceDoc$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkDto>): LinkDto => r.body)
    );
  }

  /** Path part for operation `getKamigoTasksByCustomers()` */
  static readonly GetKamigoTasksByCustomersPath = '/task/customer/{customerId}';

  /**
   * Get tasks data of customer to show in AOS customer communication overview page.
   *
   * Return all tasks data of customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKamigoTasksByCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKamigoTasksByCustomers$Response(params: GetKamigoTasksByCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getKamigoTasksByCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tasks data of customer to show in AOS customer communication overview page.
   *
   * Return all tasks data of customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKamigoTasksByCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKamigoTasksByCustomers(params: GetKamigoTasksByCustomers$Params, context?: HttpContext): Observable<void> {
    return this.getKamigoTasksByCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getBatchTasks()` */
  static readonly GetBatchTasksPath = '/task/batch';

  /**
   * Get tasks of batch to show in AOS customer communication overview page.
   *
   * Return tasks of a specific batch and customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBatchTasks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBatchTasks$Response(params: GetBatchTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getBatchTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tasks of batch to show in AOS customer communication overview page.
   *
   * Return tasks of a specific batch and customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBatchTasks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBatchTasks(params: GetBatchTasks$Params, context?: HttpContext): Observable<void> {
    return this.getBatchTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createTasks()` */
  static readonly CreateTasksPath = '/tasks/bulk';

  /**
   * Create new tasks.
   *
   * Create new tasks in bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTasks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTasks$Response(params: CreateTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BulkCreateTaskResponseDto>>> {
    return createTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new tasks.
   *
   * Create new tasks in bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTasks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTasks(params: CreateTasks$Params, context?: HttpContext): Observable<Array<BulkCreateTaskResponseDto>> {
    return this.createTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulkCreateTaskResponseDto>>): Array<BulkCreateTaskResponseDto> => r.body)
    );
  }

  /** Path part for operation `deleteTasks()` */
  static readonly DeleteTasksPath = '/tasks/bulk';

  /**
   * Delete general tasks and notices.
   *
   * Delete general tasks and notices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTasks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteTasks$Response(params: DeleteTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete general tasks and notices.
   *
   * Delete general tasks and notices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTasks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteTasks(params: DeleteTasks$Params, context?: HttpContext): Observable<void> {
    return this.deleteTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateTasksStatus()` */
  static readonly UpdateTasksStatusPath = '/tasks/bulk';

  /**
   * Update tasks status.
   *
   * Update tasks status in bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTasksStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTasksStatus$Response(params: UpdateTasksStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTasksStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update tasks status.
   *
   * Update tasks status in bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTasksStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTasksStatus(params: UpdateTasksStatus$Params, context?: HttpContext): Observable<void> {
    return this.updateTasksStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
