<div
    #parentContainer
    tabindex="0"
    class="bg-neutral-000 py-3 px-4 rounded-2xl max-w-[600px] min-w-[250px] shadow-depth-2-regular hover:shadow-depth-2-hover hover:cursor-pointer focus:border-secondary-300 focus:border-[1.5px] focus:border-solid focus:shadow-input-glow outline-none"
    [ngClass]="{
        'border-secondary-300': forceFocus,
        'border-[1.5px]': forceFocus,
        'border-solid': forceFocus,
        'shadow-input-glow': forceFocus
    }"
    (click)="onClick.emit($event)"
>
    <div class="flex gap-3">
        <ui-icon-badge
            class="shrink-0"
            [icon]="leftIcon"
            [mode]="active ? 'dark' : 'light'"
        />
        <div class="flex-1 min-w-0">
            <div class="flex flex-col gap-[2px]">
                <div
                    class="t-h4 text-primary-600"
                    [ngClass]="{
                        'line-clamp-1': displayMode === 'full',
                        'line-clamp-2': displayMode === 'medium',
                    }"
                >
                    {{ title }}
                </div>
                <div class="t-body text-neutral-400 flex gap-2">
                    <div
                        *ngIf="descriptionIcon"
                        class="bg-neutral-200 w-5 h-5 rounded relative"
                    >
                        <ui-icon
                            [name]="descriptionIcon"
                            class="w-3 h-3 text-primary-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                            strokeWidth="3"
                        />
                    </div>
                    <div
                        *ngIf="leftLabel && displayMode === 'full'"
                        class="shrink-0"
                    >
                        <ng-container
                            [ngTemplateOutlet]="label"
                            [ngTemplateOutletContext]="{
                                labelDesc: leftLabel
                            }"
                        />
                    </div>
                    <div class="flex-1 min-w-0 max-w-fit truncate">
                        <span *ngIf="descriptionTitle" class="t-body-bold">
                            {{ descriptionTitle }}
                        </span>
                        <span *ngIf="descriptionTitle">
                            {{ SEPARATOR }}
                        </span>
                        <span
                            *ngIf="displayMode === 'full' && descriptionExtra"
                        >
                            {{ descriptionExtra }} {{ SEPARATOR }}
                        </span>
                    </div>
                    <span class="shrink-0"> {{ descriptionDate }}</span>
                </div>
            </div>
        </div>
        <div
            *ngIf="displayMode === 'full' && rightLabelInput"
            class="shrink-0 max-w-fit my-auto"
        >
            <ng-container
                [ngTemplateOutlet]="label"
                [ngTemplateOutletContext]="{ labelDesc: rightLabel }"
            />
        </div>
    </div>
    <div
        *ngIf="displayMode === 'medium'"
        class="flex flex-wrap gap-2 ml-12 pt-[2px] my-auto max-w-full max-h-fit h-full"
    >
        <ng-container
            *ngIf="leftLabel"
            [ngTemplateOutlet]="label"
            [ngTemplateOutletContext]="{ labelDesc: leftLabel }"
        />
        <div *ngIf="rightLabelInput" class="w-fit">
            <ng-container
                [ngTemplateOutlet]="label"
                [ngTemplateOutletContext]="{ labelDesc: rightLabel }"
            />
        </div>
    </div>
</div>

<ng-template #label let-labelDesc="labelDesc">
    <ui-label
        [color]="labelDesc.color"
        [active]="active"
        [label]="labelDesc.label"
        [icon]="labelDesc.icon"
        [size]="labelDesc.size"
        [bold]="false"
    />
</ng-template>
