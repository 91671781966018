import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgControl
} from '@angular/forms';
import { IconComponent } from '../icon/icon.component';
import {
    DropdownComponent,
    DropdownItem
} from '../dropdown/dropdown.component';

/**
 * An input component compatible with FormsModule. There are two ways of using this:<br />
 * -> FormsModule - this is the intended way, this way we have validation applied and the error message can show <br />
 * -> Two way binding with value - Using this and the hasError boolean you can manage all features of this input component <br />
 */
@Component({
    selector: 'ui-input',
    standalone: true,
    imports: [CommonModule, FormsModule, IconComponent, DropdownComponent],
    templateUrl: './input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputComponent
        }
    ]
})
export class InputComponent implements ControlValueAccessor, AfterContentInit {
    constructor(private injector: Injector) {}

    @Input() placeHolder: string = '';
    @Input() initialValue: string = '';
    @Input() hasError: boolean = false;
    @Input() errorMessage: string = '';
    @Input() disabled: boolean = false;
    @Input() value: string = '';
    @Input() multiline: boolean = false;
    @Input() selectOptions?: DropdownItem<string>[]; // If present, we render a dropdown
    // for password managers etc, set type to "password"
    @Input() type: string = 'text';

    selectedItem: DropdownItem<string>;

    @Output() valueChange = new EventEmitter<string>();

    // Used only for styling the sedlect trigger
    isSelectOpen: boolean = false;

    menuWidth: number;

    get invalid() {
        if (this.control) {
            return (
                !this.control.valid &&
                (this.control.touched || this.control.dirty) &&
                this.control?.touched
            );
        }
        return this.hasError;
    }

    onInputChange(value: string) {
        this.onTouched();
        this.onChange(value);
        this.valueChange.emit(value);
    }

    // Control Value Accesor Section
    onChange: (val: string) => void = (val) => {};
    onTouched: () => void = () => {};
    control: FormControl = null;

    ngAfterContentInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.control = ngControl.control as FormControl;
        }

        if (this.initialValue) {
            this.writeValue(this.initialValue);
            this.onChange(this.initialValue);
        }
    }

    private isTypeWarningShowed = false;
    writeValue(inputValue: any): void {
        if (inputValue === null || inputValue === undefined) {
            this.value = '';
        } else if (typeof inputValue === 'string') {
            this.value = inputValue;
            if (this.selectOptions) {
                const match = this.selectOptions.find(
                    (opt) => opt.item === inputValue
                );
                this.selectedItem = match;
            }
        } else if (!this.isTypeWarningShowed) {
            console.warn('Input component must be a string!');
            this.isTypeWarningShowed = true;
        }
    }

    public get inputClasses() {
        return {
            'text-neutral-300 bg-neutral-100': this.disabled,
            'text-primary-600 bg-neutral-000': !this.disabled,
            'border-red-400': this.invalid && !this.disabled,
            'hover:border-secondary-300 focus:border-secondary-300 focus:shadow-input-glow':
                !this.invalid && !this.disabled,

            'border-secondary-300 shadow-input-glow':
                this.isSelectOpen && !this.disabled
        };
    }

    registerOnChange(onChangeCallback: any): void {
        this.onChange = onChangeCallback;
    }

    registerOnTouched(onTouchedCallback: any): void {
        this.onTouched = onTouchedCallback;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // hack to let the mat-meu take the same size as the trigger button
    @ViewChild('container', { static: false, read: ElementRef })
    menuTriggerElement: ElementRef<HTMLElement>;

    get menuData() {
        return {
            menuWidth: this.menuTriggerElement?.nativeElement?.clientWidth || 0
        };
    }

    onDropdownItemSelected(selectedValue: string) {
        this.value = selectedValue;
        this.onChange(selectedValue);
        this.valueChange.emit(selectedValue);
    }
}
