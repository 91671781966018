import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from '../../@fuse/directives/skeleton-loaders/skeleton.module';
import { NgxDatePipe } from '../../@fuse/pipes/localized-date.pipe';
import { VerificationModalComponent } from './verification-modal/verification-modal.component';
import { LogoAndMessageComponent } from './logo-and-message/logo-and-message.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CountdownModule } from 'ngx-countdown';
import { FuseAlertModule } from '../../@fuse/components/alert';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'right',
            distance: 12
        },
        vertical: {
            position: 'bottom',
            distance: 12,
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }
};
@NgModule({
    declarations: [
        NgxDatePipe,
        LogoAndMessageComponent,
        VerificationModalComponent,
        SignUpFormComponent,
        SignInFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NotifierModule.withConfig(customNotifierOptions),
        MatFormFieldModule,
        MatInputModule,
        FuseAlertModule,
        MatCheckboxModule,
        MatButtonModule,
        TranslateModule,
        RouterModule,
        MatIconModule,
        CountdownModule,
        SkeletonModule,
        MatProgressSpinnerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NotifierModule,
        TranslateModule,
        SkeletonModule,
        NgxDatePipe,
        LogoAndMessageComponent,
        VerificationModalComponent,
        SignUpFormComponent,
        SignInFormComponent
    ]
})
export class SharedModule {}
