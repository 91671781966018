<div
    class="rounded-2xl py-3 px-4 shadow-depth-2-regular bg-neutral-000 grid gap-3 w-full grid-cols-1 sm:grid-cols-2 sm:grid-rows-[auto_auto]"
>
    <div
        class="order-1 sm:order-none sm:col-start-1 sm:row-start-1 flex items-center"
    >
        <ng-container *ngIf="firmCardDetails.logo; else fallbackLogo">
            <img
                [src]="firmCardDetails.logo"
                class="h-6 w-auto object-contain"
                alt="logo"
                (error)="handleImageError($event)"
            />
        </ng-container>
        <ng-template #fallbackLogo>
            <h3 class="t-h1">
                {{ firmCardDetails?.fallbackTitle }}
            </h3>
        </ng-template>
    </div>

    <div
        class="order-2 sm:order-none sm:col-span-2 sm:row-start-2 t-body text-primary-600"
    >
        {{ firmCardDetails.description }}
        <span class="t-body-bold"> {{ firmCardDetails.fallbackTitle }}</span
        >.
    </div>

    <ng-container *ngIf="buttonDescriptor">
        <div
            class="order-3 sm:order-none sm:col-start-2 sm:row-start-1 w-full sm:w-auto sm:justify-self-end"
        >
            <ui-button
                [icon]="buttonDescriptor.icon"
                [rightIcon]="buttonDescriptor.rightIcon"
                [label]="buttonDescriptor.label"
                [disabled]="buttonDescriptor.disabled"
                [variant]="buttonDescriptor.variant"
                [fullWidth]="true"
                (clicked)="handleButtonClick()"
            ></ui-button>
        </div>
    </ng-container>
</div>
