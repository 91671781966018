import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'ui-toggle',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './toggle.component.html'
})
export class ToggleComponent {
    @Input() checked = false;

    @Output() changed = new EventEmitter<boolean>();

    // this toggle is stateless so purely managed by parent
    toggle(): void {
        this.changed.emit(this.checked);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault(); // prevent scrolling with Space
            this.toggle();
        }
    }
}
