/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export enum NavItemId {
    HOME = 'home',
    INBOX = 'inbox',
    ACCOUNTING = 'accounting',
    FINANCE = 'finance',
    PROFILE = 'profile'
}

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: NavItemId.HOME,
        title: 'HOME',
        type: 'basic',
        icon: 'heroicons_solid:home',
        link: '/home'
    },
    {
        id: NavItemId.INBOX,
        title: 'INBOX',
        type: 'basic',
        icon: 'heroicons_solid:inbox-in',
        link: '/inbox'
    },
    {
        id: NavItemId.ACCOUNTING,
        title: 'ACCOUNTING',
        type: 'basic',
        icon: 'heroicons_solid:library',
        link: '/accounting'
    },
    {
        id: NavItemId.FINANCE,
        title: 'FINANCE',
        type: 'basic',
        icon: 'heroicons_solid:currency-euro',
        link: '/finance'
    },
    {
        id: NavItemId.PROFILE,
        title: 'PROFILE',
        type: 'basic',
        icon: 'heroicons_solid:user',
        link: '/profile'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
