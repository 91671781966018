<div
    class="flex flex-col gap-3 border-4 border-white rounded-3xl p-4 shadow-depth-1 bg-white-radial-gradient"
>
    <div class="flex justify-between">
        <ui-header mode="light" [icon]="headerIcon" [label]="headerTitle" />
        <ui-button
            *ngIf="button"
            class="my-auto max-h-fit"
            [rightIcon]="button?.rightIcon"
            [icon]="button?.icon"
            [label]="button?.label"
            [disabled]="button?.disabled"
            [variant]="button?.variant"
            (clicked)="onButtonClick.emit()"
        />
    </div>
    <div *ngIf="!isEmptyState; else emptyState">
        <div
            #groupingContainer
            class="min-w-0 flex flex-wrap gap-3 [&>*]:min-w-[calc(50%-6px)] [&>*]:max-w-[calc(100%)]"
        >
            <ng-content></ng-content>
        </div>
    </div>
    <ng-template #emptyState>
        <div
            class="bg-white shadow-depth-2-regular rounded-xl p-[10px] h-20 flex"
        >
            <div class="t-body-bold text-neutral-400 m-auto">
                {{ emptyText }}
            </div>
        </div>
    </ng-template>
</div>
