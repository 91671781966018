<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img
                class="w-10"
                src="assets/images/logo/logo.svg"
                alt="Logo image"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full min-h-13 px-4 md:px-6 z-49 dark:border-b bg-card dark:bg-transparent print:hidden border-b"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <!-- Components -->
        <div *ngIf="isCompanyScoped">
            <button
                mat-flat-button
                [matMenuTriggerFor]="menu"
                color="primary"
                class="squared-button ml-3"
                (menuClosed)="onDropdownClicked()"
                (menuOpened)="onDropdownClicked()"
            >
                {{ activeCompany.name }}
                <mat-icon
                    iconPositionEnd
                    [svgIcon]="dropdownIcon"
                    style="
                        margin-right: -4px !important;
                        margin-left: 8px !important;
                    "
                />
            </button>
            <mat-menu #menu="matMenu">
                <div *ngFor="let company of user?.companies || []">
                    <button
                        mat-menu-item
                        (click)="setActiveCompany(company._id)"
                        class="py-[6px]"
                        style="min-height: auto !important"
                    >
                        {{ company.name }}
                    </button>
                </div>
            </mat-menu>
        </div>

        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!--    &lt;!&ndash; Footer &ndash;&gt;-->
    <!--    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
    <!--        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>-->
    <!--    </div>-->
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
