import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { CompanyInviteDataDto } from '../../../api/models';
import { CountdownEvent } from 'ngx-countdown';
import { fuseAnimations } from '../../../@fuse/animations';

@Component({
    selector: 'app-sign-up-form',
    templateUrl: './sign-up-form.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SignUpFormComponent {
    @Input() signUpForm: UntypedFormGroup;
    @Input() isLoading: boolean;
    @Input() alert: { type: FuseAlertType; message: string };
    @Input() showAlert: boolean;
    @Input() userDetails: CompanyInviteDataDto;
    @Input() openVerificationModal: boolean;
    @Input() emailVerificationCode: number;
    @Input() reSendOTP: boolean;
    @Input() isEmailReadOnly = false;
    @Input() showAlreadyHaveAccount = true;
    @Input() inviteMessage: string;

    @Output() signUp = new EventEmitter<void>();
    @Output() confirmCode = new EventEmitter<void>();
    @Output() sendOTP = new EventEmitter<void>();
    @Output() checkCountdown = new EventEmitter<CountdownEvent>();
    @Output() closeVerificationModal = new EventEmitter<void>();

    public onSignUp(): void {
        this.signUp.emit();
    }

    public onConfirmCode(): void {
        this.confirmCode.emit();
    }

    public onSendOTP(): void {
        this.sendOTP.emit();
    }

    public onCheckCountdown(event: CountdownEvent): void {
        this.checkCountdown.emit(event);
    }

    public onCloseVerificationModal(): void {
        this.closeVerificationModal.emit();
    }
}
