import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

export interface ContactCardDetails {
    logo: string;
    logoFallback?: string;
    personImage?: string;
    personName?: string;
    phoneNumber?: string;
    email: string;
}

export type ContactCardSize = 'small' | 'medium';

/**
 * ContactCardComponent has two colors as input accentColor1 and accentColor2:
 * - accentColor1 is used for the background of the card
 * - accentColor2 is used for the color of the icons present in the card
 */

@Component({
    selector: 'ui-contact-card',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './contact-card.component.html',
    styleUrl: './contact-card.component.scss'
})
export class ContactCardComponent {
    @Input() contactCardDetails: ContactCardDetails;
    @Input() size: ContactCardSize = 'medium';
    @Input() accentColor1: string = 'rgb(30, 34, 41)';
    @Input() accentColor2: string = 'rgb(30, 34, 41)';

    isPersonImageError = false;
    isLogoImageError = false;
}
