import {
    Plus,
    ChevronDown,
    Music,
    ChevronUp,
    Building,
    FileSignature,
    House,
    Landmark,
    LogOut,
    Mail,
    PanelLeftClose,
    PanelLeftOpen,
    Settings,
    Bell,
    CheckCheck,
    ClipboardPen,
    Euro,
    Square,
    SquareCheck,
    User,
    ChevronRight,
    LayoutGrid,
    FileText,
    Upload,
    X,
    Check,
    Phone,
    Lock,
    Pencil,
    ArrowLeft,
    ArrowRight,
    Trash2,
    SendHorizontal,
    MessageSquareText,
    ChevronLeft,
    Download,
    CircleX,
    Info,
    Eye,
    Minus,
    TriangleAlert
} from 'lucide-angular';
import {
    BuildingFill,
    FileTextFill,
    Flowtribe,
    HouseFill,
    LandmarkFill,
    MailFill,
    SquareCheckFill
} from './custom-icons';

export const FillIcons: IconName[] = [
    'BuildingFill',
    'MailFill',
    'LandmarkFill',
    'HouseFill',
    'SquareCheckFill',
    'UserFill',
    'Flowtribe',
    'FileTextFill'
];

export const ICON_REGISTRY = {
    Plus,
    ChevronDown,
    Music,
    ChevronUp,
    Building,
    FileSignature,
    PanelLeftClose,
    PanelLeftOpen,
    House,
    Mail,
    Landmark,
    Settings,
    LogOut,
    MailFill,
    LandmarkFill,
    HouseFill,
    CheckCheck,
    Euro,
    ClipboardPen,
    Bell,
    SquareCheck,
    Square,
    BuildingFill,
    SquareCheckFill,
    User,
    UserFill: User,
    ChevronRight,
    Flowtribe,
    LayoutGrid,
    FileText,
    FileTextFill,
    Upload,
    X,
    Check,
    Phone,
    Download,
    ArrowRight,
    Lock,
    Pencil,
    ArrowLeft,
    Trash2,
    SendHorizontal,
    MessageSquareText,
    ChevronLeft,
    Eye,
    Minus,
    CircleX,
    Info,
    TriangleAlert
} as const;

export type IconName = keyof typeof ICON_REGISTRY;
