export type TailwindBreakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export function extractTailwindBreakpoint(breakpoint: TailwindBreakpoint) {
    const stylesheets = Array.from(document.styleSheets);
    for (const sheet of stylesheets) {
        try {
            const rules = Array.from(sheet.cssRules || []);
            for (const rule of rules) {
                if (!(rule instanceof CSSMediaRule)) {
                    continue;
                }

                const mediaText = rule.conditionText || rule.media.mediaText;
                if (
                    rule.cssRules[0]?.cssText?.includes(`.${breakpoint}\\:`) &&
                    mediaText.includes('min-width')
                ) {
                    return mediaText;
                }
            }
        } catch (e) {
            // CORS errors can happen when accessing cross-origin stylesheets
        }
    }

    // If we couldn't find any breakpoints, fall back to defaults
    switch (breakpoint) {
        case 'sm':
            return '(min-width: 640px)';
        case 'md':
            return '(min-width: 768px)';
        case 'lg':
            return '(min-width: 1024px)';
        case 'xl':
            return '(min-width: 1280px)';
        case '2xl':
            return '(min-width: 1536px)';
    }
}

export function getMediaQuery(pixels: number) {
    return `(min-width: ${pixels}px)`;
}
