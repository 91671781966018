import {
    NgModule,
    isDevMode,
    APP_INITIALIZER,
    ErrorHandler
} from '@angular/core';
import {
    TranslateService,
    TranslateLoader,
    TranslateModule
} from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ExtraOptions,
    PreloadAllModules,
    Router,
    RouterModule
} from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotifierModule } from 'gramli-angular-notifier';
import { environment } from '../environments/environment';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { isLanguageAvailable } from '@fuse/utils/language-helper';
import * as Sentry from '@sentry/angular';
import { ApiModule } from '../api/api.module';
import { ProgressInterceptor } from './shared/common/interceptors/progress.interceptor';
import { MarkdownModule } from 'ngx-markdown';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
};
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        environment.translationFileUrl,
        '.json'
    );
}
export function appInitializerFactory(translate: TranslateService) {
    return () => {
        translate.setDefaultLang(isLanguageAvailable(navigator.language));
        return translate
            .use(isLanguageAvailable(navigator.language))
            .toPromise();
    };
}
@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    exports: [TranslateModule],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 10 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NotifierModule,
        ApiModule.forRoot({ rootUrl: environment.apiUrl }),
        MarkdownModule.forRoot()
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProgressInterceptor,
            multi: true
        },
        ...(environment.sentry.enabled
            ? [
                  {
                      provide: ErrorHandler,
                      useValue: Sentry.createErrorHandler({
                          showDialog: false
                      })
                  },
                  {
                      provide: Sentry.TraceService,
                      deps: [Router]
                  },
                  {
                      provide: APP_INITIALIZER,
                      useFactory: () => () => {
                          // Intentionally empty function
                      },
                      deps: [Sentry.TraceService],
                      multi: true
                  }
              ]
            : []),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}
