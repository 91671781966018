/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AccountingIntegrationAppTokens {
  Silverfin = 'silverfin',
  Clearfacts = 'clearfacts',
  Exactonline = 'exactonline',
  Yuki = 'yuki'
}
