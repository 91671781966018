<div
    class="relative hidden md:flex flex-col flex-auto items-center justify-center h-full overflow-hidden bg-primary-800 py-8 px-4 sm:p-12 md:p-16"
>
    <div class="watermark absolute top-0 left-0">
        <img
            src="assets/images/watermark.svg"
            class="text-primary-50"
            alt="Kamigo watermark"
        />
    </div>
    <!--        Kamigo LOGO -->
    <div class="logo d-flex item-center justify-center pb-8 z-10">
        <img
            src="assets/images/logo/logo-text-on-dark.svg"
            alt="kamigo-white-logo"
        />
    </div>
    <!-- Content -->
    <div class="z-10 relative w-full max-w-2xl">
        <div class="mt-6 text-lg tracking-tight leading-6 text-gray-50">
            {{ inviteMessage }}
        </div>
    </div>
    <div class="watermark absolute bottom-0 right-0">
        <img
            src="assets/images/watermark.svg"
            class="waterMark2"
            alt="Kamigo watermark"
        />
    </div>
</div>
