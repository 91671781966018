import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io } from 'socket.io-client';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ItemEventType } from './web-socket.types';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    public onTaskEvent: Subject<ItemEventType> = new Subject<ItemEventType>();
    private socket: any;

    constructor(private authService: AuthService) {
        this.initSocket();
    }

    public initSocket() {
        this.socket = io(environment.apiUrl, {
            auth: { Authorization: this.authService.accessToken },
            transports: ['websocket', 'polling'],
            withCredentials: true
        });

        this.socket.on('taskEvent', (res: ItemEventType) => {
            this.onTaskEvent.next(res);
        });
    }

    public disconnectSocket(): void {
        this.socket.disconnect();
    }
}
