import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { io } from 'socket.io-client';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ItemEventType, OutstandingCount } from './web-socket.types';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    public taskNotification: Subject<ItemEventType> =
        new Subject<ItemEventType>();
    public totalOutstandingCount: BehaviorSubject<OutstandingCount> =
        new BehaviorSubject<OutstandingCount>(null);
    private socket: any;

    constructor(private authService: AuthService) {
        if (!this.socket?.connected && this.authService.accessToken) {
            this.initSocket();
        }
    }

    public initSocket() {
        this.socket = io(environment.apiUrl, {
            auth: {
                Authorization: this.authService.accessToken
            },
            transports: ['websocket', 'polling'],
            withCredentials: true
        });

        this.socket.on('taskEvent', (res: ItemEventType) => {
            const countsPerEntity: Map<string, OutstandingCount> = new Map();
            const entityKey = `${res.entityType}:${res.entityId}`;
            const entityCount: OutstandingCount = {
                outstandingNotice: res.totalOutstandingNotice || 0,
                outstandingItem: res.totalOutstandingItem || 0,
                outstandingTask: res.totalOutstandingTask || 0
            };
            countsPerEntity.set(entityKey, entityCount);

            // sum total counts
            const totalCount: OutstandingCount = {
                outstandingNotice: 0,
                outstandingItem: 0,
                outstandingTask: 0
            };

            for (const count of countsPerEntity.values()) {
                totalCount.outstandingNotice += count.outstandingNotice;
                totalCount.outstandingItem += count.outstandingItem;
                totalCount.outstandingTask += count.outstandingTask;
            }

            this.totalOutstandingCount.next(totalCount);

            this.taskNotification.next(res);
        });
    }

    public disconnectSocket() {
        this.socket.disconnect();
    }
}
