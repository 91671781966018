import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconName } from '../../assets/icons/icons';
import { IconComponent } from '../icon/icon.component';

export type ButtonVariant =
    | 'primary'
    | 'secondary'
    | 'destructive'
    | 'tertiary'
    | 'light-green'
    | 'light-red';
export type ButtonDescriptor = {
    icon?: IconName;
    rightIcon?: IconName;
    label?: string;
    disabled?: boolean;
    variant?: ButtonVariant;
    fullWidth?: boolean;
};

/**
 * ButtonComponent displays various optional configurations:
 * - Icon only
 * - Left icon + label
 * - Left icon + label + right icon
 * - Label only
 */
@Component({
    selector: 'ui-button',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    _disabled = false;
    @Input()
    set disabled(disabled: boolean) {
        this._disabled = disabled !== undefined ? disabled : false;
    }
    get disabled(): boolean {
        return this._disabled;
    }

    _variant: ButtonVariant = 'primary';
    @Input()
    set variant(variant: ButtonVariant) {
        this._variant = variant ? variant : 'primary';
    }
    get variant(): ButtonVariant {
        return this._variant;
    }

    @Input() icon?: IconName;
    @Input() rightIcon?: IconName;
    @Input() label?: string;
    @Input() fullWidth?: boolean = false;

    @Output() clicked = new EventEmitter<Event>();

    handleClick(event: Event): void {
        if (!this.disabled) {
            this.clicked.emit(event);
        }
    }

    get buttonClasses(): { [key: string]: boolean } {
        return {
            'bg-primary-600': this.variant === 'primary' && !this.disabled,
            'bg-white': this.variant === 'tertiary' && !this.disabled,
            'bg-neutral-100': this.variant === 'secondary' && !this.disabled,
            'bg-red-600': this.variant === 'destructive' && !this.disabled,
            'bg-green-100': this.variant === 'light-green' && !this.disabled,
            'bg-red-100': this.variant === 'light-red' && !this.disabled,

            'bg-primary-600 hover:shadow-[inset_0_0_0_50px_rgba(0,0,0,0.2)]':
                this.variant === 'primary' && !this.disabled,
            'bg-neutral-100 hover:shadow-[inset_0_0_0_50px_rgba(0,0,0,0.03)]':
                this.variant === 'secondary' && !this.disabled,
            'bg-red-600 hover:shadow-[inset_0_0_0_50px_rgba(0,0,0,0.2)]':
                this.variant === 'destructive' && !this.disabled,

            'focus-primary': this.variant === 'primary' && !this.disabled,
            'focus-secondary': this.variant === 'secondary' && !this.disabled,
            'focus-destructive':
                this.variant === 'destructive' && !this.disabled,

            'btn-border-gradient': !this.disabled,
            'bg-neutral-200': this.disabled,
            'sm:w-8 w-11': !this.label,
            'justify-center': !this.label || this.fullWidth,
            'justify-between': !!this.label && !this.fullWidth,

            'w-full': this.fullWidth
        };
    }

    get textClasses(): { [key: string]: boolean } {
        const shouldBeDarkText = ['secondary', 'tertiary'].includes(
            this.variant
        );
        const shouldBeWhiteText = ['destructive', 'primary'].includes(
            this.variant
        );
        return {
            'text-neutral-300': this.disabled,
            'text-primary-600': shouldBeDarkText && !this.disabled,
            'text-white': shouldBeWhiteText && !this.disabled,
            'text-green-600': this.variant === 'light-green',
            'text-red-600': this.variant === 'light-red'
        };
    }
}
