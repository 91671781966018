import { CommonModule } from '@angular/common';
import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ButtonComponent, ButtonVariant } from '../button/button.component';
import { IconName } from '../../assets/icons/icons';
import { IconComponent } from '../icon/icon.component';
import { BadgeComponent } from '../badge/badge.component';

export interface DropdownItem<T> {
    // the name that will appear in the dropdown
    name: string;
    // the item passed in the callbacks
    item: T;
    // the icon to display
    icon?: IconName;
    // the count to display in a badge if given
    count?: number;
}

export type AllDropdownItem = DropdownItem<undefined> & { isSelected: boolean };
export const REPLACE_SELECTED_ITEMS = '$$SELECTED$$';

/**
 *
 */
@Component({
    selector: 'ui-dropdown',
    standalone: true,
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        ButtonComponent,
        IconComponent,
        BadgeComponent
    ],
    providers: [provideAnimations()],
    templateUrl: './dropdown.component.html'
})
export class DropdownComponent<T> implements OnChanges, OnInit {
    // templates
    @ContentChild('customTrigger') customTrigger: TemplateRef<any>;
    @ContentChild('customItem') customItem: TemplateRef<any>;

    // general config
    @Input() buttonVariant: ButtonVariant = 'primary';
    @Input() buttonFullWidth: boolean = false;
    @Input() buttonCustomStyles: string = undefined;
    @Input() multiple: boolean = false;
    // single config
    @Input() nothingSelectedText: string = 'Select';
    // multiple config
    @Input()
    multipleButtonText: string = `${REPLACE_SELECTED_ITEMS} items`;
    @Input() allSelector: boolean = true;
    @Input() allSelectorText: string = 'All';

    // general two way bind
    @Input() items: DropdownItem<T>[] = [];
    @Output() itemsChange = new EventEmitter<DropdownItem<T>>();
    // single two way bind
    @Input() singleSelectedItem: DropdownItem<T> = undefined;
    @Output() singleSelectedItemChange = new EventEmitter<DropdownItem<T>>();
    // multiple two way bind
    @Input() multipleSelectedItems: DropdownItem<T>[] = [];
    @Output() multipleSelectedItemsChange = new EventEmitter<
        DropdownItem<T>[]
    >();

    // Utils functions
    @Output() onMenuStateChange = new EventEmitter<'open' | 'close'>();
    @Output() onItemSelected = new EventEmitter<T>();

    closeMenu() {
        this.menuTrigger.closeMenu();
    }

    openMenu() {
        this.menuTrigger.openMenu();
    }

    // Implementation
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    menuRightIcon: 'ChevronDown' | 'ChevronUp' = 'ChevronDown';
    allDropdownItem: AllDropdownItem = {
        icon: 'CheckCheck',
        item: undefined,
        name: this.allSelectorText,
        isSelected: false
    };

    get buttonText(): string {
        if (!this.multiple) {
            return this.singleSelectedItem?.name || this.nothingSelectedText;
        }

        // show text of the only selected item
        if (this.multipleSelectedItems.length === 1) {
            return this.multipleSelectedItems[0].name;
        }

        // show the all selector text when all selected
        if (this.allDropdownItem.isSelected) {
            return this.allDropdownItem.name;
        }

        return this.multipleButtonText.replace(
            REPLACE_SELECTED_ITEMS,
            this.multipleSelectedItems.length.toString()
        );
    }

    get buttonIcon(): IconName {
        if (!this.multiple) {
            return this.singleSelectedItem?.icon || undefined;
        }

        if (this.allDropdownItem.isSelected) {
            return this.allDropdownItem.icon;
        }
        if (this.multipleSelectedItems.length === 1) {
            return this.multipleSelectedItems[0].icon;
        }
        return undefined;
    }

    get buttonStyleClasses(): string {
        return (
            this.buttonCustomStyles ||
            'pl-2 pr-2 gap-1 h-8 bg-neutral-000 hover:bg-[#94A3B81A] focus:bg-[#94A3B81A] border-none hover:cursor-pointer outline-none'
        );
    }

    ngOnInit(): void {
        if (this.multipleSelectedItems.length === this.items.length) {
            this.allDropdownItem.isSelected = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allSelectorText) {
            this.allDropdownItem.name = this.allSelectorText;
        }
    }

    onItemClick(event: Event, dropdownItem: DropdownItem<T>) {
        event.stopPropagation();
        this.onItemSelect(dropdownItem);
    }

    onItemSelect(dropdownItem: DropdownItem<T>) {
        if (this.multiple) {
            this.onItemSelectMultiple(dropdownItem);
            this.multipleSelectedItemsChange.emit(this.multipleSelectedItems);
        } else {
            this.singleSelectedItem = dropdownItem;
            this.closeMenu();
            this.singleSelectedItemChange.emit(this.singleSelectedItem);
        }

        // add to javascript queue so that the menu position updates
        // after angular makes the changes in the html
        // we may have changed the width of the button by changing text/icon...
        setTimeout(() => {
            this.menuTrigger.updatePosition();
        });

        this.onItemSelected.emit(dropdownItem.item);
    }

    onItemSelectMultiple(dropdownItem: DropdownItem<T>) {
        const wasSelected = this.isItemSelected(dropdownItem);
        if (dropdownItem === this.allDropdownItem) {
            // always empty the array safely(not breaking the reference)
            this.multipleSelectedItems.splice(
                0,
                this.multipleSelectedItems.length
            );

            if (!wasSelected) {
                this.items.forEach((item) => {
                    this.multipleSelectedItems.push(item);
                });
            }

            this.allDropdownItem.isSelected = !wasSelected;
            return;
        }

        if (wasSelected) {
            // splice safely (not breaking the reference)
            const index = this.multipleSelectedItems.indexOf(dropdownItem);
            if (index !== -1) {
                this.multipleSelectedItems.splice(index, 1);
            }
        } else {
            this.multipleSelectedItems.push(dropdownItem);
        }

        this.allDropdownItem.isSelected =
            this.multipleSelectedItems.length === this.items.length;
    }

    onMenuButtonClick(action: 'close' | 'open') {
        this.menuRightIcon =
            this.menuRightIcon === 'ChevronDown' ? 'ChevronUp' : 'ChevronDown';

        this.onMenuStateChange.emit(action);
    }

    isItemSelected(dropdownItem: DropdownItem<T>) {
        //return true;
        if (dropdownItem === this.allDropdownItem) {
            return this.allDropdownItem.isSelected;
        }

        return this.multiple
            ? this.multipleSelectedItems.includes(dropdownItem)
            : this.singleSelectedItem === dropdownItem;
    }
}
