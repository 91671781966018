/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { uploadMedia } from '../fn/storage/upload-media';
import { UploadMedia$Params } from '../fn/storage/upload-media';

@Injectable({ providedIn: 'root' })
export class StorageApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadMedia()` */
  static readonly UploadMediaPath = '/storage';

  /**
   * Upload media.
   *
   * Upload the media into meta cloud for whatsapp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadMedia()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadMedia$Response(params: UploadMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload media.
   *
   * Upload the media into meta cloud for whatsapp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadMedia$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadMedia(params: UploadMedia$Params, context?: HttpContext): Observable<void> {
    return this.uploadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
