import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { SharedModule } from 'app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SkeletonModule } from '../../@fuse/directives/skeleton-loaders/skeleton.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { FuseLoadingBarModule } from '../../@fuse/components/loading-bar';
import {
    provideHttpClient,
    withInterceptorsFromDi
} from '@angular/common/http';
@NgModule({
    declarations: [LayoutComponent],
    imports: [
        SharedModule,
        EmptyLayoutModule,
        MatIconModule,
        RouterLink,
        MatTabsModule,
        MatTooltipModule,
        SkeletonModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatButtonModule,
        MatMenuModule,
        FuseLoadingBarModule,
        TranslateModule,
        MatButtonToggleModule
    ],
    exports: [LayoutComponent, EmptyLayoutModule],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class LayoutModule {}
