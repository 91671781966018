<div
    class="bg-neutral-000 relative overflow-hidden pt-4 pb-3 px-3 shadow-depth-2-regular rounded-2xl max-w-[600px] min-w-[270px]"
    [ngClass]="{ 'pb-4 pr-4': size === 'small' }"
>
    <div
        class="background-ellipse absolute"
        [style.background]="accentColor1"
    ></div>
    <div class="gap-3 flex flex-col relative">
        <div class="px-2 h-[36px]" [ngClass]="{ hidden: size === 'small' }">
            <img
                *ngIf="!isLogoImageError"
                alt="firm logo"
                class="h-full w-auto"
                [src]="contactCardDetails.logo"
                (error)="isLogoImageError = true"
            />
            <span
                *ngIf="isLogoImageError"
                class="t-h1"
                [style.color]="accentColor1"
            >
                {{ contactCardDetails?.logoFallback }}</span
            >
        </div>
        <div
            class="rounded-xl flex px-3 py-2 bg-neutral-000 h-16"
            [ngClass]="{
                'shadow-depth-3 py-1 pl-1 pr-3': size === 'medium'
            }"
        >
            <img
                class="h-12 w-12 rounded-lg"
                alt="responsible person"
                *ngIf="!isPersonImageError && size === 'medium'"
                [src]="contactCardDetails.personImage"
                (error)="isPersonImageError = true"
            />
            <div class="flex justify-between w-full flex-wrap">
                <div
                    class="w-[150px]"
                    [ngClass]="{ hidden: size === 'medium' }"
                >
                    <img
                        [src]="contactCardDetails.logo"
                        alt="firm logo"
                        class="h-[36px] object-contain w-auto"
                    />
                </div>
                <div
                    *ngIf="size === 'medium'"
                    class="flex items-center pl-2 text-neutral-600 t-h3-bold"
                >
                    {{ contactCardDetails.personName }}
                </div>
                <div class="t-body flex flex-col justify-center pl-2">
                    <div
                        *ngIf="contactCardDetails.phoneNumber"
                        class="flex items-center"
                    >
                        <ui-icon
                            [color]="accentColor2"
                            name="phone"
                            [size]="12"
                            class="h-fit mr-1"
                        />
                        <div>{{ contactCardDetails.phoneNumber }}</div>
                    </div>
                    <div class="flex items-center">
                        <ui-icon
                            [color]="accentColor2"
                            name="mail"
                            [size]="12"
                            class="h-fit mr-1"
                        />
                        <div>{{ contactCardDetails.email }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
