<div
    class="rounded-xl px-3 h-9 flex items-center cursor-pointer"
    [ngClass]="{
        'w-full justify-center': fullWidth,
        'w-fit': !fullWidth,
        'bg-primary-500 t-body-bold hover:bg-primary-600': isSelected,
        'bg-white t-body hover:bg-[rgba(148,163,184,0.1)]': !isSelected
    }"
    (click)="onClick.emit(tab.key)"
>
    <ui-icon
        *ngIf="tab.icon"
        [name]="tab.icon"
        [ngClass]="isSelected ? 'text-white' : 'text-primary-400'"
        class="w-4 h-4"
    ></ui-icon>

    <!-- Label is conditionally hidden on mobile when not selected -->
    <span
        class="ml-2 truncate"
        [ngClass]="
            isSelected
                ? 'text-white t-body-bold'
                : fullWidth
                ? 'text-primary-600 t-body'
                : 'text-primary-600 t-body hidden sm:inline'
        "
    >
        {{ tab.label }}
    </span>

    <!-- Badge count (Optional and if count > 0) -->
    <ui-badge
        class="ml-2"
        *ngIf="tab.count > 0"
        [count]="tab.count"
        [color]="isSelected ? 'red' : 'black'"
    />

    <ui-icon
        *ngIf="tab.rightIcon"
        [name]="tab.rightIcon"
        class="w-4 h-4 [&>svg]:pl-1"
        [ngClass]="isSelected ? 'text-white' : 'text-primary-400'"
    ></ui-icon>
</div>
