<ng-container *ngIf="composition === 'modal'">
    <div
        class="fixed inset-0 z-50 h-screen p-4"
        [ngClass]="{
            'opacity-0 pointer-events-none': isCollapsed && !isTransitioning,
            'opacity-100 pointer-events-auto': !isCollapsed
        }"
    >
        <!-- Overlay -->
        <div
            class="absolute inset-0 bg-[#101729] opacity-50 back transition-opacity hover:cursor-pointer"
            (click)="toggleCollapse()"
        ></div>

        <!-- Content -->
        <div class="w-fit h-full">
            <ng-container [ngTemplateOutlet]="desktopNavigation"></ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="composition === 'desktop'">
    <ng-container [ngTemplateOutlet]="desktopNavigation"></ng-container>
</ng-container>
<ng-container *ngIf="composition === 'mobile'">
    <ng-container [ngTemplateOutlet]="mobileNavigation"></ng-container>
</ng-container>

<!-- desktop sidebar nav -->
<ng-template #desktopNavigation>
    <div class="hidden sm:flex items-start relative h-full">
        <nav
            [ngClass]="isCollapsed ? 'w-[0px] px-0' : 'w-[250px] mr-8'"
            class="bg-primary-600 rounded-xl text-white flex flex-col h-full p-3 transition-all ease-in-out duration-300 relative"
        >
            <!-- Header with logo, logo-text and collapse button icon -->
            <div class="flex items-center justify-between mb-4 h-9">
                <div
                    class="flex items-center overflow-hidden transition-width duration-300 ease-in-out"
                    [ngClass]="isCollapsed ? 'w-0' : 'auto'"
                >
                    <img
                        *ngIf="logoSvgPath"
                        class="ml-2.5"
                        [src]="logoSvgPath"
                        alt="Logo and text"
                    />
                </div>
                <button
                    *ngIf="!isCollapsed"
                    (click)="toggleCollapse()"
                    class="flex items-center cursor-pointer p-2 bg-primary-600 text-white border-none hover:bg-[rgba(255,255,255,0.15)] focus:outline-none rounded-lg transition-transform duration-300 ease-out z-50"
                >
                    <ui-icon
                        name="PanelLeftClose"
                        class="w-5 h-5 transition-transform duration-300 ease-linear"
                        [style.transform]="
                            isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'
                        "
                    ></ui-icon>
                </button>
            </div>

            <div class="flex flex-col flex-1 overflow-hidden">
                <!-- top Nav Items -->
                <div class="flex flex-col flex-1">
                    <ul class="list-none p-0 m-0">
                        <li *ngFor="let item of navItems">
                            <a
                                [attr.href]="item.route"
                                (click)="handleClick($event, item)"
                                [ngClass]="getNavItemClasses(item)"
                                class="flex gap-[8px] items-center cursor-pointer p-2 rounded-lg my-1 text-left no-underline"
                            >
                                <ui-header
                                    [icon]="getIcon(item)"
                                    [mode]="
                                        isActive(item) ? 'dark' : 'transparent'
                                    "
                                />
                                <span
                                    class="truncate"
                                    [ngClass]="{
                                        't-body-bold': isActive(item),
                                        't-body': !isActive(item)
                                    }"
                                >
                                    {{ item.label }}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- Bottom section (expandable sub-items & toggle) -->
                <div class="relative mt-auto">
                    <div
                        class="absolute bottom-full left-0 right-0 mb-1 z-10 overflow-hidden transition-all duration-300 ease-in-out"
                        [style.height]="!isBottomExpanded ? '0px' : 'auto'"
                        [style.opacity]="!isBottomExpanded ? '0' : '1'"
                    >
                        <ul
                            class="list-none p-0 mb-1 bg-primary-500 rounded-lg"
                        >
                            <li *ngFor="let subItem of bottomSubItems">
                                <a
                                    [attr.href]="subItem.route"
                                    (click)="
                                        handleSubItemClick($event, subItem)
                                    "
                                    [ngClass]="getBottomSubItemClasses(subItem)"
                                    class="flex items-center cursor-pointer p-2 rounded-lg text-left no-underline"
                                >
                                    <div
                                        class="rounded-lg flex items-center p-2 mr-2"
                                        [ngClass]="{
                                            'bg-primary-gradient-01':
                                                isActive(subItem)
                                        }"
                                    >
                                        <ui-icon
                                            [name]="subItem.icon"
                                            class="h-5 w-5 text-neutral-000"
                                            [gradientUrl]="
                                                'neutral-gradient-01'
                                            "
                                        ></ui-icon>
                                    </div>
                                    <span
                                        class="truncate"
                                        [ngClass]="{
                                            't-body-bold': isActive(subItem),
                                            't-body': !isActive(subItem)
                                        }"
                                    >
                                        {{ subItem.label }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <!-- bottom expansion button toggle -->
                    <button
                        (click)="toggleBottomExpansion()"
                        class="flex items-center w-full hover:bg-[rgba(255,255,255,0.15)] bg-primary-600 text-white cursor-pointer p-3 rounded-lg nav-item-border-gradient"
                    >
                        <ng-container
                            *ngIf="
                                bottomNavItem?.image && !isBottomImageError;
                                else iconTemplateBottom
                            "
                        >
                            <img
                                [src]="bottomNavItem.image"
                                class="h-6 w-6 rounded-full object-cover mr-2"
                                alt="{{ bottomNavItem.label }} icon"
                                (error)="onBottomImageError()"
                            />
                        </ng-container>
                        <ng-template #iconTemplateBottom>
                            <ui-icon
                                [name]="bottomNavItem.icon"
                                class="mr-2"
                            ></ui-icon>
                        </ng-template>

                        <span class="t-body truncate">{{
                            bottomNavItem.label
                        }}</span>
                        <ui-icon
                            name="ChevronDown"
                            class="ml-auto transition-transform duration-200 ease-linear"
                            [style.transform]="
                                isBottomExpanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)'
                            "
                            [gradientUrl]="'neutral-gradient-01'"
                        ></ui-icon>
                    </button>
                </div>
            </div>
        </nav>
    </div>
</ng-template>

<!-- mobile bottom nav -->
<ng-template #mobileNavigation>
    <div *ngIf="!hideMobileBottomNav" class="block sm:hidden relative">
        <nav
            class="fixed bottom-0 left-0 right-0 items-center bg-neutral-000 z-50 shadow-[0px_-4px_6px_0px_#00000014]"
        >
            <div class="flex justify-evenly my-1 items-center w-full">
                <div
                    *ngFor="let item of navItems"
                    (click)="onNavItemClick(item)"
                    class="flex flex-col flex-1 justify-center items-center py-2 rounded-full transition-colors duration-150"
                    [ngClass]="
                        !isBottomExpanded && isActive(item)
                            ? 'bg-primary-100'
                            : 'text-neutral-400'
                    "
                >
                    <ui-icon
                        [name]="getIcon(item)"
                        class="w-5 h-5"
                        [gradientUrl]="
                            isActive(item) ? 'primary-gradient-02' : null
                        "
                    ></ui-icon>
                </div>

                <!-- last button is expansion toggle-->
                <div
                    (click)="toggleBottomExpansion()"
                    class="flex flex-col flex-1 justify-center items-center py-2 rounded-full transition-colors duration-150"
                    [ngClass]="
                        isBottomExpanded
                            ? 'bg-primary-100 text-primary-gradient-02'
                            : 'text-neutral-400'
                    "
                >
                    <ui-icon
                        [name]="
                            isBottomExpanded && bottomNavItem.activeIcon
                                ? bottomNavItem.activeIcon
                                : bottomNavItem.icon
                        "
                        [fill]="isBottomExpanded"
                        [gradientUrl]="
                            isBottomExpanded ? 'primary-gradient-02' : null
                        "
                        class="w-5 h-5"
                    ></ui-icon>
                </div>
            </div>
        </nav>

        <!-- sub-menu for bottomSubItems -->
        <div
            *ngIf="isBottomExpanded"
            class="fixed inset-0 p-0 bg-neutral-000 flex flex-col items-start z-40 w-full bg-dark-wave"
        >
            <p
                class="w-full mt-10 p-8 font-dm font-semibold text-[24px] leading-[36px] text-center text-neutral-000"
            >
                {{ mobileSubMenuText }}
            </p>
            <ul class="list-none p-6 mt-0 w-full">
                <li *ngFor="let subItem of bottomSubItems">
                    <ng-container
                        *ngIf="!subItem.mobileButton; else mobileButtonTemplate"
                    >
                        <div
                            (click)="onBottomSubItemClick(subItem)"
                            class="flex items-center justify-between p-4 text-primary-600 border-b border-solid border-neutral-200"
                        >
                            <div class="flex items-center">
                                <div
                                    class="p-1.5 mr-3 border border-primary-100 rounded-md shadow-depth-3"
                                >
                                    <ui-icon
                                        [name]="subItem.icon"
                                        class="h-5 w-5 text-primary-500"
                                    ></ui-icon>
                                </div>
                                <span class="t-h3-bold text-primary-600">
                                    {{ subItem.label }}
                                </span>
                            </div>
                            <ui-icon
                                [name]="'ChevronRight'"
                                [strokeWidth]="1.5"
                                class="h-4 w-4 text-primary-500"
                            ></ui-icon>
                        </div>
                    </ng-container>
                    <!-- Mobile-only ui-button template -->
                    <ng-template #mobileButtonTemplate>
                        <div class="mt-6">
                            <ui-button
                                [label]="subItem.label"
                                [icon]="subItem.icon"
                                [fullWidth]="true"
                                (click)="onBottomSubItemClick(subItem)"
                            >
                            </ui-button>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
</ng-template>

<ng-template #header>
    <!-- collapse icon when sidebar isCollapsed -->
    <div class="hidden sm:flex flex-row">
        <button
            *ngIf="isCollapsed"
            (click)="toggleCollapse()"
            class="flex items-center cursor-pointer p-2 focus:outline-none rounded-lg bg-neutral-000 text-primary-400 shadow-depth-3 mr-2 border border-solid border-primary-100 hover:bg-[rgba(0,0,0,0.15)]"
        >
            <ui-icon
                name="PanelLeftClose"
                class="w-5 h-5 transition-transform duration-300 ease-linear"
                [style.transform]="
                    isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'
                "
            ></ui-icon>
        </button>
        <!-- summary card displaying active icon and label -->
        <div
            *ngIf="!activeItemHeaderHidden"
            class="flex items-center rounded-lg"
        >
            <ui-header
                *ngIf="activeItem"
                [icon]="getIcon(activeItem)"
                [label]="activeItem.label"
            />
        </div>
    </div>
</ng-template>
