/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PaginatedTasksDto } from '../../models/paginated-tasks-dto';

export interface GetPaginatedTask$Params {
  status: string;
  type?: 'notice' | 'task-request';
  companyId?: string;
  limit: number;
  page: number;
}

export function getPaginatedTask(http: HttpClient, rootUrl: string, params: GetPaginatedTask$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedTasksDto>> {
  const rb = new RequestBuilder(rootUrl, getPaginatedTask.PATH, 'get');
  if (params) {
    rb.path('status', params.status, {});
    rb.query('type', params.type, {});
    rb.query('companyId', params.companyId, {});
    rb.query('limit', params.limit, {});
    rb.query('page', params.page, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PaginatedTasksDto>;
    })
  );
}

getPaginatedTask.PATH = '/task/{status}';
