import { CommonModule } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';
import { BadgeComponent } from '../../badge/badge.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToggleTab } from '../toggle-tabs.component';

@Component({
    selector: 'ui-tab-item',
    standalone: true,
    imports: [CommonModule, IconComponent, BadgeComponent],
    templateUrl: './tab-item.component.html'
})
export class TabItemComponent {
    @Input() tab: ToggleTab;
    @Input() isSelected: boolean = false;
    @Input() fullWidth: boolean = false;
    @Output() onClick = new EventEmitter<string>();
}
