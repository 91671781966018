import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { debounceTime, Subject } from 'rxjs';

export interface AppCardDetails {
    name: string;
    description: string;
    link: string;
    app?: string;
}

@Component({
    selector: 'ui-app-card',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './app-card.component.html'
})
export class AppCardComponent {
    @Input() appCardDetails: AppCardDetails;

    @Output() clicked = new EventEmitter<AppCardDetails>();

    private clickSubject = new Subject<void>();
    isActive: boolean = false;

    constructor() {
        this.clickSubject.pipe(debounceTime(300)).subscribe(() => {
            this.isActive = false;
        });
    }

    applyClass() {
        this.isActive = true;
        this.clickSubject.next();
    }

    handleClick(cardDetails: AppCardDetails): void {
        this.clicked.emit(cardDetails);
    }
}
