<div class="bg-card">
    <div class="flex flex-col w-full mx-auto px-6 sm:px-8">
        <div
            class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-3"
        >
            <div class="flex flex-auto items-center min-w-0">
                <div
                    [skeleton]="isLoading"
                    class="flex w-16 h-16 rounded-full overflow-hidden items-center justify-center bg-slate-100 border"
                >
                    <img
                        class="w-16 h-16 rounded-full overflow-hidden"
                        *ngIf="user.profilePic"
                        [src]="user.profilePic"
                    />
                    <mat-icon
                        *ngIf="!user.profilePic"
                        class="firm-icon flex material-symbols-outlined"
                    >
                        account_circle
                    </mat-icon>
                </div>
                <div class="flex flex-col min-w-0 ml-4">
                    <div
                        [skeleton]="isLoading"
                        class="text-xl font-semibold tracking-tight leading-7 md:leading-snug truncate"
                    >
                        {{ user.firstName + ' ' + user.lastName }}
                    </div>
                    <div [skeleton]="isLoading" class="flex items-center">
                        <mat-icon class="text-lg justify-start"
                            >notifications_active</mat-icon
                        >
                        <div
                            class="ml-1.5 leading-6 truncate text-secondary font-semibold"
                            [translate]="'app.YOU_HAVE_OUTSTANDING_NOTICES'"
                            [translateParams]="{
                                number: taskInfo?.totalOutstandingNotice
                                    ? taskInfo?.totalOutstandingNotice
                                    : 0
                            }"
                        ></div>
                    </div>
                    <div [skeleton]="isLoading" class="flex items-center">
                        <mat-icon class="text-lg justify-start">rule</mat-icon>
                        <div
                            class="ml-1.5 leading-6 truncate text-secondary font-semibold"
                            [translate]="'app.YOU_HAVE_OUTSTANDING_TASK'"
                            [translateParams]="{
                                number: taskInfo?.totalOutstandingTask
                                    ? taskInfo?.totalOutstandingTask
                                    : 0
                            }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
