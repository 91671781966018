import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpEventType,
    HttpContextToken,
    HttpContext,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';

export type ProgressEvent =
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>;

export const PROGRESS_HANDLER = new HttpContextToken<
    ((progress: number) => void) | null
>(() => null);

export function withProgressHandler(
    handler: (progress: number) => void
): HttpContext {
    return new HttpContext().set(PROGRESS_HANDLER, handler);
}

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
    currentProgress: number = 0;

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const progressHandler = request.context.get(PROGRESS_HANDLER);

        if (!progressHandler) {
            return next.handle(request);
        }

        return next
            .handle(
                request.clone({
                    reportProgress: true
                })
            )
            .pipe(
                tap((event: ProgressEvent) => {
                    const newProgress = this.handleEvent(event);
                    if (newProgress) {
                        this.currentProgress = newProgress;
                    }

                    progressHandler(this.currentProgress);
                })
            );
    }

    handleEvent(event: ProgressEvent): number {
        switch (event.type) {
            case HttpEventType.Sent:
                return 33;
            case HttpEventType.UploadProgress:
                return 33 + this.computeProgress(event) / 3;
            case HttpEventType.DownloadProgress:
                return 33 + 33 + this.computeProgress(event) / 3;
        }

        return undefined;
    }

    computeProgress(event: ProgressEvent): number {
        const isValidEvent =
            (event.type === HttpEventType.UploadProgress ||
                event.type === HttpEventType.DownloadProgress) &&
            event.total;
        if (isValidEvent) {
            return Math.round((100 * event.loaded) / event.total);
        }
        return 0;
    }
}
