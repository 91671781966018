/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttachUserToCompanyResponseDto } from '../models/attach-user-to-company-response-dto';
import { CompanyAdminInviteDataDto } from '../models/company-admin-invite-data-dto';
import { CompanyRes } from '../models/company-res';
import { CompanyResponseDto } from '../models/company-response-dto';
import { createCompany } from '../fn/company/create-company';
import { CreateCompany$Params } from '../fn/company/create-company';
import { detachUserFromCompany } from '../fn/company/detach-user-from-company';
import { DetachUserFromCompany$Params } from '../fn/company/detach-user-from-company';
import { DetachUserFromCompanyResponseDto } from '../models/detach-user-from-company-response-dto';
import { getBusinessInfo } from '../fn/company/get-business-info';
import { GetBusinessInfo$Params } from '../fn/company/get-business-info';
import { getCompanyAdminInviteData } from '../fn/company/get-company-admin-invite-data';
import { GetCompanyAdminInviteData$Params } from '../fn/company/get-company-admin-invite-data';
import { getCompanyIntegrationSettings } from '../fn/company/get-company-integration-settings';
import { GetCompanyIntegrationSettings$Params } from '../fn/company/get-company-integration-settings';
import { getCompanyUsers } from '../fn/company/get-company-users';
import { GetCompanyUsers$Params } from '../fn/company/get-company-users';
import { GetIntegrationDetailsResponse } from '../models/get-integration-details-response';
import { inviteUser } from '../fn/company/invite-user';
import { InviteUser$Params } from '../fn/company/invite-user';
import { onboardCompany } from '../fn/company/onboard-company';
import { OnboardCompany$Params } from '../fn/company/onboard-company';
import { PaginatedCompanyUser } from '../models/paginated-company-user';
import { reSendInviteToUser } from '../fn/company/re-send-invite-to-user';
import { ReSendInviteToUser$Params } from '../fn/company/re-send-invite-to-user';
import { SuccessMessageDto } from '../models/success-message-dto';
import { updateBusinessInfo } from '../fn/company/update-business-info';
import { UpdateBusinessInfo$Params } from '../fn/company/update-business-info';
import { updateCompany } from '../fn/company/update-company';
import { UpdateCompany$Params } from '../fn/company/update-company';
import { updateUsersRolesInCompany } from '../fn/company/update-users-roles-in-company';
import { UpdateUsersRolesInCompany$Params } from '../fn/company/update-users-roles-in-company';
import { UpdateUsersRolesResponse } from '../models/update-users-roles-response';
import { verifyAttachUserToken } from '../fn/company/verify-attach-user-token';
import { VerifyAttachUserToken$Params } from '../fn/company/verify-attach-user-token';

@Injectable({ providedIn: 'root' })
export class CompanyApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCompany()` */
  static readonly CreateCompanyPath = '/company';

  /**
   * Create a new company.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompany$Response(params: CreateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponseDto>> {
    return createCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new company.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompany(params: CreateCompany$Params, context?: HttpContext): Observable<CompanyResponseDto> {
    return this.createCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponseDto>): CompanyResponseDto => r.body)
    );
  }

  /** Path part for operation `updateCompany()` */
  static readonly UpdateCompanyPath = '/company/{companyId}';

  /**
   * User updates the company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompany$Response(params: UpdateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyRes>> {
    return updateCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * User updates the company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompany(params: UpdateCompany$Params, context?: HttpContext): Observable<CompanyRes> {
    return this.updateCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyRes>): CompanyRes => r.body)
    );
  }

  /** Path part for operation `onboardCompany()` */
  static readonly OnboardCompanyPath = '/company/onboard';

  /**
   * Onboard a company by accepting terms and conditions.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onboardCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  onboardCompany$Response(params: OnboardCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return onboardCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Onboard a company by accepting terms and conditions.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `onboardCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  onboardCompany(params: OnboardCompany$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.onboardCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `getCompanyAdminInviteData()` */
  static readonly GetCompanyAdminInviteDataPath = '/company/admin-invite-data';

  /**
   * Get the company data using the invite token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyAdminInviteData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyAdminInviteData$Response(params: GetCompanyAdminInviteData$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyAdminInviteDataDto>> {
    return getCompanyAdminInviteData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the company data using the invite token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyAdminInviteData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyAdminInviteData(params: GetCompanyAdminInviteData$Params, context?: HttpContext): Observable<CompanyAdminInviteDataDto> {
    return this.getCompanyAdminInviteData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyAdminInviteDataDto>): CompanyAdminInviteDataDto => r.body)
    );
  }

  /** Path part for operation `getBusinessInfo()` */
  static readonly GetBusinessInfoPath = '/company/business-info';

  /**
   * Get business info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusinessInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBusinessInfo$Response(params: GetBusinessInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getBusinessInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get business info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBusinessInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBusinessInfo(params: GetBusinessInfo$Params, context?: HttpContext): Observable<{
}> {
    return this.getBusinessInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `updateBusinessInfo()` */
  static readonly UpdateBusinessInfoPath = '/company/business-info';

  /**
   * Update business information of a company.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBusinessInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBusinessInfo$Response(params: UpdateBusinessInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return updateBusinessInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Update business information of a company.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBusinessInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBusinessInfo(params: UpdateBusinessInfo$Params, context?: HttpContext): Observable<{
}> {
    return this.updateBusinessInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `inviteUser()` */
  static readonly InviteUserPath = '/company/{companyId}/invite-user';

  /**
   * Invite a user to join the company. Depending on wether the user already exists the user will be asked to sign-in or sign-up
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser$Response(params: InviteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return inviteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Invite a user to join the company. Depending on wether the user already exists the user will be asked to sign-in or sign-up
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser(params: InviteUser$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.inviteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `reSendInviteToUser()` */
  static readonly ReSendInviteToUserPath = '/company/{companyId}/resend-invite-user';

  /**
   * Invite a user to join the company. Depending on wether the user already exists the user will be asked to sign-in or sign-up
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reSendInviteToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reSendInviteToUser$Response(params: ReSendInviteToUser$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return reSendInviteToUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Invite a user to join the company. Depending on wether the user already exists the user will be asked to sign-in or sign-up
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reSendInviteToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reSendInviteToUser(params: ReSendInviteToUser$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.reSendInviteToUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

  /** Path part for operation `verifyAttachUserToken()` */
  static readonly VerifyAttachUserTokenPath = '/company/verify-token-attach-user';

  /**
   * Verify the attach user token to associate a user with a company.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyAttachUserToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyAttachUserToken$Response(params: VerifyAttachUserToken$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachUserToCompanyResponseDto>> {
    return verifyAttachUserToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Verify the attach user token to associate a user with a company.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyAttachUserToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyAttachUserToken(params: VerifyAttachUserToken$Params, context?: HttpContext): Observable<AttachUserToCompanyResponseDto> {
    return this.verifyAttachUserToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachUserToCompanyResponseDto>): AttachUserToCompanyResponseDto => r.body)
    );
  }

  /** Path part for operation `getCompanyUsers()` */
  static readonly GetCompanyUsersPath = '/company/{companyId}/users';

  /**
   * Get the users of the company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUsers$Response(params: GetCompanyUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedCompanyUser>> {
    return getCompanyUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the users of the company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUsers(params: GetCompanyUsers$Params, context?: HttpContext): Observable<PaginatedCompanyUser> {
    return this.getCompanyUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginatedCompanyUser>): PaginatedCompanyUser => r.body)
    );
  }

  /** Path part for operation `updateUsersRolesInCompany()` */
  static readonly UpdateUsersRolesInCompanyPath = '/company/update-user-roles';

  /**
   * Update the roles of the user in the company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUsersRolesInCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUsersRolesInCompany$Response(params: UpdateUsersRolesInCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateUsersRolesResponse>> {
    return updateUsersRolesInCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the roles of the user in the company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUsersRolesInCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUsersRolesInCompany(params: UpdateUsersRolesInCompany$Params, context?: HttpContext): Observable<UpdateUsersRolesResponse> {
    return this.updateUsersRolesInCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUsersRolesResponse>): UpdateUsersRolesResponse => r.body)
    );
  }

  /** Path part for operation `getCompanyIntegrationSettings()` */
  static readonly GetCompanyIntegrationSettingsPath = '/company/{companyId}/integrations';

  /**
   * Get the integration settings of the company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyIntegrationSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyIntegrationSettings$Response(params: GetCompanyIntegrationSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetIntegrationDetailsResponse>>> {
    return getCompanyIntegrationSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the integration settings of the company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyIntegrationSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyIntegrationSettings(params: GetCompanyIntegrationSettings$Params, context?: HttpContext): Observable<Array<GetIntegrationDetailsResponse>> {
    return this.getCompanyIntegrationSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetIntegrationDetailsResponse>>): Array<GetIntegrationDetailsResponse> => r.body)
    );
  }

  /** Path part for operation `detachUserFromCompany()` */
  static readonly DetachUserFromCompanyPath = '/company/{companyId}/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detachUserFromCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  detachUserFromCompany$Response(params: DetachUserFromCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<DetachUserFromCompanyResponseDto>> {
    return detachUserFromCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detachUserFromCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detachUserFromCompany(params: DetachUserFromCompany$Params, context?: HttpContext): Observable<DetachUserFromCompanyResponseDto> {
    return this.detachUserFromCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetachUserFromCompanyResponseDto>): DetachUserFromCompanyResponseDto => r.body)
    );
  }

}
