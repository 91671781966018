import { NavItem } from '../../../ui/components/navigation/navigation.component';

export enum NavItemId {
    HOME = 'home',
    INBOX = 'inbox',
    ACCOUNTING = 'accounting',
    FINANCE = 'finance',
    PROFILE = 'profile',
    SIGNOUT = 'signOut',
    COMPANIES = 'companies',
    ACCOUNT_SECURITY = 'accountSecurity'
}
export const TOP_NAV_ITEMS: NavItem[] = [
    {
        key: NavItemId.HOME,
        label: 'HOME',
        icon: 'House',
        activeIcon: 'HouseFill',
        route: '/home'
    },
    {
        key: NavItemId.INBOX,
        label: 'INBOX',
        icon: 'Mail',
        activeIcon: 'MailFill',
        route: '/inbox'
    },
    {
        key: NavItemId.ACCOUNTING,
        label: 'ACCOUNTING',
        icon: 'Landmark',
        activeIcon: 'LandmarkFill',
        route: '/accounting'
    },
    {
        key: NavItemId.FINANCE,
        label: 'FINANCE',
        icon: 'Euro',
        route: '/finance'
    }
];

export const BOTTOM_SUB_ITEMS: NavItem[] = [
    {
        key: NavItemId.PROFILE,
        label: 'PROFILE',
        icon: 'User',
        activeIcon: 'UserFill',
        route: '/profile'
    },
    {
        key: NavItemId.COMPANIES,
        label: 'COMPANIES',
        icon: 'Building',
        activeIcon: 'BuildingFill',
        route: '/companies'
    },
    {
        key: NavItemId.ACCOUNT_SECURITY,
        label: 'ACCOUNT_SECURITY',
        icon: 'Lock',
        route: '/account-security'
    },
    {
        key: NavItemId.SIGNOUT,
        label: 'SIGN_OUT',
        icon: 'LogOut',
        route: '/sign-out',
        mobileButton: true
    }
];
