<div
    class="flex flex-col gap-[2px] min-w-[250px] max-w-[550px]"
    (click)="onContainerClicked($event)"
    [ngClass]="{
        'hover:cursor-pointer': downloaderProps
    }"
>
    <input
        #fileUploader
        class="hidden"
        type="file"
        (change)="onFileSelected($event)"
    />

    <div
        class="flex flex-grow gap-2 p-2 bg-neutral-000 rounded-xl border-[1px] border-neutral-100 border-solid hover:shadow-depth-3"
    >
        <div
            class="w-11 h-11 sm:w-9 sm:h-9 shadow-depth-3 flex rounded-lg"
            [ngClass]="iconParentClasses"
        >
            <ui-icon
                [name]="isLoading ? 'FileText' : 'FileTextFill'"
                class="w-6 h-6 sm:w-5 sm:h-5 m-auto"
                [ngClass]="{
                        'text-white': !disabled,
                        'text-neutral-300': disabled,
                    }"
            />
        </div>
        <div class="my-auto h-fit max-h-[36px] flex-1 max-w-full min-w-0">
            <div
                class="t-h4 truncate"
                [ngClass]="{
                        'text-primary-600': !errorMessage && !disabled,
                        'text-neutral-300': !errorMessage && disabled,
                        'text-red-600': errorMessage,
                    }"
            >
                {{ fileName ?? title }}
            </div>
            <div
                *ngIf="selectedFile && !errorMessage && !isLoading"
                class="t-cap"
                [ngClass]="{
                        'text-neutral-400': !disabled,
                        'text-neutral-300': disabled,
                    }"
            >
                {{ fileSize }}
            </div>
            <div *ngIf="isLoading" class="pb-1 pt-[4.5px] h-[6.5px] w-full">
                <div class="h-[6.5px] rounded-lg w-full bg-neutral-100">
                    <div
                        [style]="loadingBarStyles"
                        class="h-[6.5px] rounded-lg bg-primary-gradient-02"
                    ></div>
                </div>
            </div>
        </div>
        <ui-button
            *ngIf="showButton"
            class="ml-auto h-fit my-auto"
            variant="secondary"
            [icon]="isLoading ? 'X' : 'Upload'"
            [label]="buttonLabel"
            (clicked)="onButtonClicked($event)"
        />
    </div>
    <div
        *ngIf="errorMessage"
        class="t-cap text-red-400 pl-2 min-w-0 line-clamp-2"
    >
        {{ errorMessage }}
    </div>
</div>
