/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { validateInvite } from '../fn/perma-link/validate-invite';
import { ValidateInvite$Params } from '../fn/perma-link/validate-invite';
import { validatePermalink } from '../fn/perma-link/validate-permalink';
import { ValidatePermalink$Params } from '../fn/perma-link/validate-permalink';

@Injectable({ providedIn: 'root' })
export class PermaLinkApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `validatePermalink()` */
  static readonly ValidatePermalinkPath = '/permalink/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validatePermalink()` instead.
   *
   * This method doesn't expect any request body.
   */
  validatePermalink$Response(params: ValidatePermalink$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validatePermalink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validatePermalink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validatePermalink(params: ValidatePermalink$Params, context?: HttpContext): Observable<void> {
    return this.validatePermalink$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `validateInvite()` */
  static readonly ValidateInvitePath = '/permalink/kamigo-invite-callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateInvite$Response(params: ValidateInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateInvite(params: ValidateInvite$Params, context?: HttpContext): Observable<void> {
    return this.validateInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
