import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import {
    DropdownComponent,
    DropdownItem
} from '../dropdown/dropdown.component';

@Component({
    selector: 'ui-paginator',
    standalone: true,
    imports: [ButtonComponent, DropdownComponent],
    templateUrl: './paginator.component.html'
})
export class PaginatorComponent implements OnInit, OnChanges {
    @Input() length: number = 0;
    @Input() pageSize: number = 10;
    @Input() pageIndex: number = 1;
    @Input() pageSizeOptions: number[] = [5, 10, 25, 50];

    /** Translatable label for the page size selector */
    @Input() itemsPerPageLabel: string = 'Items per page:';
    /** Translatable word displayed between current page and total pages */
    @Input() ofLabel: string = 'of';

    @Output() pageChange = new EventEmitter<{
        pageIndex: number;
        pageSize: number;
    }>();

    totalPages: number = 0;

    // Cached selected dropdown item for the page size.
    selectedPageSizeItem: DropdownItem<number>;

    // array of dropdown items
    dropdownPageSizeItems: DropdownItem<number>[] = [];

    ngOnInit(): void {
        // Initialize the selected item and cache the dropdown items.
        this.selectedPageSizeItem = {
            name: this.pageSize.toString(),
            item: this.pageSize
        };
        this.updateDropdownItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.totalPages = Math.max(Math.ceil(this.length / this.pageSize), 1);

        if (this.pageIndex < 1) {
            this.pageIndex = 1;
            this.emitChange();
        } else if (this.pageIndex > this.totalPages) {
            this.pageIndex = this.totalPages;
            this.emitChange();
        }

        if (changes.pageSizeOptions) {
            this.updateDropdownItems();
        }

        // If pageSize changed from outside, update the selected item.
        if (changes.pageSize && !changes.pageSize.isFirstChange()) {
            this.selectedPageSizeItem = {
                name: this.pageSize.toString(),
                item: this.pageSize
            };
        }
    }

    /** Navigate to the previous page */
    onPrevious(): void {
        if (this.pageIndex > 1) {
            this.pageIndex--;
            this.emitChange();
        }
    }

    /** Navigate to the next page */
    onNext(): void {
        if (this.pageIndex < this.totalPages) {
            this.pageIndex++;
            this.emitChange();
        }
    }

    onPageSizeChange(newPageSize: number): void {
        this.pageSize = newPageSize;
        this.selectedPageSizeItem = {
            name: newPageSize.toString(),
            item: newPageSize
        };
        this.pageIndex = 1;
        this.totalPages = Math.max(Math.ceil(this.length / this.pageSize), 1);
        this.emitChange();
    }

    private emitChange(): void {
        this.pageChange.emit({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    private updateDropdownItems(): void {
        this.dropdownPageSizeItems = this.pageSizeOptions.map((size) => ({
            name: size.toString(),
            item: size
        }));
    }

    /** Returns the index of the first item on the current page */
    get startIndex(): number {
        return this.length === 0 ? 0 : (this.pageIndex - 1) * this.pageSize + 1;
    }

    /** Returns the index of the last item on the current page */
    get endIndex(): number {
        return Math.min(this.pageIndex * this.pageSize, this.length);
    }
}
