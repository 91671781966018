import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, BehaviorSubject, tap, pairwise, noop } from 'rxjs';
import { Company, UpdatePassword, User } from 'app/core/user/user.types';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Permission, UserWithPermissions } from '../../../api/models';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user = new BehaviorSubject<User>(null);

    private _activeCompany = new BehaviorSubject<Company>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _router: Router) {
        this._user.subscribe((user) => {
            if (!user) {
                return;
            }
            const activeCompanyId =
                window.localStorage.getItem('activeCompanyId');

            const activeCompany =
                user.companies.find((c) => c._id === activeCompanyId) ||
                user.companies[0] ||
                undefined;

            this.setActiveCompany(activeCompany?._id);
        });

        // reload navigation when activeCompany changes
        this._activeCompany
            .asObservable()
            .pipe(pairwise())
            .subscribe(([oldCompany, newCompany]) => {
                if (
                    oldCompany &&
                    (!newCompany || oldCompany._id !== newCompany._id)
                ) {
                    this._router.navigate([this._router.url]);
                }
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get activeCompany$(): Observable<Company> {
        return this._activeCompany.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>(`${environment.apiUrl}/user`).pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient
            .patch<User>(`${environment.apiUrl}/user`, user)
            .pipe(
                map((response) => {
                    this._user.next(response);
                })
            );
    }

    updatePassword(updatePassword: UpdatePassword): Observable<any> {
        return this._httpClient.post(
            `${environment.apiUrl}/user/reset-password`,
            updatePassword
        );
    }

    logOut(): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}/user/logout`, {});
    }

    fileUploadEvent(file: FormData): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}/storage`, file);
    }

    changeEmail(emailObj: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.patch(
            `${environment.apiUrl}/user/validate/change-mail`,
            emailObj
        );
    }

    hasPermission(companyId: string, permission: Permission): boolean {
        const userWithPermissions =
            this._user.getValue() as unknown as UserWithPermissions;

        const company = userWithPermissions.companies.find(
            (company) => company._id === companyId
        );

        return !!company?.permissions?.includes(permission);
    }

    setActiveCompany(id: string) {
        const user = this._user.getValue();
        const activeCompany = user.companies.find((c) => c._id === id) || null;
        window.localStorage.setItem(
            'activeCompanyId',
            activeCompany?._id || ''
        );
        this._activeCompany.next(activeCompany);
    }
}
