import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormsModule
} from '@angular/forms';

@Component({
    selector: 'ui-otp-input',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './otp-input.component.html',
    styleUrl: './otp-input.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OtpInputComponent),
            multi: true
        }
    ]
})
export class OtpInputComponent implements ControlValueAccessor, OnInit {
    //  Number of digits. Defaults to 6.
    @Input() length = 6;
    // Emits when a full code is entered
    @Output() codeCompleted = new EventEmitter<string>();
    //  hidden true code
    code = '';
    // digits for display
    digits: string[] = [];

    @ViewChild('realInput') realInput!: ElementRef<HTMLInputElement>;

    private onChangeFn = (value: string) => {};
    private onTouchedFn = () => {};

    isFocused = false;

    ngOnInit(): void {
        this.digits = new Array(this.length).fill('');
    }

    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        // Allow only digits and limit to the desired length.
        this.code = input.value.replace(/\D/g, '').slice(0, this.length);
        // Update each display box.
        for (let i = 0; i < this.length; i++) {
            this.digits[i] = this.code[i] || '';
        }
        this.onChangeFn(this.code);
        this.onTouchedFn();
        if (this.code.length === this.length) {
            this.codeCompleted.emit(this.code);
        }
    }

    focusInput(): void {
        this.realInput.nativeElement.focus();
    }

    // ControlValueAccessor interface
    writeValue(value: string): void {
        this.code = value || '';
        for (let i = 0; i < this.length; i++) {
            this.digits[i] = this.code[i] || '';
        }
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChangeFn = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouchedFn = fn;
    }
}
