/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createOrUpdateFirmSettings } from '../fn/firm-settings/create-or-update-firm-settings';
import { CreateOrUpdateFirmSettings$Params } from '../fn/firm-settings/create-or-update-firm-settings';
import { SuccessMessageDto } from '../models/success-message-dto';

@Injectable({ providedIn: 'root' })
export class FirmSettingsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createOrUpdateFirmSettings()` */
  static readonly CreateOrUpdateFirmSettingsPath = '/firmSettings/{firmId}';

  /**
   * Create Firm Settings.
   *
   * create  or update firm settings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateFirmSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateFirmSettings$Response(params: CreateOrUpdateFirmSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessMessageDto>> {
    return createOrUpdateFirmSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Firm Settings.
   *
   * create  or update firm settings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateFirmSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateFirmSettings(params: CreateOrUpdateFirmSettings$Params, context?: HttpContext): Observable<SuccessMessageDto> {
    return this.createOrUpdateFirmSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessMessageDto>): SuccessMessageDto => r.body)
    );
  }

}
