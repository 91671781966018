<div class="w-full gap-1 flex flex-col relative">
    <!-- If selectOptions exist, render the dropdown mode -->
    <div #container *ngIf="selectOptions; else normalInput">
        <ui-dropdown
            #dropdown
            [items]="selectOptions"
            [buttonVariant]="'primary'"
            [nothingSelectedText]="placeHolder"
            [buttonCustomStyles]="'w-full focus:bg-[#94A3B81A]'"
            [(singleSelectedItem)]="selectedItem"
            (onMenuStateChange)="isSelectOpen = $event === 'open'"
            (onItemSelected)="onDropdownItemSelected($event)"
        >
            <ng-template #customTrigger>
                <button
                    mat-button
                    class="rounded-lg border-neutral-200 border-[1px] border-solid placeholder:text-neutral-300 t-body h-[30px] w-full max-w-[600px] px-2 pt-[1px] pb-0 outline-none flex items-center justify-between"
                    [ngClass]="inputClasses"
                    [disabled]="disabled"
                >
                    <span [ngClass]="{ 'text-neutral-300': isSelectOpen }">
                        {{
                            isSelectOpen
                                ? placeHolder
                                : selectedItem?.name || placeHolder
                        }}
                    </span>
                    <ui-icon
                        class="text-neutral-400"
                        [name]="isSelectOpen ? 'ChevronUp' : 'ChevronDown'"
                        size="16"
                    ></ui-icon>
                </button>
            </ng-template>
            <ng-template #customItem let-item="item">
                <div
                    [style.width.px]="menuData.menuWidth"
                    class="flex h-10 text-left items-center px-4 py-1 hover:bg-[#94A3B81A]"
                >
                    <span
                        [ngClass]="{
                            't-body-bold': selectedItem?.item === item.item,
                            't-body': selectedItem?.item !== item.item
                        }"
                    >
                        {{ item.name }}
                    </span>
                </div>
            </ng-template>
        </ui-dropdown>
    </div>

    <ng-template #normalInput>
        <ng-container *ngIf="!multiline; else multiInput">
            <input
                [type]="type"
                class="rounded-lg h-11 border-neutral-200 border-[1px] border-solid placeholder:text-neutral-300 t-body sm:h-[30px] w-full max-w-[600px] px-2 pt-[1px] pb-0 outline-none"
                [ngClass]="inputClasses"
                [placeholder]="placeHolder"
                [disabled]="disabled"
                [(ngModel)]="value"
                (input)="onInputChange(value)"
            />
        </ng-container>
        <ng-template #multiInput>
            <textarea
                class="rounded-lg border-neutral-200 border-[1px] border-solid placeholder:text-neutral-300 t-body w-full max-w-[600px] px-2 py-2 outline-none resize-none"
                [ngClass]="inputClasses"
                [placeholder]="placeHolder"
                [disabled]="disabled"
                [(ngModel)]="value"
                (input)="onInputChange(value)"
                rows="2"
            ></textarea>
        </ng-template>
    </ng-template>

    <span
        *ngIf="errorMessage && invalid"
        class="block h-[18px] px-1 text-red-400 t-cap"
    >
        {{ errorMessage }}
    </span>
</div>
