<div class="flex flex-col flex-auto min-w-0">
    <ng-container
        *ngIf="(user?.companies?.length || 0) <= 1; else customerDropDown"
    >
        <ui-toggle-tabs
            [tabItems]="tabItems"
            [selectedTab]="selectedItem?.item"
            (selectedTabChange)="onSelectionChange($event)"
        ></ui-toggle-tabs>
    </ng-container>
</div>

<ng-template #customerDropDown>
    <ui-dropdown
        #dropdown
        class="dropdown-bg rounded-2xl p-1"
        [multiple]="false"
        [singleSelectedItem]="selectedItem"
        [items]="dropdownItems"
        (singleSelectedItemChange)="onSelectionChange($event.item)"
    >
        <ng-template #customTrigger>
            <ui-tab-item
                [isSelected]="true"
                [tab]="{
                    rightIcon: 'ChevronDown',
                    key: dropdown.singleSelectedItem?.item,
                    label: dropdown.singleSelectedItem?.name,
                    icon: dropdown.singleSelectedItem?.icon,
                    count: dropdown.singleSelectedItem?.count
                }"
            />
        </ng-template>
    </ui-dropdown>
</ng-template>
