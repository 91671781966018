<button
    type="button"
    class="relative flex items-center transition-all duration-100 border-none shadow-depth-2-regular disabled:bg-neutral-200 disabled:cursor-not-allowed cursor-pointer remove-gradient-on-focus rounded-lg sm:px-1.5 px-2.5 sm:h-8 h-11"
    [ngClass]="buttonClasses"
    [disabled]="disabled"
    (click)="handleClick($event)"
>
    <ng-container *ngIf="icon">
        <ui-icon
            [name]="icon"
            class="w-5 h-5 sm:w-4 sm:h-4"
            [ngClass]="textClasses"
        ></ui-icon>
    </ng-container>

    <ng-container *ngIf="label">
        <span class="sm:t-body t-h4 mx-2 truncate" [ngClass]="textClasses">
            {{ label }}
        </span>
    </ng-container>

    <ng-container *ngIf="rightIcon">
        <ui-icon
            [name]="rightIcon"
            class="w-5 h-5 sm:w-4 sm:h-4"
            [ngClass]="textClasses"
        ></ui-icon>
    </ng-container>
</button>
