import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { Component, Input } from '@angular/core';
import { IconName } from '../../assets/icons/icons';

@Component({
    selector: 'ui-header',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './header.component.html'
})
export class HeaderComponent {
    @Input() mode: 'dark' | 'light' | 'transparent' = 'dark';
    @Input() icon: IconName;
    @Input() label?: string;

    get cssClasses() {
        return {
            'bg-primary-gradient-02': this.mode === 'dark',
            'text-white': this.mode === 'dark' || this.mode === 'transparent',

            'bg-white': this.mode === 'light',
            'text-primary-400': this.mode === 'light',
            'border-primary-100': this.mode === 'light',
            'border-[1px]': this.mode === 'light',
            'shadow-depth-3': this.mode === 'light',

            'bg-transparent': this.mode === 'transparent'
        };
    }
}
