<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<svg-gradients class="w-0 h-0"></svg-gradients>
<ng-container *ngIf="layout === 'empty'; else contentLayout">
    <empty-layout></empty-layout>
</ng-container>

<ng-template #contentLayout>
    <!-- Loading bar -->
    <fuse-loading-bar></fuse-loading-bar>
    <div class="h-screen sm:px-4 sm:pt-4 p-0 flex overflow-hidden">
        <ui-navigation
            class="sm:pb-4"
            #uiNavigation
            [logoSvgPath]="'/assets/images/logo/logo-nav.svg'"
            [navItems]="topNavItems"
            [bottomNavItem]="bottomNavItem"
            [bottomSubItems]="bottomSubItems"
            [activeItemKey]="activeItemKey"
            [hideMobileBottomNav]="hideMobileNavbar"
            (activeItemKeyChange)="onActiveItemChange($event)"
        >
        </ui-navigation>

        <div class="flex flex-col grow min-w-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-template>
