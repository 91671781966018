import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    IApiResponse,
    IPaymentRequestAttachmentResponse,
    IUpdateInboxItem
} from '../../../app/modules/admin/inbox/inbox.type';
import { TaskApiService } from '../../../api/services';
import { WebSocketService } from '../../../app/core/web-socket/web-socket.service';
import { ItemEventType, TaskCountWithTotal } from '../../../app/core/web-socket/web-socket.types';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    public _taskCount = new BehaviorSubject<TaskCountWithTotal>(null);

    constructor(
        private _httpClient: HttpClient,
        private readonly _taskApiService: TaskApiService,
         private _webSocketService: WebSocketService
    ) {
        this.getTaskCount();

        this._webSocketService.onTaskEvent.subscribe((event: ItemEventType) => {
            const updatedCounts = this.updateCounts(event);
            this._taskCount.next(updatedCounts);
        });
    }

    taskCount$(): Observable<TaskCountWithTotal> {
        return this._taskCount.asObservable();
    }

  getTaskCount(): void {
    this._taskApiService.getTaskCount().subscribe(taskCount => {
      const taskCountWithTotal: TaskCountWithTotal = {
        ...taskCount,
        totalOutstandingCount: this.computeTotal(taskCount)
      };
      this._taskCount.next(taskCountWithTotal);
    });
  }

    updateInboxItem(
        inboxItemId: string,
        inboxItem: IUpdateInboxItem
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.apiUrl}/task/task-response/${inboxItemId}`,
            inboxItem
        );
    }

    getTaskLink(taskId: string, redirectUrl: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}/task/${taskId}/link?redirectUrl=${redirectUrl}`
        );
    }
    getLastTaskLink(redirectUrl: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}/task/link?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadDocumentLink(
        taskId: string,
        redirectUrl: string
    ): Observable<IApiResponse<{ url: string; isVerificationLink: boolean }>> {
        return this._httpClient.get<
            IApiResponse<{
                url: string;
                isVerificationLink: boolean;
            }>
        >(
            `${environment.apiUrl}/task/e-sign/doc/${taskId}?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadESignAttachment(
        taskId: string,
        redirectUrl: string
    ): Observable<IApiResponse<{ url: string; isVerificationLink: boolean }>> {
        return this._httpClient.get<
            IApiResponse<{
                url: string;
                isVerificationLink: boolean;
            }>
        >(
            `${environment.apiUrl}/task/e-sign/source-doc/${taskId}?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadAttachmentLink(
        type: string,
        taskId: string,
        fileId: string
    ): Observable<IPaymentRequestAttachmentResponse> {
        return this._httpClient.post<IPaymentRequestAttachmentResponse>(
            `${environment.apiUrl}/task/download/${type}/${taskId}?all=false`,
            {
                fileIds: [fileId]
            }
        );
    }

    uploadRequestedFiles(taskId: string, fileId: string, file: FormData) {
        return this._httpClient.post(
            `${environment.apiUrl}/task/upload/${taskId}/${fileId}`,
            file
        );
    }

 private computeTotal(data: { 
    companyOutstandingCounts?: { [key: string]: number }; 
    userOutstandingCounts?: { [key: string]: number };
  }): number {
    const companyTotal = data.companyOutstandingCounts
      ? Object.values(data.companyOutstandingCounts).reduce((sum, count) => sum + count, 0)
      : 0;
    const userTotal = data.userOutstandingCounts
      ? Object.values(data.userOutstandingCounts).reduce((sum, count) => sum + count, 0)
      : 0;
    return companyTotal + userTotal;
  }

    private updateCounts(eventData: ItemEventType): TaskCountWithTotal {
        const current = this._taskCount.getValue() || {
            companyOutstandingCounts: {},
            firmOutstandingCounts: {},
            userOutstandingCounts: {},
            totalOutstandingCount: 0
        };

        // Update only the specific count for the provided entityId.
        if (eventData.entityType === 'user' && eventData.entityId) {
            current.userOutstandingCounts[eventData.entityId] =
                eventData.userOutstandingCounts?.[eventData.entityId] ?? 0;
        } else if (eventData.entityType === 'company' && eventData.entityId) {
            current.companyOutstandingCounts[eventData.entityId] =
                eventData.companyOutstandingCounts?.[eventData.entityId] ?? 0;
        }

        // compute the overall total based on (updated) counts.
        const companyTotal = Object.values(
            current.companyOutstandingCounts
        ).reduce((sum, count) => sum + count, 0);
        const userTotal = Object.values(current.userOutstandingCounts).reduce(
            (sum, count) => sum + count,
            0
        );
        current.totalOutstandingCount = companyTotal + userTotal;

        return {
            companyOutstandingCounts: current.companyOutstandingCounts,
            userOutstandingCounts: current.userOutstandingCounts,
            totalOutstandingCount: current.totalOutstandingCount,
            // When we need actually updated firmcounts by entity websockets we need 'entityId-firmId' counts. 
            // To be implemented when needed.
            firmOutstandingCounts: current.firmOutstandingCounts
        };
    }
}