import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    CustomerDetails,
    IApiResponse,
    InboxItem,
    IPaymentRequestAttachmentResponse,
    IUpdateInboxItem
} from '../../../app/modules/admin/inbox/inbox.type';
import { IPaginateResult } from '../../../app/core/common-pagination/common-pagination.type';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    public taskInfo: ReplaySubject<any> = new ReplaySubject<any>(1);
    constructor(private _httpClient: HttpClient) {}

    getTaskInfo(): Observable<CustomerDetails> {
        return this._httpClient
            .get<CustomerDetails>(`${environment.apiUrl}/task`)
            .pipe(
                tap((task) => {
                    this.taskInfo.next(task);
                })
            );
    }
    getInboxData(
        status: string,
        limit: number,
        page: number,
        type: string
    ): Observable<IPaginateResult<InboxItem>> {
        if (type === 'all') {
            return this._httpClient.get<IPaginateResult<InboxItem>>(
                `${environment.apiUrl}/task/${status}?page=${page}&limit=${limit}`
            );
        } else
            return this._httpClient.get<IPaginateResult<InboxItem>>(
                `${environment.apiUrl}/task/${status}?page=${page}&limit=${limit}&type=${type}`
            );
    }

    updateInboxItem(
        inboxItemId: string,
        inboxItem: IUpdateInboxItem
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.apiUrl}/task/task-response/${inboxItemId}`,
            inboxItem
        );
    }

    getTaskLink(taskId: string, redirectUrl: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}/task/${taskId}/link?redirectUrl=${redirectUrl}`
        );
    }
    getLastTaskLink(redirectUrl: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}/task/link?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadDocumentLink(
        taskId: string,
        redirectUrl: string
    ): Observable<IApiResponse<{ url: string; isVerificationLink: boolean }>> {
        return this._httpClient.get<
            IApiResponse<{
                url: string;
                isVerificationLink: boolean;
            }>
        >(
            `${environment.apiUrl}/task/e-sign/doc/${taskId}?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadESignAttachment(
        taskId: string,
        redirectUrl: string
    ): Observable<IApiResponse<{ url: string; isVerificationLink: boolean }>> {
        return this._httpClient.get<
            IApiResponse<{
                url: string;
                isVerificationLink: boolean;
            }>
        >(
            `${environment.apiUrl}/task/e-sign/source-doc/${taskId}?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadAttachmentLink(
        type: string,
        taskId: string,
        fileId: string
    ): Observable<IPaymentRequestAttachmentResponse> {
        return this._httpClient.post<IPaymentRequestAttachmentResponse>(
            `${environment.apiUrl}/task/download/${type}/${taskId}?all=false`,
            {
                fileIds: [fileId]
            }
        );
    }

    uploadRequestedFiles(taskId: string, fileId: string, file: FormData) {
        return this._httpClient.post(
            `${environment.apiUrl}/task/upload/${taskId}/${fileId}`,
            file
        );
    }
}
