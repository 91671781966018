import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ColorMode,
    IconBadgeComponent
} from '../icon-badge/icon-badge.component';
import { IconName } from '../../assets/icons/icons';

@Component({
    selector: 'ui-summary-card',
    standalone: true,
    imports: [IconBadgeComponent, CommonModule],
    templateUrl: './summary-card.component.html'
})
export class SummaryCardComponent {
    @Input() label: string;
    @Input() icon: IconName;
    @Input() badgeColor: ColorMode;
    @Input() badgeIconFill: boolean;

    @Output() clicked = new EventEmitter<void>();

    constructor() {}

    handleClick(): void {
        this.clicked.emit();
    }
}
