import { LucideAngularComponent, LucideAngularModule } from 'lucide-angular';
import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillDirective } from './fill.directive';

@Component({
    selector: 'ui-icon',
    standalone: true,
    imports: [CommonModule, LucideAngularModule, FillDirective],
    providers: [
        {
            provide: LucideAngularComponent,
            useExisting: IconComponent
        }
    ],
    hostDirectives: [
        { directive: FillDirective, inputs: ['fill', 'gradientUrl'] }
    ],
    template: ''
})
export class IconComponent extends LucideAngularComponent implements OnChanges {
    @Input() gradientUrl?: string;
    onChangesCallback?: () => void;

    ngOnChanges(changes: any) {
        super.ngOnChanges(changes);
        this.onChangesCallback?.();
    }
}
