<div *ngIf="showAsModal">
    <ui-modal
        [isOpen]="isOpen"
        [position]="modalPosition"
        [growVertically]="modalGrowVertically"
        (onClose)="close()"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </ui-modal>
</div>

<ng-container *ngIf="isOpen && !showAsModal">
    <ui-default-modal-content
        [growVertically]="modalGrowVertically"
        (onClose)="close()"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </ui-default-modal-content>
</ng-container>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
