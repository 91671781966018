<!-- Container: clicking anywhere focuses the hidden input -->
<div class="relative w-fit" (click)="focusInput()">
    <div class="flex gap-2">
        <div
            *ngFor="let digit of digits; let i = index"
            class="w-12 h-15 relative flex items-center justify-center border border-neutral-100 shadow-depth-2-regular rounded-lg"
        >
            <span class="t-h1">{{ digit }}</span>
            <!-- If this is the active box (next to fill) and it's empty and focused, show a custom caret -->
            <ng-container
                *ngIf="isFocused && i === code.length && code.length < length"
            >
                <div
                    class="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-0.5 bg-neutral-400 animate-blink"
                ></div>
            </ng-container>
        </div>
    </div>
    <!-- Transparent input overlaid on top-->
    <input
        #realInput
        autocomplete="off"
        [value]="code"
        (input)="onInput($event)"
        (focus)="isFocused = true"
        (blur)="isFocused = false"
        [attr.maxlength]="length"
        class="absolute inset-0 w-full h-full bg-transparent text-transparent caret-transparent outline-none"
    />
</div>
