import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

export interface NotificationConfiguration {
    type: 'success' | 'warning' | 'error' | 'info';
    title: string;
    isDetailPresent: boolean;
    detail?: string;
    detailLink?: string;
}

@Component({
    selector: 'ui-notification',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './notification.component.html',
    animations: [
        trigger('notificationState', [
            state(
                'void',
                style({
                    opacity: 0,
                    transform: 'translateY(-20px)'
                })
            ),
            state(
                'visible',
                style({
                    opacity: 1,
                    transform: 'translateY(0)'
                })
            ),
            state(
                'hidden',
                style({
                    opacity: 0,
                    transform: 'translateY(-20px)'
                })
            ),
            transition('void => visible', animate('200ms ease-out')),
            transition('visible => hidden', animate('100ms ease-in'))
        ])
    ]
})
export class NotificationComponent {
    @Input() notificationConfiguration: NotificationConfiguration;
    @Output() closeNotifierClicked = new EventEmitter<Event>();
    @Output() closed = new EventEmitter<void>();

    animationState: 'visible' | 'hidden' = 'visible';

    get notificationIcon(): string {
        return (
            {
                success: 'check',
                warning: 'triangle-alert',
                error: 'circle-x',
                info: 'info'
            }[this.notificationConfiguration.type] || 'check'
        );
    }

    get notificationIconBackground(): string {
        return (
            {
                success: 'bg-green-300',
                warning: 'bg-yellow-300',
                error: 'bg-red-300',
                info: 'bg-secondary-300'
            }[this.notificationConfiguration.type] || ''
        );
    }

    get notificationTextColor(): string {
        return (
            {
                success: 'text-green-400',
                warning: 'text-yellow-400',
                error: 'text-red-400',
                info: 'text-secondary-400'
            }[this.notificationConfiguration.type] || ''
        );
    }

    get notificationIconColor(): string {
        return (
            {
                success: 'text-green-100',
                warning: 'text-yellow-100',
                error: 'text-red-100',
                info: 'text-secondary-100'
            }[this.notificationConfiguration.type] || ''
        );
    }

    handleCloseNotifier() {
        // Change state to trigger the exit animation
        this.animationState = 'hidden';
        setTimeout(() => {
            this.closed.emit();
        }, 200);
    }
}
