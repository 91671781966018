<div class="flex items-center">
    <div class="rounded-lg flex items-center p-2" [ngClass]="cssClasses">
        <ui-icon [name]="icon" class="h-5 w-5"></ui-icon>
    </div>
    <span
        *ngIf="label"
        class="t-body-bold inline-block max-w-[150px] truncate ml-2"
        [ngClass]="{
            'text-white': mode === 'transparent',
            'text-primary-600': mode === 'light' || mode === 'dark',
        }"
    >
        {{ label }}
    </span>
</div>
